import { BaseGameDriver, GameDriver } from './GameDriver';
import { GamePhase } from './GamePhase';

const ANIMATION_INTERVAL = 1000;
const REVEAL_BASE_ANIMATION_TIME = 2000;
const CHOOSE_ACTION_TIME_INTERVAL = 12000;

export class TimedGameDriver extends BaseGameDriver implements GameDriver {
  private phaseTime: number = -1;
  private currentAdvanceTime: number = -1;

  constructor(player1Username: string, player2Username: string, deck1: number[], deck2: number[]) {
    super(player1Username, player2Username, deck1, deck2);
  }

  timedAdvance(currentTime: number): GamePhase | null {
    this.currentAdvanceTime = currentTime;
    return this.advance();
  }

  advance(): GamePhase | null {
    if (this.phase == GamePhase.GAME_OVER) return null;
    if (this.game.isEitherPlayerDead()) this.beforeChangePhase(GamePhase.GAME_OVER);
    const oldPhase = this.phase;

    if (this.phaseTime < 0) this.phaseTime = this.currentAdvanceTime;
    const deltaTime = this.currentAdvanceTime - this.phaseTime;
    switch (this.phase) {
      case GamePhase.WAITING_TO_START:
        this.beforeChangePhase(GamePhase.STARTING_TURN);
      // When starting the game we want to fallthrough
      // eslint-disable-next-line no-fallthrough
      case GamePhase.STARTING_TURN:
        if (deltaTime >= ANIMATION_INTERVAL) {
          this.beforeChangePhase(GamePhase.START_OF_TURN_EFFECTS);
        }
        break;
      case GamePhase.START_OF_TURN_EFFECTS:
        if (deltaTime >= ANIMATION_INTERVAL) {
          this.beforeChangePhase(GamePhase.AWAITING_ROUND_ACTION);
        }
        break;
      case GamePhase.AWAITING_ROUND_ACTION:
        if (deltaTime >= CHOOSE_ACTION_TIME_INTERVAL || this.game.haveAllPlayersReadiedAnAction()) {
          this.beforeChangePhase(GamePhase.REVEALING_ROUND_ACTION);
        }
        break;
      case GamePhase.REVEALING_ROUND_ACTION:
        if (deltaTime >= REVEAL_BASE_ANIMATION_TIME) {
          if (this.game.haveAllPlayersPassed()) {
            this.beforeChangePhase(GamePhase.COMBAT);
          } else {
            this.beforeChangePhase(GamePhase.AWAITING_ROUND_ACTION);
          }
        }
        break;
      case GamePhase.COMBAT:
        if (!this.game.canAnythingAttack()) {
          this.beforeChangePhase(GamePhase.STARTING_TURN);
        } else {
          this.beforeChangePhase(GamePhase.ANIMATING_ATTACKS);
        }
        break;
      case GamePhase.ANIMATING_ATTACKS:
        if (deltaTime >= ANIMATION_INTERVAL * 4) {
          this.beforeChangePhase(GamePhase.STARTING_TURN);
        }
        break;
    }

    return oldPhase == this.phase ? null : this.phase;
  }

  beforeChangePhase(newPhase: GamePhase) {
    this.phaseTime = this.currentAdvanceTime;
    this.changePhase(newPhase);
  }
}
