import { Chat } from '../../data';
import { FullData } from '../data';
import { RevealActionsRequest } from '../network';
import { GamePhase } from './GamePhase';

export class PlayInterfaceState {
  constructor(
    public readonly chatHistory: Chat[],
    public readonly gameState: FullData | null,
    public readonly playerHasSkipped: boolean,
    public readonly localHasPlayerPlayedManaThisTurn: boolean,
    public readonly isPendingAddMana: boolean,
    public readonly currentGamePhase: GamePhase,
    public readonly turnCount: number,
    public readonly revealedActions: RevealActionsRequest | null,
    public readonly selectedHandIndex: number | null,
  ) {}
}
