interface CopySet {
  getNameById(id: number): string;
  getTextById(id: number): string;
}

class EnglishCopySet implements CopySet {
  constructor() {}
  getNameById(id: number): string {
    switch (id) {
      case 1:
        return 'Skeleton';
      case 2:
        return 'Bat Swarm';
      case 3:
        return 'Duchess Anastasia';
      case 4:
        return 'Tabunara Sellsword';
      case 5:
        return 'Canyon Raptor';
      case 6:
        return 'Riptide Trident';
      case 7:
        return 'Codex Witch Sarah';
      case 8:
        return 'Meran River Guard';
      case 9:
        return 'Delve into Knowledge';
      case 10:
        return 'Rat';
      case 11:
        return 'Nullmana Terradon';
      case 12:
        return 'Nox Puddles';
      case 13:
        return 'Rat Barrel';
      case 14:
        return 'Midnight Thunder Toad';
      case 15:
        return 'Sellwood Archer';
      case 16:
        return 'Nox Jar';
      case 17:
        return 'Swift Squirrel';
      case 18:
        return 'Pied Piper of Leofare';
      case 19:
        return 'Sellwood Hargoth';
      case 20:
        return 'Sergeant Zahn';
      case 21:
        return 'Dee';
      case 22:
        return 'Dan';
      case 23:
        return 'Doggo of the Black Blade';
      case 24:
        return 'Mana Surge';
      case 25:
        return 'Soul Rend';
      case 26:
        return 'Attune Energy';
      case 27:
        return 'Deadly Concoction';
      case 28:
        return 'Studious Scholar';
      case 29:
        return 'Gunslinger Jam';
      case 30:
        return 'Axe Raider Brandon';
      case 31:
        return 'Fire Sphere';
      case 32:
        return 'Iceblade Leonin';
      case 33:
        return 'Backalley Goblin';
      case 34:
        return 'Flame Blade Rogue';
      case 35:
        return 'Dusk Phoenix';
      case 36:
        return 'Dusk Phoenix Egg';
      case 37:
        return 'Chipped Battleaxe';
      case 38:
        return 'Nox Toad';
      case 39:
        return 'Wild Growth';
      case 40:
        return 'Blood Moon';
      case 41:
        return 'Mecha Sun Cat Apollo';
      case 42:
        return 'Mecha Moon Cat Swiff';
      case 43:
        return 'Red Devil Rogue';
      case 44:
        return 'Starstorm';
      case 45:
        return 'Verdant Golem';
      case 46:
        return 'Mecha Earth Cat Artemis';
      case 47:
        return 'Sellwood Spirit Caller';
      case 48:
        return 'Mecha Hellcat Mittens';
      case 49:
        return 'Vanguard Pawn';
      case 50:
        return 'Vanguard Bishop';
      case 51:
        return 'Vanguard Rook';
      case 52:
        return 'Light Swords';
      case 53:
        return 'Manaspawn';
      case 54:
        return 'Sunbeam';
      case 55:
        return 'Cleanse';
      case 56:
        return 'To Dust';
      case 57:
        return 'Vanguard Knight';
      case 58:
        return 'Spring Totem';
      case 59:
        return 'Megawhale Vanada';
      case 60:
        return 'Mecha Thundercat Luffy';
      case 61:
        return 'Doggo';
      case 62:
        return 'Dogify';
      case 63:
        return 'Warp Gate';
      case 64:
        return 'Arcana Fool Lily';
      case 65:
        return 'Disrupting Tide';
      case 66:
        return 'Commander Hazen';
      case 67:
        return 'Navy Recruit';
      case 68:
        return 'Arcana Fortune Teller';
      case 69:
        return 'Roaring Battlecry Noelle';
      case 70:
        return 'Booty Hunter Clyde';
      case 71:
        return 'Doctor Lee';
      case 72:
        return 'White Mage Emtir';
      case 73:
        return 'Reptara Scout';
      case 74:
        return 'Eccentric Poisoner Adam';
      case 75:
        return 'Thunder Fists';
      case 76:
        return 'Zap';
      case 77:
        return 'Grizzly Bear';
      case 78:
        return 'Meran Skirmisher';
      case 79:
        return 'Meran Harpooner';
      case 80:
        return 'Nox-Eating Rat';
      case 81:
        return 'Starlight Heal';
      case 82:
        return 'Determined Engineer';
      case 83:
        return 'Death Reaper Mazin';
      case 84:
        return 'Lightning Boots';
      case 85:
        return 'Angelic Guard';
      case 86:
        return 'Divine General Moriel';
      case 87:
        return 'Breath of Renewal';
      case 88:
        return 'Lost in Mist';
      case 89:
        return 'Goth Girl Susan';
      case 90:
        return 'Flame Wreath Toad';
      case 91:
        return 'Daring Adventurer Gerty';
      case 92:
        return 'Natural Order';
      case 93:
        return 'Mana Tree Yustafar';
      case 94:
        return 'Duke Dairy';
      case 95:
        return 'Milk';
      case 96:
        return 'Thundersword Leonin';
      case 97:
        return 'Viper Spear Alaran';
      case 98:
        return 'Giant Viper';
      case 99:
        return 'Order Initiate';
      case 100:
        return 'Wild Mane Swordsman';
      case 101:
        return 'Shield Blessing';
      case 102:
        return 'Infernal Nail';
      case 103:
        return 'Syphon Mana';
      case 104:
        return 'Cemetery Keeper';
      case 105:
        return 'Lightning Mage Asta';
      case 106:
        return 'Old Man with Stick';
      case 107:
        return 'Graveyard Girl Elara';
      case 108:
        return 'Spirit Wolf';
      case 109:
        return 'Spectral Hunters';
      case 110:
        return 'Vanishing Shade';
      case 111:
        return 'Jungle Ape';
      case 112:
        return 'Castle Checkpoint';
      case 113:
        return 'Spellsap Mage';
      case 114:
        return 'Golem Guardian';
      case 115:
        return 'Princess Aurina';
      case 116:
        return 'Feline Familiar';
      case 117:
        return 'Meran Lord Azacenadar';
      case 118:
        return 'Hell Scourge Zandorus';
      case 119:
        return 'Lady Bellamina';
      case 120:
        return 'Apex Chimera Gorasax';
      case 121:
        return 'Vanguard Queen';
      case 122:
        return 'Force Spiral';
      case 123:
        return 'Wall of Ice';
      case 124:
        return 'Ice Wall';
      case 125:
        return 'Midnight Assassin';
      case 126:
        return 'Commune Spirit Wolves';
      case 127:
        return 'Raging Skeleton';
      case 128:
        return 'Red Light Infusion';
      case 129:
        return 'Raise Skeletons';
      case 130:
        return 'Glade Spirit Deer';
      case 131:
        return 'Summoning Bell';
      case 132:
        return 'Commune Shore Wraiths';
      case 133:
        return 'Shore Wraith';
      case 134:
        return 'Kit the Shifter';
      case 135:
        return 'Silver Reign';
    }
    return '';
  }
  getTextById(id: number): string {
    switch (id) {
      case 1:
        return '';
      case 2:
        return '<div class="CARD-center"><span class="CARD-bold">Ranged</span></div>';
      case 3:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Double Damage</span></div><span class="CARD-bold">DEPLOY</span> Make a <b>Bat</b> to the Left and Right';
      case 4:
        return '<div class="CARD-center"><span class="CARD-bold">Can\'t Attack</span></div>';
      case 5:
        return '<div class="CARD-center"><span class="CARD-bold">Ranged</span></div>';
      case 6:
        return '<div class="CARD-center">An <b>Ocean</b> Creature in Front of this has <b>+3000</b> and cannot be <b>Stunned</b></div>';
      case 7:
        return '<div class="CARD-center">This gets <b>+1000</b> when you draw a Card</div>';
      case 8:
        return '<div class="CARD-center"><span class="CARD-bold">Can\'t Attack</span></div>';
      case 9:
        return '<div class="CARD-center">Draw 2 Cards</div>';
      case 10:
        return '';
      case 11:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center">Cannot be targeted by Spells</div>';
      case 12:
        return '<div class="CARD-center">All enemy Creatures get <b>-1000</b>';
      case 13:
        return '<span class="CARD-bold">DESTROY</span> Flood this Row with <b>Rats</b>';
      case 14:
        return '<span class="CARD-bold">DEPLOY</span> <b>Stun</b> all enemies with <b>3000</b> Power or less';
      case 15:
        return '<div class="CARD-center"><span class="CARD-bold">Ranged</span></div>';
      case 16:
        return '<div class="CARD-center">When this has no <b>Energy</b>, your Opponent takes 1 <b>Damage</b></div>';
      case 17:
        return '';
      case 18:
        return '<span class="CARD-bold">DEPLOY</span> Flood your Board with <b>Rats</b>';
      case 19:
        return '<div class="CARD-center"><span class="CARD-bold">Breakthrough</span></div>';
      case 20:
        return '<span class="CARD-bold">DEPLOY</span> Make <b>Dee</b> and <b>Dan</b> to the Left and Right';
      case 21:
        return '';
      case 22:
        return '';
      case 23:
        return '';
      case 24:
        return '<div class="CARD-center">Gain 3 <b>Temporary Mana</b> this Turn</div>';
      case 25:
        return 'Destroy 1 Card';
      case 26:
        return 'Gain 1 <b>Mana</b>';
      case 27:
        return '<div class="CARD-center">Give a Creature <b>+1000</b> and <b>Deadly</b></div>';
      case 28:
        return '<div class="CARD-center">Spell Cards you play cost 1 less <b>Mana</b></div>';
      case 29:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Deadly</span><span class="CARD-keywordSpace"></span><span class="CARD-bold CARD-row">Ranged</span></div>';
      case 30:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Breakthrough</span></div><span class="CARD-bold">DEPLOY</span> Destroy enemy Creatures in this Column with <b>3000</b> Power or less';
      case 31:
        return '<div class="CARD-center">Destroy 1 Card with <b>2000</b> Power or less</div>';
      case 32:
        return '<span class="CARD-bold">DEPLOY</span> <b>Stun</b> all enemies in this Column';
      case 33:
        return '';
      case 34:
        return '<span class="CARD-bold">DEPLOY</span> Lose 1 Red <b>Mana</b>';
      case 35:
        return '<span class="CARD-bold">DESTROY</span> Make a <b>Dusk Phoenix Egg</b>';
      case 36:
        return '<div class="CARD-center">When this has no <b>Energy</b>, make a <b>Dusk Phoenix</b></div>';
      case 37:
        return '<div class="CARD-center">A Creature in Front of this has <b>+2000</b></div>';
      case 38:
        return '<div class="CARD-center"><span class="CARD-bold">Deadly</span></div>';
      case 39:
        return '<div class="CARD-center">Give a Creature <b>+3000</b></div>';
      case 40:
        return '<div class="CARD-center">Your <b>Purple</b> Creatures have <b>+1000</b></div>';
      case 41:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Double Damage</span></div><div class="CARD-center CARD-row">Cannot be targeted by Spells</div><span class="CARD-bold">DEPLOY</span> Destroy all other Creatures';
      case 42:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Double Damage</span></div><div class="CARD-center CARD-row">Cannot be targeted by Spells</div><span class="CARD-bold">DEPLOY</span> Destroy all other Creatures in this Column';
      case 43:
        return '<span class="CARD-bold">DEPLOY</span> The closest enemy in this Column gets <b>-2000</b>';
      case 44:
        return '<div class="CARD-center">Destroy all Cards with <b>3000</b> Power or less</div>';
      case 45:
        return '<div class="CARD-center">This has <b>+1000</b> for each of your other <b>Green</b> cards</div>';
      case 46:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Triple Damage</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center CARD-row">Cannot be targeted by Spells</div>';
      case 47:
        return '<span class="CARD-bold">DEPLOY</span> Creatures to the Left and Right gain <b>+1500</b>';
      case 48:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Double Damage</span></div>Cannot be targeted by spells';
      case 49:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Can\'t Attack</span></div><div class="CARD-center">If a Creature destroys this card, it gets <b>-1000</b></div>';
      case 50:
        return '<div class="CARD-center CARD-row">Allies Diagonal from this have <b>+1000</b></div><span class="CARD-bold">DEPLOY</span> Make a <b>Vanguard Pawn</b> in Front</div>';
      case 51:
        return '<div class="CARD-center CARD-row">Enemies in the same Column have <b>-1000</b></div><div class="CARD-center">Allies in the same Row have <b>+1000</b></div>';
      case 52:
        return '<div class="CARD-center">Creatures in this Column can\'t Attack</div>';
      case 53:
        return '<div class="CARD-center">This has <b>+1000</b> times your Yellow <b>Mana</b></div>';
      case 54:
        return '<div class="CARD-center">Destroy all Cards in a Column</div>';
      case 55:
        return '<div class="CARD-center"><b>Cleanse</b> 1 Card</div>';
      case 56:
        return '<div class="CARD-center">Destroy 1 <b>Exhausted</b> Creature</div>';
      case 57:
        return '<div class="CARD-center"><span class="CARD-bold">Ranged</span><span class="CARD-keywordSpace"></span><span class="CARD-bold">Shielded</span></div>';
      case 58:
        return '<span class="CARD-bold">ADD MANA</span> Your creatures get <b>+1000</b> till end of Turn';
      case 59:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><span class="CARD-bold">DEPLOY</span> <b>Stun</b> Front Row enemies and <b>Push</b> them Backwards';
      case 60:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Double Damage</span></div><div class="CARD-center CARD-row">Cannot be targeted by Spells</div><span class="CARD-bold">DEPLOY</span> <b>Stun</b> all enemy Creatures';
      case 61:
        return '';
      case 62:
        return '<div class="CARD-center"><b>Transform</b> a Card into a <b>Doggo</b></div>';
      case 63:
        return '<div class="CARD-center"><b>Return</b> a Card to its owner\'s Hand</div>';
      case 64:
        return '<span class="CARD-bold">DEPLOY</span> <b>Swap</b> the Front and Back row enemies in this Column</span>';
      case 65:
        return '<div class="CARD-center">Non-<b>Ocean</b> Creatures in the Back Row can\'t attack</div>';
      case 66:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Double Damage</span></div><span class="CARD-bold">DEPLOY</span> Flood your Back Row with <b>Navy Recruits</b>';
      case 67:
        return '<div class="CARD-center"><span class="CARD-bold">Ranged</span></div>';
      case 68:
        return '<span class="CARD-bold">DEPLOY</span> <b>Return</b> the closest enemy Creature in this Column to its owner\'s Hand';
      case 69:
        return '<span class="CARD-bold">DEPLOY</span> <b>Push</b> Front Row enemies Backwards. Your other Creatures get <b>+1000</b>';
      case 70:
        return '<span class="CARD-bold">DEPLOY</span> <b>Steal</b> the furthest enemy Monument in this Column and place it Behind this</span>';
      case 71:
        return '<span class="CARD-bold">TURN START</span> This gets <b>-1000</b>. Your other Creatures get <b>+1000</b>';
      case 72:
        return '<div class="CARD-center">This has <b>-2000</b> times how many other Creatures you have in play';
      case 73:
        return '';
      case 74:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Deadly</span></div><div class="CARD-center">All other Creatures get <b>-1000</b></div>';
      case 75:
        return '<div class="CARD-center">Give a Creature <b>+3000</b> until end of Turn</div>';
      case 76:
        return '<div class="CARD-center"><b>Stun</b> 1 Creature';
      case 77:
        return '';
      case 78:
        return '';
      case 79:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Ranged</span></div><span class="CARD-bold">DEPLOY</span> <b>Push</b> the closest enemy Creature in this Column Backwards';
      case 80:
        return '<span class="CARD-bold">DESTROY</span> If this was destroyed in Combat, your Opponent takes 1 <b>Damage</b>';
      case 81:
        return '<div class="CARD-center">Gain 1 Life</div>';
      case 82:
        return '<span class="CARD-bold">DEPLOY</span> Draw 1 Card';
      case 83:
        return '<span class="CARD-bold">COMBAT</span> Destroy all enemy Cards in the Front Row';
      case 84:
        return '<div class="CARD-center">A Creature in Front of this cannot be targeted by Spells';
      case 85:
        return '<div class="CARD-center"><span class="CARD-bold">Shielded</span></div>';
      case 86:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Double Damage</span></div><span class="CARD-bold">DEPLOY</span> All your Cards become <b>Shielded</b>';
      case 87:
        return '<div class="CARD-center"><b>Resurrect</b> 1 Creature</div>';
      case 88:
        return '<div class="CARD-center"><b>Exhaust</b> all Creatures</div>';
      case 89:
        return '<div class="CARD-center">Other creatures in this Row and in the same enemy Row have <b>-2000</b>';
      case 90:
        return '';
      case 91:
        return '<span class="CARD-bold">DESTROY</span> Draw 1 Card';
      case 92:
        return 'Destroy 1 Creature. Your opponent Adds 1 <b>Mana</b> of its Color';
      case 93:
        return '<span class="CARD-bold">TURN START</span> Add 4 <b>Temporary Green Mana</b> this Turn';
      case 94:
        return '<span class="CARD-bold">DEPLOY</span> Add 3 <b>Milk</b> to your Hand</div>';
      case 95:
        return '<div class="CARD-center CARD-row">When played on a <b>Lactomancer</b>, it gets <b>+1000</b> and becomes <b>Shielded</b></div><div class="CARD-center">Otherwise, <b>Stun</b> it';
      case 96:
        return '<span class="CARD-bold">DEPLOY</span> <b>Stun</b> the closest enemy Creature in this Column</span>';
      case 97:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Breakthrough</span></div><span class="CARD-bold">DEPLOY</span> Make a <b>Giant Viper</b> to the Left and Right';
      case 98:
        return '<div class="CARD-center"><span class="CARD-bold">Deadly</span></div>';
      case 99:
        return '<div class="CARD-center"><span class="CARD-bold">Shielded</span></div>';
      case 100:
        return '<div class="CARD-center">Cannot be <b>Stunned</b></div>';
      case 101:
        return '<div class="CARD-center">Give a Creature <b>+1000</b> and it becomes <b>Shielded</b></div>';
      case 102:
        return '<div class="CARD-center">Your Opponent takes 1 <b>Damage</b></div>';
      case 103:
        return '<div class="CARD-center">Your Opponent loses 1 <b>Mana</b></div>';
      case 104:
        return '<div class="CARD-center">This gets <b>+1000</b> when a Creature is destroyed</div>';
      case 105:
        return '<div class="CARD-center">This gets <b>+1000</b> when a Spell is played</div>';
      case 106:
        return '<div class="CARD-center">This gets <b>+1000</b> when it wins a Battle</div>';
      case 107:
        return '<div class="CARD-center CARD-row">Your <b>Undead</b> Creatures have <b>+2000</b></div><span class="CARD-bold">DESTROY</span> Lose 1 Life</span>';
      case 108:
        return '';
      case 109:
        return '<span class="CARD-bold">DESTROY</span> Create a <b>Spirit Wolf</b></div>';
      case 110:
        return '<span class="CARD-bold">DESTROY</span> If this loses Combat, return the Winner to its owner\'s Hand';
      case 111:
        return '<div class="CARD-center">This has <b>+1500</b> if you have <b>Green Mana</b></div>';
      case 112:
        return '';
      case 113:
        return '<span class="CARD-bold">DEPLOY</span> On your Opponent\'s next Turn their Spells cost <b>1</b> more</span>';
      case 114:
        return '<div class="CARD-center"><span class="CARD-bold">Can\'t Attack</span></div>';
      case 115:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center">This has <b>+1000</b> for each of your Monuments</div>';
      case 116:
        return '<div class="CARD-center"><b>Purple</b> Spells you play cost 1 less <b>Mana</b></div>';
      case 117:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Meran Evolution</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span><span class="CARD-keywordSpace"></span><span class="CARD-bold">Shielded</span></div><span class="CARD-bold">DEPLOY</span> Destroy all enemy Back row Cards</span>';
      case 118:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Devil Evolution</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center">When this destroys a Creature your Opponent discards a random Card</div>';
      case 119:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Demon Evolution</span></div><div class="CARD-center">When your Opponent plays a Creature it gets <b>-1000</b> and becomes <b>Stunned</b></div>';
      case 120:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Beast Evolution</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Breakthrough</span></div><div class="CARD-center">This has <b>+1500</b> for each of your other Beast Creatures</div>';
      case 121:
        return '<div class="CARD-center CARD-row"><span class="CARD-bold">Chess Evolution</span></div><div class="CARD-center CARD-row"><span class="CARD-bold">Shielded</span></div><div class="CARD-center">Enemies in the same Column and Diagonal from this have <b>-1000</b></div>';
      case 122:
        return '<div class="CARD-center"><b>Swap</b> all Front and Back row enemies</div>';
      case 123:
        return '';
      case 124:
        return '<div class="CARD-center">Flood your Front Row with <b>Wall of Ice</b></div>';
      case 125:
        return '<div class="CARD-center"><span class="CARD-bold CARD-row">Deadly</span><span class="CARD-keywordSpace"></span><span class="CARD-bold CARD-row">Stealth</span></div>';
      case 126:
        return '<div class="CARD-center">Flood your Front Row with <b>Exhausted Spirit Wolves</b></div>';
      case 127:
        return '<div class="CARD-center"><span class="CARD-bold">Breakthrough</span></div>';
      case 128:
        return '<div class="CARD-center"><b>Transform</b> your <b>Skeletons</b> into <b>Raging Skeletons</b></div>';
      case 129:
        return '<div class="CARD-center">Flood your Board with <b>Exhausted Skeletons</b></div>';
      case 130:
        return '<span class="CARD-bold">DEPLOY</span> This gets <b>+500</b> for every <b>Green</b> Card you\'ve played this Game</span>';
      case 131:
        return '<div class="CARD-center CARD-row">Your <b>Commune</b> Spells cost 1 less <b>Mana</b></div><div class="CARD-center CARD-row">Your <b>Spirits</b> have <b>+1000</b></div>';
      case 132:
        return '<div class="CARD-center">Flood your Front Row with <b>Exhausted Shore Wraiths</b></div>';
      case 133:
        return '<span class="CARD-bold">DESTROY</span> Draw 1 Card';
      case 134:
        return '<div class="CARD-center">This has <b>+500</b> for every other Creature</div>';
      case 135:
        return '<div class="CARD-center CARD-row">This gets <b>+1500</b> when it wins a Battle</div><span class="CARD-bold">TURN START</span> This gets <b>-1000</b>';
    }
    return '';
  }
}

class JapaneseCopySet implements CopySet {
  constructor() {}
  getNameById(id: number): string {
    switch (id) {
      case 1:
        return 'スケルトン';
      case 3:
        return 'ヴァンパイアレディ';
      case 40:
        return '血の月';
    }
    return '';
  }
  getTextById(id: number): string {
    switch (id) {
      case 1:
        return '';
      case 3:
        return '<b>二重ダメージ</b>';
      case 40:
        return 'あなたのすべての<b>闇</b>のクリーチャーは<b>+1000</b>の強化を受ける';
    }
    return '';
  }
}

export class CopyDatabase {
  private static copySet = new EnglishCopySet();
  static getNameById(id: number): string {
    return this.copySet.getNameById(id);
  }
  static getTextById(id: number): string {
    return this.copySet.getTextById(id);
  }
}
