import { ManaSet } from '../player';
import { RulesUtil } from '../util';

const maxSize = 8;
const startingSize = 4;

/**
 * A Hand of cards with max size of 8
 */
export class Hand {
  private cards: number[] = [];

  /**
   * How many cards are in this Hand
   */
  public get length(): number {
    return this.cards.length;
  }

  /**
   * Whether or not there are as many cards in this Hand as the starting size
   */
  public get hasRoomTillStartingCount(): boolean {
    return this.cards.length < startingSize;
  }

  /**
   * Whether or not this Hand has room to draw more cards
   */
  public get isFull(): boolean {
    return this.cards.length == maxSize;
  }

  /**
   * Whether or not this Hand is empty
   */
  public get isEmpty(): boolean {
    return this.cards.length == 0;
  }

  /**
   * Draw a card into this Hand
   * @param id The id of the card to draw
   * @returns Whether or not the card was accepted, or rejected due to Hand size
   */
  draw(id: number): boolean {
    if (this.isFull) return false;
    this.cards.push(id);
    return false;
  }

  /**
   * Remove a card at the specified index
   * @param index The index to remove a card at
   */
  removeAt(index: number) {
    // Ensure the index is within bounds
    if (index < 0 || index >= this.cards.length) {
      return;
    }

    // Create a new array excluding the element at the specified index
    this.cards = [...this.cards.slice(0, index), ...this.cards.slice(index + 1)];
  }

  /**
   * Get the card at the specified position
   * @param index The Hand index to get a card from
   * @returns The card id at the specified index, or null if it doesn't exist
   */
  get(index: number): number | null {
    return this.cards[index] ?? null;
  }

  /**
   * Check if this Hand contains a specified card id
   * @param id The card id to search for
   * @returns Whether this Hand has the specified id in it
   */
  includes(id: number): boolean {
    return this.cards.includes(id);
  }

  /**
   * Find the index of a card id in this hand
   * @param id The card id to look for
   * @returns The index of the id in the hand, or -1 if it doesn't exist
   */
  indexOf(id: number): number {
    return this.cards.indexOf(id);
  }

  /**
   * Determine whether there is a card in the Hand that can is inexpensive enough to cast
   * @param currentMana How much many the player has
   * @returns Whether or not there is a card in Hand that can be played with the current mana
   */
  canPlayAnything(manaSet: ManaSet): boolean {
    for (let i = 0; i < this.cards.length; i++) {
      if (RulesUtil.canAffordCard(this.cards[i], manaSet)) return true;
    }
    return false;
  }

  /**
   * Convert this into a network ready data object
   * @returns This as a network ready data object
   */
  asData(): number[] {
    return [...this.cards];
  }
}
