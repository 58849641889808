import React from 'react';
import { M } from '../../../uilib/Modifier';
import { RevealTile } from '../../component/RevealComponents';
import { ActionType } from 'tenant-common';

export function DebugRevealComponents(): JSX.Element {
  return (
    <div style={M.flexRow.fillSize.background('#222222').css()}>
      <div style={M.flexColumn.fillHeight.css()}>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.PLAY_ACTION}
            cardId={1}
            isLeft={true}
            shouldAnimate={false}
          />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.PLAY_ACTION}
            cardId={3}
            isLeft={false}
            shouldAnimate={false}
          />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.PLAY_ACTION}
            cardId={3}
            isLeft={false}
            shouldAnimate={true}
          />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.PLAY_ACTION} cardId={1} isLeft={true} shouldAnimate={true} />
        </div>
      </div>
      <div style={M.flexColumn.fillHeight.border('1px red solid').css()}>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.MANA_ACTION}
            cardId={1}
            isLeft={true}
            shouldAnimate={false}
          />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.MANA_ACTION}
            cardId={1}
            isLeft={false}
            shouldAnimate={false}
          />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.MANA_ACTION} cardId={1} isLeft={true} shouldAnimate={true} />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile
            type={ActionType.MANA_ACTION}
            cardId={1}
            isLeft={false}
            shouldAnimate={true}
          />
        </div>
      </div>
      <div style={M.flexColumn.fillHeight.border('1px red solid').css()}>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.PASS} isLeft={true} shouldAnimate={false} cardId={-1} />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.PASS} isLeft={false} shouldAnimate={false} cardId={-1} />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.PASS} isLeft={true} shouldAnimate={true} cardId={-1} />
        </div>
        <div style={M.height('32px').css()}></div>
        <div style={M.justifyStart.alignStart.width('360px').css()}>
          <RevealTile type={ActionType.PASS} isLeft={false} shouldAnimate={true} cardId={-1} />
        </div>
      </div>
    </div>
  );
}
