import { CardDatabase } from '../card';
import { ManaSet } from '../player';

/**
 * Utilities for helping sort out Game rules
 */
export class RulesUtil {
  /**
   * Determine if a player can afford to cast a certain card
   * @param cardId The id of the card
   * @param currentMana How much mana the player currently has
   * @param manaPool What mana colors the player currently has
   * @param discount A discount for the play
   * @returns If the player can afford to play the card
   */
  static canAffordCard(cardId: number, manaSet: ManaSet, discount: number = 0) {
    const card = CardDatabase.getCardById(cardId);
    const cost = discount < 0 ? 0 : card.cost - discount;
    return cost <= manaSet.currentMana && (cost == 0 || manaSet.manaPool[card.color]);
  }
}
