import React from 'react';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { DeckTile } from '../component/DeckTile';
import {
  Auth,
  CardColor,
  CardUiProperties,
  ConnectionInfo,
  JoinRoom,
  Message,
} from 'tenant-common';
import { Socket } from 'socket.io-client';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface AuthWindowProps {
  socket: Socket;
  onLogin: (connectionInfo: ConnectionInfo) => void;
}
export function AuthWindow({ socket, onLogin }: AuthWindowProps): JSX.Element {
  const roomId = 1337;
  const cookieDeck = Cookies.get('deckIndex');
  const [chosenDeck, setChosenDeck] = useState<number>(
    cookieDeck !== undefined ? parseInt(cookieDeck) : 0,
  );
  const storedName = Cookies.get('username');
  const [username, setUserName] = useState(storedName ?? '');

  const login = async () => {
    Cookies.set('username', username);
    Cookies.set('deckIndex', chosenDeck + '');
    // TODO wait for ack with built in function or otherwise
    console.log(`LOGIN: ${username}`);
    socket.emit(Message.AUTH, JSON.stringify({ username: username, deck: chosenDeck } as Auth));
    await delay(100);
    socket.emit(Message.JOIN_ROOM, JSON.stringify({ id: roomId } as JoinRoom));
    onLogin(new ConnectionInfo(username, chosenDeck));
  };

  const submitLogin = (e: React.FormEvent) => {
    e.preventDefault();
    login();
  };
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    alignItems: 'center',
    overflowY: 'scroll',
  };
  const deckRowStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflowY: 'scroll',
  };

  return (
    <div style={style}>
      <form onSubmit={submitLogin}>
        <input value={username} onChange={(e) => setUserName(e.target.value)} />
        <button type="submit">Login</button>
      </form>
      <br></br>
      <div style={deckRowStyle}>
        <DeckTile
          coverImageId={3}
          name="Purple Deck"
          color={CardUiProperties.getColorForType(CardColor.BLACK)}
          selected={chosenDeck == 0}
          onClick={() => setChosenDeck(0)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={29}
          name="Red Deck"
          color={CardUiProperties.getColorForType(CardColor.RED)}
          selected={chosenDeck == 1}
          onClick={() => setChosenDeck(1)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={62}
          name="Blue Deck"
          color={CardUiProperties.getColorForType(CardColor.BLUE)}
          selected={chosenDeck == 2}
          onClick={() => setChosenDeck(2)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={26}
          name="Green Deck"
          color={CardUiProperties.getColorForType(CardColor.GREEN)}
          selected={chosenDeck == 3}
          onClick={() => setChosenDeck(3)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={72}
          name="Yellow Deck"
          color={CardUiProperties.getColorForType(CardColor.WHITE)}
          selected={chosenDeck == 4}
          onClick={() => setChosenDeck(4)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={83}
          name="GB Deck"
          color={'#42874b'}
          selected={chosenDeck == 5}
          onClick={() => setChosenDeck(5)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={94}
          name="Milk Deck"
          color={'#eeeeee'}
          selected={chosenDeck == 6}
          onClick={() => setChosenDeck(6)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={41}
          name="Bailey Deck"
          color={'#333333'}
          selected={chosenDeck == 7}
          onClick={() => setChosenDeck(7)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={4}
          name="Michael Simic"
          color={'#444444'}
          selected={chosenDeck == 8}
          onClick={() => setChosenDeck(8)}
        />
        <div style={{ width: '16px', height: '16px' }}></div>
        <DeckTile
          coverImageId={10}
          name="NIC BR"
          color={'#FF3535'}
          selected={chosenDeck == 9}
          onClick={() => setChosenDeck(9)}
        />
        <DeckTile
          coverImageId={4}
          name="MER B"
          color={'#3333cc'}
          selected={chosenDeck == 10}
          onClick={() => setChosenDeck(10)}
        />
      </div>
    </div>
  );
}
