import { PlayerModifierData } from './ModifierData';

export class PlayerData {
  constructor(
    public readonly username: string,
    public currentMana: number,
    public maxMana: number,
    public readonly life: number,
    public readonly modifier?: PlayerModifierData,
    public readonly manaPool?: boolean[],
  ) {}
}
