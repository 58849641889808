import { Game } from '../Game';
import { CardEffectContext, CardPermanentInstance, CardSpellInstance } from '../card';

export abstract class Effect {
  constructor(
    public readonly ownerId: string,
    public readonly instance: CardPermanentInstance | null,
    public readonly source: CardPermanentInstance | CardSpellInstance | null | undefined,
    public readonly x: number,
    public readonly y: number,
  ) {}
  abstract process(battlefield: Game): Effect[];
}

export class DeathEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly instance: CardPermanentInstance,
    public readonly source: CardPermanentInstance | CardSpellInstance | null | undefined,
    public readonly x: number,
    public readonly y: number,
  ) {
    super(ownerId, instance, source, x, y);
  }

  process(battlefield: Game): Effect[] {
    if (this.instance && this.instance.onDestroy) {
      return (
        this.instance.onDestroy({
          x: this.x,
          y: this.y,
          ownerId: this.ownerId,
          battlefield: battlefield,
          source: this.source,
          instance: this.instance,
        } as CardEffectContext) ?? []
      );
    } else {
      return [];
    }
  }
}

export class DeployEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly instance: CardPermanentInstance,
    public readonly x: number,
    public readonly y: number,
  ) {
    super(ownerId, instance, instance, x, y);
  }

  process(battlefield: Game): Effect[] {
    if (this.instance && this.instance.onDeploy) {
      return (
        this.instance.onDeploy({
          x: this.x,
          y: this.y,
          ownerId: this.ownerId,
          battlefield: battlefield,
          instance: this.instance,
          source: this.instance,
        } as CardEffectContext) ?? []
      );
    } else {
      return [];
    }
  }
}

export class AddManaEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly instance: CardPermanentInstance,
    public readonly source: CardPermanentInstance | CardSpellInstance | null | undefined,
    public readonly x: number,
    public readonly y: number,
    public readonly action: (context: CardEffectContext) => Effect[],
  ) {
    super(ownerId, instance, source, x, y);
  }

  // TODO make this add the mana!
  process(battlefield: Game): Effect[] {
    // return this.action({
    //   battlefield: battlefield,
    //   instance: this.instance,
    //   ownerId: this.ownerId,
    //   x: this.x,
    //   y: this.y,
    //   source: this.source,
    // } as CardEffectContext);
    return [];
  }
}

export class LoseLifeEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly amount: number,
    public readonly source: CardPermanentInstance | CardSpellInstance | null | undefined,
  ) {
    super(ownerId, null, source, -1, -1);
  }

  process(battlefield: Game): Effect[] {
    if (this.amount > 0) {
      battlefield.getPlayerById(this.ownerId)?.player.takeDirectDamage(this.amount - 1);
    }
    return [];
  }
}

export class BounceEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly x: number,
    public readonly y: number,
  ) {
    super(ownerId, null, null, x, y);
  }

  process(battlefield: Game): Effect[] {
    const player = battlefield.getPlayerById(this.ownerId);
    player?.returnToHand(this.x, this.y);
    return [];
  }
}

// Make an ATTEMPT relocate and a relocate. This decouples it from unsuccessful moves
export class RelocateEffect extends Effect {
  constructor(
    public readonly ownerId: string,
    public readonly x1: number,
    public readonly y1: number,
    public readonly x2: number,
    public readonly y2: number,
  ) {
    super(ownerId, null, null, x1, y1);
  }

  process(battlefield: Game): Effect[] {
    const player = battlefield.getPlayerById(this.ownerId);
    player?.returnToHand(this.x, this.y);
    return [];
  }
}
