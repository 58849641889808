import { Game } from './Game';
import { CardEffectContext, CardPermanentInstance, CardSpellInstance, CardType } from './card';
import { PlayerInstance } from './player';
import { Field1v1Data, Point } from './data';
import { DeathEffect, DeployEffect, Effect } from './domain';

export class PlaySide {
  private markedForDeath: Point[] = [];
  public frontRow: (CardPermanentInstance | null)[];
  public backRow: (CardPermanentInstance | null)[];
  constructor(
    public readonly ownerId: string,
    public readonly battlefield: Game,
    public readonly width: number = 3,
    public readonly height: number = 2,
  ) {
    this.frontRow = new Array(width).fill(null);
    this.backRow = new Array(width).fill(null);
  }

  play(instance: CardPermanentInstance, x: number, y: number): Effect[] {
    if (x >= this.width) throw `Invalid x value! Greater than ${this.width}`;
    if (y >= this.height) throw `Invalid y value! Greater than ${this.width}`;
    if (x < 0) throw `Invalid x value! Below 0!`;
    if (y < 0) throw `Invalid y value! Below 0!`;

    const toPlay = y == 0 ? this.backRow : this.frontRow;
    const currentSquare = toPlay[x];
    // TODO mana values
    if (currentSquare) {
      throw 'Board already occupied!';
    } else {
      toPlay[x] = instance;
      if (instance.onDeploy) {
        return [new DeployEffect(this.ownerId, instance, x, y)];
      } else return [];
    }
  }

  playSpell(
    instance: CardSpellInstance,
    x: number,
    y: number,
    localTarget: boolean,
    target: CardPermanentInstance | null,
  ): Effect[] {
    // TODO non targeting spells with shroud...
    // Shrouded targets can't be targeted
    if (target && !target.canBeSpellTargeted) {
      return [];
    }

    return (
      instance.base.onPlay?.({
        x: x,
        y: y,
        localTarget: localTarget,
        instance: target,
        ownerId: this.ownerId,
        battlefield: this.battlefield,
      } as CardEffectContext) ?? []
    );
  }

  canAnythingAttack(): boolean {
    let ret = false;
    this.onAll((instance) => {
      if (instance !== null) {
        ret = ret || instance.isReadyForAttack;
      }
    });
    return ret;
  }

  onAll(action: (card: CardPermanentInstance | null, x: number, y: number) => void) {
    this.frontRow.forEach((card: CardPermanentInstance | null, index: number) =>
      action(card, index, 1),
    );
    this.backRow.forEach((card: CardPermanentInstance | null, index: number) =>
      action(card, index, 0),
    );
  }

  performAttacks(enemyBoard: PlaySide, opponent: PlayerInstance) {
    for (let i = 0; i < this.width; i++) {
      this.attackColumn(i, enemyBoard, opponent);
    }
  }

  private attackColumn(index: number, enemyBoard: PlaySide, opponent: PlayerInstance) {
    // Attack with front row
    let c = this.frontRow[index];
    if (c && c.isReadyForAttack) {
      this.creatureAttack(index, c, enemyBoard, opponent);
    }
    // Attack with back row
    c = this.backRow[index];
    // TODO cleave, breakthrough
    if (c && c.isReadyForAttack && (this.frontRow[index] == null || c.isRanged)) {
      this.creatureAttack(index, c, enemyBoard, opponent);
    }
  }

  private creatureAttack(
    index: number,
    c: CardPermanentInstance,
    enemyBoard: PlaySide,
    enemy: PlayerInstance,
  ) {
    if (!c.canAttack) return;

    // TODO optimize
    const targets = [enemyBoard.frontRow[index], enemyBoard.backRow[index], null];
    let e = targets[0];
    for (let i = 0; i < targets.length; i++) {
      e = targets[i];
      if (e !== null) break;
    }

    // TODO modifiers
    if (e !== null) {
      c.fight(e, enemy);
    } else {
      enemy.takeDirectDamage(c.extraDamage ?? 0);
    }
  }

  markDeadBodiesForRemoval() {
    for (let i = 0; i < this.width; i++) {
      let c = this.frontRow[i];
      if (c && c.isDead) {
        this.markedForDeath.push({ x: i, y: 1 });
      }
      c = this.backRow[i];
      if (c && c.isDead) {
        this.markedForDeath.push({ x: i, y: 0 });
      }
    }
  }

  purge(): Effect[] {
    const effects: Effect[] = [];
    while (this.markedForDeath.length > 0) {
      const point = this.markedForDeath.pop();
      if (point) {
        const effect = this.destroy(point.x, point.y);
        if (effect) {
          effects.push(effect);
        }
      }
    }
    return effects;
  }

  destroy(x: number, y: number): DeathEffect | null {
    const card = this.get(x, y);
    this.set(x, y, null);
    if (card && card.onDestroy) {
      return new DeathEffect(this.ownerId, card, card.destroyer, x, y);
    }
    return null;
  }

  isEmpty(x: number, y: number) {
    return (
      x >= 0 &&
      x <= this.width &&
      y >= 0 &&
      y <= this.height &&
      ((y == 0 ? this.backRow : this.frontRow) as (CardPermanentInstance | null)[])[x] == null
    );
  }

  closestCreature(x: number): CardPermanentInstance | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 1);
    if (e1?.type == CardType.CREATURE) return e1;
    const e2 = this.get(x, 0);
    if (e2?.type == CardType.CREATURE) return e2;
    return null;
  }

  closestCreatureY(x: number): number | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 1);
    if (e1?.type == CardType.CREATURE) return 1;
    const e2 = this.get(x, 0);
    if (e2?.type == CardType.CREATURE) return 0;
    return null;
  }

  furthestCreature(x: number): CardPermanentInstance | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 0);
    if (e1?.type == CardType.CREATURE) return e1;
    const e2 = this.get(x, 1);
    if (e2?.type == CardType.CREATURE) return e2;
    return null;
  }

  furthestCreatureY(x: number): number | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 0);
    if (e1?.type == CardType.CREATURE) return 0;
    const e2 = this.get(x, 1);
    if (e2?.type == CardType.CREATURE) return 1;
    return null;
  }

  closestMonument(x: number): CardPermanentInstance | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 1);
    if (e1?.type == CardType.MONUMENT) return e1;
    const e2 = this.get(x, 0);
    if (e2?.type == CardType.MONUMENT) return e2;
    return null;
  }

  furthestMonument(x: number): CardPermanentInstance | null {
    if (x < 0 || x > this.width) return null;
    const e1 = this.get(x, 0);
    if (e1?.type == CardType.MONUMENT) return e1;
    const e2 = this.get(x, 1);
    if (e2?.type == CardType.MONUMENT) return e2;
    return null;
  }

  swapColumn(x: number) {
    const e1 = this.get(x, 0);
    const e2 = this.get(x, 1);
    this.frontRow[x] = e1;
    this.backRow[x] = e2;
  }

  pushCard(x: number): boolean {
    const e1 = this.get(x, 1);
    if (e1 && this.backRow[x] == null) {
      this.backRow[x] = e1;
      this.frontRow[x] = null;
      return true;
    }
    return false;
  }

  pushCreature(x: number): boolean {
    const e1 = this.get(x, 1);
    if (e1 && e1.type == CardType.CREATURE && this.backRow[x] == null) {
      this.backRow[x] = e1;
      this.frontRow[x] = null;
      return true;
    }
    return false;
  }

  get(x: number, y: number): CardPermanentInstance | null {
    if (x < 0 || x > this.width) return null;
    if (y < 0 || y > this.height) return null;
    return (y == 0 ? this.backRow : this.frontRow)[x];
  }

  set(x: number, y: number, card: CardPermanentInstance | null) {
    if (x < 0 || x > this.width) return null;
    if (y < 0 || y > this.height) return null;
    (y == 0 ? this.backRow : this.frontRow)[x] = card;
  }

  asData(): Field1v1Data {
    return {
      front: this.frontRow.map((card: CardPermanentInstance | null) =>
        card ? card?.asData() : null,
      ),
      back: this.backRow.map((card: CardPermanentInstance | null) => (card ? card.asData() : null)),
    };
  }
}
