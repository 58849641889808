export class TenantResponseCode {
  static UNKNOWN = 404;
  static OK = 200;

  static ERROR = 500;
  static PLAYER_MISSING = 501;
  static ALREADY_QUEUED = 501;
  static CANT_PLAY_MANA = 502;
  static HAND_ACCESS = 503;
  static WRONG_PHASE = 504;

  static MANA_TOO_LOW = 510;
  static TILE_FULL = 511;
  static MANA_NO_COLOR = 512;
}
