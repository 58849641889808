import { Game } from '../Game';
import { Effect } from '../domain';
import { PlayerModifierSet } from '../player/PlayerModifier';
import { Attribute } from './Attribute';
import { CardColor } from './CardColor';
import { CardPermanentInstance, CardSpellInstance } from './CardInstance';
import { CharacterToModifiersMap } from './CardModifier';

export enum CardType {
  CREATURE = 1,
  MONUMENT = 2,
  SPELL = 3,
}

export type CardEffectContext = {
  battlefield: Game;
  ownerId: string;
  instance: CardPermanentInstance | null;
  x: number;
  y: number;
  localTarget: boolean;
  source?: CardPermanentInstance | CardSpellInstance;
};

export class Card {
  constructor(
    public readonly type: CardType,
    public readonly id: number,
    public readonly cost: number,
    public readonly color: CardColor,
    public readonly attributes: Attribute[],
  ) {}
}

export abstract class PermanentCard extends Card {
  constructor(
    public readonly type: CardType,
    public readonly id: number,
    public readonly cost: number,
    public readonly color: CardColor,
    public readonly attributes: Attribute[],
    public readonly isRanged: boolean = false,
    public readonly attackDisabled: boolean = false,
    public readonly isDeadly: boolean = false,
    public readonly hasBreakthrough: boolean = false,
    public readonly isShielded: boolean = false,
    public readonly onDeath: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onDeploy: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onCombat: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onAddMana: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly getStaticModifiers:
      | ((context: CardEffectContext) => CharacterToModifiersMap)
      | undefined,
    public readonly getUserStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
    public readonly getEnemyStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
  ) {
    super(type, id, cost, color, attributes);
  }
}
export class CreatureCard extends PermanentCard {
  constructor(
    public readonly id: number,
    public readonly cost: number,
    public readonly color: CardColor,
    public readonly attributes: Attribute[],
    public readonly attack: number,
    public readonly isRanged: boolean = false,
    public readonly attackDisabled: boolean = false,
    public readonly isDeadly: boolean = false,
    public readonly hasBreakthrough: boolean = false,
    public readonly isShielded: boolean = false,
    public readonly extraDamage: number = 0,
    public readonly showPlusPower: boolean = false,
    public readonly onDeath: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onDeploy: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onCombat: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onAddMana: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly getStaticModifiers:
      | ((context: CardEffectContext) => CharacterToModifiersMap)
      | undefined,
    public readonly getUserStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
    public readonly getEnemyStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
  ) {
    super(
      CardType.CREATURE,
      id,
      cost,
      color,
      attributes,
      isRanged,
      attackDisabled,
      isDeadly,
      hasBreakthrough,
      isShielded,
      onDeath,
      onDeploy,
      onCombat,
      onAddMana,
      getStaticModifiers,
      getUserStaticModifiers,
      getEnemyStaticModifiers,
    );
  }
}
export class MonumentCard extends PermanentCard {
  constructor(
    public readonly id: number,
    public readonly cost: number,
    public readonly color: CardColor,
    public readonly attributes: Attribute[],
    public readonly maxEnergy: number | null,
    public readonly isShielded: boolean,
    // TODO make these false?
    public readonly onDeath: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onDeploy: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onCombat: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly onAddMana: ((context: CardEffectContext) => Effect[]) | undefined,
    public readonly getStaticModifiers:
      | ((context: CardEffectContext) => CharacterToModifiersMap)
      | undefined,
    public readonly getUserStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
    public readonly getEnemyStaticModifiers:
      | ((context: CardEffectContext) => PlayerModifierSet)
      | undefined,
    public readonly onTimeEffectTriggered: ((context: CardEffectContext) => void) | undefined,
  ) {
    super(
      CardType.MONUMENT,
      id,
      cost,
      color,
      attributes,
      false,
      true,
      false,
      false,
      isShielded,
      onDeath,
      onDeploy,
      onCombat,
      onAddMana,
      getStaticModifiers,
      getUserStaticModifiers,
      getEnemyStaticModifiers,
    );
  }
}

export class SpellCard extends Card {
  constructor(
    public readonly id: number,
    public readonly cost: number,
    public readonly color: CardColor,
    public readonly attributes: Attribute[],
    public readonly onPlay: ((context: CardEffectContext) => Effect[]) | undefined,
  ) {
    super(CardType.SPELL, id, cost, color, attributes);
  }
}
