import {
  PlayCardActionRequest,
  MakeManaActionRequest,
  SkipActionRequest,
  RevealActionsRequest,
} from './UserAction';
import { FullData } from '../data/FullData';

export class TenantMessage {
  static PLAY = 'PLAY';
  static FULL_DATA = 'FULL_DATA';
  static MAKE_MANA = 'MAKE_MANA';
  static SKIP = 'SKIP';
}

export class TenantPayload {
  static parsePlay(payload: string): PlayCardActionRequest | null {
    try {
      const play: PlayCardActionRequest = JSON.parse(payload) as PlayCardActionRequest;
      return play;
    } catch (error) {
      console.error('PLAY parsing error: ', error);
    }
    return null;
  }

  static parseFullData(payload: string): FullData | null {
    try {
      const status: FullData = JSON.parse(payload) as FullData;
      return status;
    } catch (error) {
      console.error('FULL_DATA parsing error: ', error);
    }
    return null;
  }

  static parseMakeMana(payload: string): MakeManaActionRequest | null {
    try {
      const play: MakeManaActionRequest = JSON.parse(payload) as MakeManaActionRequest;
      return play;
    } catch (error) {
      console.error('MAKE_MANA parsing error: ', error);
    }
    return null;
  }

  static parseSkip(payload: string): SkipActionRequest | null {
    try {
      const play: SkipActionRequest = JSON.parse(payload) as SkipActionRequest;
      return play;
    } catch (error) {
      console.error('SKIP parsing error: ', error);
    }
    return null;
  }

  static parseRevealActionsRequest(payload: string): RevealActionsRequest | null {
    try {
      const play: RevealActionsRequest = JSON.parse(payload) as RevealActionsRequest;
      return play;
    } catch (error) {
      console.error('REVEAL ACTION parsing error: ', error);
      console.log(payload);
    }
    return null;
  }
}
