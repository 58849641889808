import React, { useEffect, useState } from 'react';
import Timer from '../component/Timer';
import { M } from '../../uilib/Modifier';
import { GameBoardPanel } from '../panel/board/GameBoardPanel';
import { NetworkPlayInterface } from '../../domain/NetworkPlayInterface';

interface GameScreenProps {
  playInterface: NetworkPlayInterface;
}
export function GameScreen({ playInterface }: GameScreenProps): JSX.Element {
  const [cardPreviewId, setCardPreviewId] = useState<number | null>(null);

  useEffect(() => {
    if (playInterface.state.revealedActions != null) {
      const timer = setTimeout(() => {
        playInterface.clearRevealedActions();
      }, 1750);
      return () => clearTimeout(timer);
    }
  }, [playInterface.state.revealedActions]);

  if (!playInterface.state.gameState) return <></>;
  return (
    <>
      <div style={M.fillWidth.flexRow.css()}>
        {playInterface.state.turnCount}&nbsp;-&nbsp;
        <Timer />
      </div>
      <GameBoardPanel
        gamePhase={playInterface.state.currentGamePhase}
        state={playInterface.state.gameState}
        onClickTile={(target) => {
          if (playInterface.state.selectedHandIndex != null)
            playInterface.performPlayCard(playInterface.state.selectedHandIndex, target);
        }}
        selectedHandIndex={playInterface.state.selectedHandIndex}
        onChangeHandSelection={(index: number) => {
          const previous = playInterface.state.selectedHandIndex;
          playInterface.setSelectedHandIndex(index == previous ? null : index);
        }}
        onClickAddMana={(index) => {
          if (index != null) playInterface.performAddMana(index);
        }}
        onSkip={playInterface.performSkip}
        hasSkipped={playInterface.state.playerHasSkipped}
        hasPlayedMana={playInterface.state.localHasPlayerPlayedManaThisTurn}
        cardPreviewId={cardPreviewId}
        revealActionsRequest={playInterface.state.revealedActions}
        onLongPressCard={(id) => setCardPreviewId(id)}
        onDismissCardPreview={() => setCardPreviewId(null)}
      />
    </>
  );
}
