import { PlayerData } from '../data';
import { CardColorMapUtil } from '../domain';
import { ManaSet } from './ManaSet';
import { PlayerModifierSet } from './PlayerModifier';

export class Player {
  constructor(public readonly id: string) {}
}

export class PlayerInstance {
  static startingLife = 5;
  mana = new ManaSet();

  // TODO leader based
  public life: number = PlayerInstance.startingLife;
  private _modifierSet: PlayerModifierSet = new PlayerModifierSet();

  constructor(public readonly base: Player) {}

  get modifier(): PlayerModifierSet {
    return this._modifierSet;
  }

  takeDirectDamage(extraDamage: number = 0) {
    this.life -= 1 + extraDamage;
  }

  // TODO events
  takeOverdrawDamage() {
    // this.life -= amount;
    // this.life -= 1;
    // TODO
  }

  heal(amount: number) {
    // TODO heal event
    this.life += amount;
  }

  setModifier(modifierSet: PlayerModifierSet) {
    this._modifierSet = modifierSet;
  }

  asData(): PlayerData {
    return {
      username: this.base.id,
      currentMana: this.mana.currentMana,
      maxMana: this.mana.maxMana,
      life: this.life,
      modifier: this._modifierSet.asData(),
      manaPool: CardColorMapUtil.toArray(this.mana.manaPool),
    };
  }
}
