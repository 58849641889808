import React from 'react';
import { Chat } from 'tenant-common';

interface ChatPanelProps {
  messages: Chat[];
}
export function ChatPanel({ messages }: ChatPanelProps): JSX.Element {
  console.log('CHAT PANEL RENDER! ' + messages.length);
  return (
    <ul>
      {messages.map((chat, index) => (
        <li key={index}>
          {chat.username}: {chat.message}
        </li>
      ))}
    </ul>
  );
}
