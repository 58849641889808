import { CardColor } from '../card';

/**
 * An alias type for CardColor->boolean
 */
export type CardColorMap = {
  [key in CardColor]: boolean;
};

/**
 * Utilities for dealing with CardColorMap objects
 */
export class CardColorMapUtil {
  /**
   * Create an empty CardColorMap with all false values
   * @returns An empty CardColorMap
   */
  static empty(): CardColorMap {
    return {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    };
  }

  /**
   * Convert a CardColorMap to a boolean array
   * @param map The CardColorMap to convert
   * @returns A boolean array representing the CardColorMap
   */
  static toArray(map: CardColorMap): boolean[] {
    return [map[0], map[1], map[2], map[3], map[4], map[5]];
  }

  /**
   * Create a CardColorMap from a boolean array
   * @param array The array to turn into a CardColorMap
   * @returns A CardColorMap representing the boolean array
   */
  static fromArray(array: boolean[]): CardColorMap {
    return {
      0: array[0] ?? false,
      1: array[1] ?? false,
      2: array[2] ?? false,
      3: array[3] ?? false,
      4: array[4] ?? false,
      5: array[5] ?? false,
    };
  }
}
