import { ArrayUtil } from '../util';

/**
 * A Deck of cards
 */
export class Deck {
  private cards: number[];

  constructor(cardIds: number[], shuffle: boolean = true) {
    this.cards = [...cardIds];
    if (shuffle) {
      ArrayUtil.shuffleArray(this.cards);
    }
  }

  /**
   * How many cards are in this Deck
   */
  public get length(): number {
    return this.cards.length;
  }

  /**
   * Draw a card from the Deck
   * @returns The top card of the Deck, or null if the Deck is empty
   */
  draw(): number | null {
    return this.cards.pop() ?? null;
  }
}
