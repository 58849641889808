import { ModifierData } from './ModifierData';

export class CardInstanceData {
  constructor(
    // Base fields
    public readonly id: number,
    // Instance fields
    // public readonly energy: number,
    // public readonly maxEnergy: number,
    public readonly isExhausted: boolean = false,
    public readonly isStunned: boolean = false,
    public readonly isShielded: boolean = false,
    public readonly usedEnergy: number = 0,
    public readonly modifier?: ModifierData,
    public readonly isFrontendPreview?: boolean,
  ) {}
}
