import { Game } from '../Game';
import { PlayerModifierData } from '../data/ModifierData';

export interface PlayerModifier {
  id: number;
  allSpellCostReduction?: ((battlefield: Game) => number | null) | undefined;
  canAddMana?: ((battlefield: Game) => boolean | null) | undefined;
}

export class PlayerModifierSet {
  _modifiers: PlayerModifier[] = [];
  _staticModifiers: PlayerModifier[] = [];
  _eotModifiers: PlayerModifier[] = [];
  _modifiedAllSpellCostReduction: number | null = null;
  _modifiedCanAddMana: boolean | null = null;

  get allSpellCostReduction(): number | null {
    return this._modifiedAllSpellCostReduction;
  }

  get canAddMana(): boolean | null {
    return this._modifiedCanAddMana;
  }

  addModifier(modifier: PlayerModifier, battlefield: Game) {
    this._modifiers.push(modifier);
    this.calculate(battlefield);
  }

  addStaticModifier(modifier: PlayerModifier, battlefield: Game) {
    this._staticModifiers.push(modifier);
    this.calculate(battlefield);
  }

  addEotModifier(modifier: PlayerModifier, battlefield: Game) {
    this._eotModifiers.push(modifier);
    this.calculate(battlefield);
  }

  clearStaticModifiers(battlefield: Game) {
    this._staticModifiers = [];
    this.calculate(battlefield);
  }

  clearEotModifiers(battlefield: Game) {
    this._eotModifiers = [];
    this.calculate(battlefield);
  }

  private calculate(battlefield: Game) {
    this._modifiedAllSpellCostReduction = this.calculateAllSpellCostReduction(battlefield);
    this._modifiedCanAddMana = this.calculateCanAddMana(battlefield);
  }

  private calculateAllSpellCostReduction(battlefield: Game): number | null {
    let hasNonNullAllSpellCostReduction = false;

    const totalReduction = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum, item) => {
        const reduction = item.allSpellCostReduction
          ? item.allSpellCostReduction(battlefield)
          : null;
        if (reduction !== null) {
          hasNonNullAllSpellCostReduction = true;
          return sum + reduction;
        }
        return sum;
      }, 0);

    return hasNonNullAllSpellCostReduction ? totalReduction : null;
  }

  private calculateCanAddMana(battlefield: Game): boolean | null {
    let hasNonNullCanAddMana = false;
    let ret = true;
    // TODO optimize
    const totalCanAddMana = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAddMana = item.canAddMana ? item.canAddMana(battlefield) : null;
        if (canAddMana !== null) {
          hasNonNullCanAddMana = true;
          ret = ret && canAddMana;
        }
        return sum;
      }, true);

    return hasNonNullCanAddMana ? ret : null;
  }

  getModifierIds(): number[] {
    return this._modifiers.map((modifier) => modifier.id);
  }

  combine(other: PlayerModifierSet): PlayerModifierSet {
    const ret = new PlayerModifierSet();
    ret._modifiers = [...this._modifiers, ...other._modifiers];
    ret._staticModifiers = [...this._staticModifiers, ...other._staticModifiers];
    ret._eotModifiers = [...this._eotModifiers, ...other._staticModifiers];
    ret._modifiedAllSpellCostReduction =
      (this._modifiedAllSpellCostReduction ?? 0) + (other._modifiedAllSpellCostReduction ?? 0);
    ret._modifiedCanAddMana =
      (this._modifiedCanAddMana ?? true) && other._modifiedCanAddMana && true;
    return ret;
  }

  asData(): PlayerModifierData {
    return {
      allSpellCostReduction: this.allSpellCostReduction,
      canAddMana: this.canAddMana,
    };
  }
}

export class PlayerModifierDatabase {
  static getModifierById(id: number): PlayerModifier {
    switch (id) {
      case 0: // Spell cost reduction
        return {
          id: id,
          allSpellCostReduction: () => {
            return 1;
          },
        } as PlayerModifier;
      case 1: // No mana
        return {
          id: id,
          canAddMana: () => {
            return false;
          },
        } as PlayerModifier;
    }
    throw 'Invalid card ID!';
  }
}
