import { CardColor } from '../card';
import { CardColorMap, CardColorMapUtil } from '../domain';

/**
 * A set of mana for a player, including current, maximum, and what colors of mana are available
 */
export class ManaSet {
  private current;
  private max;
  private pool;

  constructor(max: number = 0, pool: CardColorMap = CardColorMapUtil.empty()) {
    this.max = max;
    this.current = max;
    this.pool = pool;
  }

  /**
   * The current mana in the set
   */
  get currentMana(): number {
    return this.current;
  }

  /**
   * The max mana in the set
   */
  get maxMana(): number {
    return this.max;
  }

  /**
   * The mana color values for this set
   */
  get manaPool(): CardColorMap {
    return this.pool;
  }

  /**
   * Reset the current mana to maximum, removing any temporary mana
   */
  refill() {
    this.current = this.max;
  }

  /**
   * Gain max mana of a specified color
   * @param color The color of mana that was gained
   */
  gainMaxMana(color: CardColor) {
    this.max += 1;
    this.current += 1;
    this.pool[color] = true;
  }

  /**
   * Lose a maximum mana
   */
  loseMaxMana() {
    this.max -= 1;
    if (this.current > this.max) this.current = this.maxMana;
  }

  /**
   * Gain temporary mana that will be lost when the ManaSet is refilled
   * @param amount How much mana to gain
   */
  gainTemporaryMana(amount: number) {
    this.current += amount;
  }

  /**
   * Spend a specified amount of mana if there is enough available
   * @param amount The amount of mana to spend
   * @returns Whether or not the mana was spent
   */
  spendMana(amount: number): boolean {
    if (this.current < amount) {
      return false;
    }
    this.current -= amount;
    return true;
  }
}
