import {
  Attribute,
  BounceEffect,
  Card,
  CardColor,
  CardEffectContext,
  CardPermanentInstance,
  CardType,
  CreatureCard,
  Effect,
  LoseLifeEffect,
  MonumentCard,
  PlayPermanentAction,
  SpellCard,
} from '..';
import { PlayerModifierDatabase, PlayerModifierSet } from '../player/PlayerModifier';
import {
  CardModifier,
  CardModifierDatabase,
  CharacterToModifiersMap,
  StaticModifierUtil,
} from './CardModifier';

// TODO static map
export class CardDatabase {
  static getCardById(id: number): Card {
    switch (id) {
      case 0: // Test Bear
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          color: CardColor.GREEN,
          attack: 2000,
        } as CreatureCard;
      case 1: // Skeleton
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          color: CardColor.BLACK,
          attack: 1000,
          attributes: [Attribute.UNDEAD],
        } as CreatureCard;
      case 2: // Bat
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          attack: 2000,
          isRanged: true,
          attributes: [Attribute.BAT, Attribute.FLYING],
        } as CreatureCard;
      case 3: // Duchess Anastasia
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          attack: 5000,
          color: CardColor.BLACK,
          extraDamage: 1,
          attributes: [Attribute.VAMPIRE],

          onDeploy: (context: CardEffectContext) => {
            if (context.x > 0) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 2, // Lackey 1
                x: context.x - 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
            if (context.x < 2) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 2, // Lackey 2
                x: context.x + 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as CreatureCard;
      case 4: // Tabunara Sellsword
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          attack: 3000,
          color: CardColor.BLUE,
          attackDisabled: true,
          attributes: [Attribute.OCEAN, Attribute.BLADEMASTER],
        } as CreatureCard;
      case 5: // Blue Bird
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          attack: 2000,
          isRanged: true,

          color: CardColor.RED,
        } as CreatureCard;
      case 6: // Riptide Trident
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 3,
          color: CardColor.BLUE,
          maxEnergy: 4,
          attributes: [Attribute.EQUIPMENT],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            if (context.y == 0) {
              const card = player?.board.get(context.x, context.y + 1);
              if (
                card &&
                card.type == CardType.CREATURE &&
                card.attributes.includes(Attribute.OCEAN)
              ) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: context.x,
                    y: context.y + 1,
                    playerId: context.ownerId,
                  }),
                  [
                    CardModifierDatabase.getModifierById(3),
                    CardModifierDatabase.getModifierById(6),
                  ],
                );
              }
            }
            return ret;
          },
        } as MonumentCard;
      case 7: // Codex Witch Sarah
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          attack: 1500,
          color: CardColor.BLUE,
          attributes: [Attribute.WIZARD],
        } as CreatureCard;
      // case 7: // Shallow Grave
      //   return {
      //     type: CardType.MONUMENT,
      //     id: id,
      //     cost: 4,
      //     health: 5,
      //     effectTime: 2,
      //     onTimeEffectTriggered: (context: CardEffectContext) => {
      //       // TODO play event
      //       context.battlefield.play({
      //         costDiscount: -1,
      //         playerId: context.ownerId,
      //         cardId: 1, // Skeleton
      //         x: context.x,
      //         y: context.y + 1,
      //         // TODO handle undefined for these
      //         handIndex: -1,
      //       } as PlayPermanentRequest);
      //       const player = context.battlefield.getPlayerById(context.ownerId);
      //       player?.board.get(context.x, context.y)?.takeDamage(1);
      //     },
      //     onDeath: (context: CardEffectContext) => {
      //       context.battlefield.play({
      //         costDiscount: -1,
      //         playerId: context.ownerId,
      //         cardId: 1, // Skeleton
      //         x: context.x,
      //         y: context.y,
      //         handIndex: -1,
      //       } as PlayPermanentRequest);
      //     },
      //   } as MonumentCard;
      case 8: // Meran River Guard
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          attack: 2000,
          color: CardColor.BLUE,
          attackDisabled: true,
          attributes: [Attribute.OCEAN],
        } as CreatureCard;
      case 9: // Delve into Knowledge
        return {
          type: CardType.SPELL,
          color: CardColor.BLUE,
          id: id,
          cost: 2,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.draw();
            player?.draw();
          },
        } as SpellCard;
      case 10: // Rat Token
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.BLACK,
          attack: 2000,
          attributes: [Attribute.RAT],
        } as CreatureCard;
      case 11: // Nullmana Terradon
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 6,
          color: CardColor.GREEN,
          attack: 6500,
          attributes: [Attribute.BEAST],
          hasBreakthrough: true,

          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 12: // Nox Puddles
        return {
          type: CardType.SPELL,
          id: id,
          color: CardColor.BLACK,
          cost: 2,
          attributes: [Attribute.NOX],

          onPlay: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.board.onAll((instance) => {
              instance?.addModifier(CardModifierDatabase.getModifierById(1), context.battlefield);
            });
          },
        } as SpellCard;
      case 13: // Rat Barrel
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          maxEnergy: 3,

          onDeath: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            if (player) {
              player.draw();
              context.battlefield.floodRow(context.y, {
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 10, // Navy Recruit
                x: context.x,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as MonumentCard;
      case 14: // Midnight Thunder Toad
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          attack: 5000,
          color: CardColor.BLACK,
          attributes: [Attribute.FROG],
          onDeploy: (context: CardEffectContext) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.board.onAll((card) => {
              if (card && card.type == CardType.CREATURE && card.power && card.power <= 3000) {
                card.stun();
              }
            });
          },
        } as CreatureCard;
      case 15: // Sellwood Archer
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.GREEN,
          attack: 2000,
          isRanged: true,
          attributes: [Attribute.ELF, Attribute.ARCHER],
        } as CreatureCard;
      case 16: // Nox Jar
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.BLACK,
          maxEnergy: 3,
          attributes: [Attribute.NOX],

          onTimeEffectTriggered: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            opponent?.player.takeDirectDamage(0);
          },
        } as MonumentCard;
      case 17: // Swift Squirrel
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          color: CardColor.GREEN,
          attack: 1000,
          attributes: [Attribute.SQUIRREL, Attribute.BEAST],
        } as CreatureCard;
      case 18: // Pied Piper
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          attack: 4000,
          color: CardColor.BLACK,
          attributes: [Attribute.BARD],

          onDeploy: (context: CardEffectContext) => {
            context.battlefield.flood({
              costDiscount: -1,
              playerId: context.ownerId,
              cardId: 10, // Rat
              x: context.x,
              y: context.y,
              handIndex: -1,
            } as PlayPermanentAction);
          },
        } as CreatureCard;
      case 19: // Sellwood Hargoth
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.GREEN,
          extraDamage: 1,
          attack: 6000,
          hasBreakthrough: true,
          attributes: [Attribute.BEAST],
        } as CreatureCard;
      case 20: // Sergeant Zahn
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          attack: 4000,
          color: CardColor.GREEN,
          attributes: [Attribute.ELF, Attribute.WARRIOR],

          onDeploy: (context: CardEffectContext) => {
            if (context.x > 0) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 21, // Dee
                x: context.x - 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
            if (context.x < 2) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 22, // Dan
                x: context.x + 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as CreatureCard;
      case 21: // Dee
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          attack: 2000,
          attributes: [Attribute.ELF],

          color: CardColor.GREEN,
        } as CreatureCard;
      case 22: // Dan
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          attack: 2000,
          attributes: [Attribute.ELF],

          color: CardColor.GREEN,
        } as CreatureCard;
      case 23: // Doggo of the Black Blade
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          attack: 4000,
          attributes: [Attribute.DOG],

          color: CardColor.WHITE,
        } as CreatureCard;
      case 24: // Mana Surge
        return {
          type: CardType.SPELL,
          id: id,
          color: CardColor.GREEN,
          cost: 1,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.gainTempMana(3);
          },
        } as SpellCard;
      case 25: // Soul Rend
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          color: CardColor.BLACK,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null) {
              creature?.destroy();
            }
          },
        } as SpellCard;
      case 26: // Attune Energy
        return {
          type: CardType.SPELL,
          color: CardColor.GREEN,
          id: id,
          cost: 2,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.gainMaxMana(CardColor.GREEN);
          },
        } as SpellCard;
      case 27: // Deadly Concoction
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.BLACK,

          attributes: [Attribute.NOX],
          onPlay: (context: CardEffectContext) => {
            if (context.instance && context.instance !== null) {
              context.instance.addModifier(
                CardModifierDatabase.getModifierById(10),
                context.battlefield,
              );
              context.instance.addModifier(
                CardModifierDatabase.getModifierById(0),
                context.battlefield,
              );
            }
          },
        } as SpellCard;
      case 28: // Studious Scholar
        return {
          type: CardType.CREATURE,
          color: CardColor.BLUE,
          id: id,
          cost: 3,
          attack: 3000,

          attributes: [Attribute.WIZARD],
          getUserStaticModifiers: (context) => {
            const ret = new PlayerModifierSet();
            ret.addStaticModifier(PlayerModifierDatabase.getModifierById(0), context.battlefield);
            return ret;
          },
        } as CreatureCard;
      case 29: // Little Jam
        return {
          type: CardType.CREATURE,
          color: CardColor.RED,
          id: id,
          cost: 4,
          attack: 3000,
          isRanged: true,
          isDeadly: true,
          attributes: [Attribute.ROGUE, Attribute.GUNSLINGER],
        } as CreatureCard;
      case 30: // Brandon
        return {
          type: CardType.CREATURE,
          color: CardColor.RED,
          id: id,
          cost: 5,
          attack: 6000,
          attributes: [Attribute.WARRIOR],

          hasBreakthrough: true,
          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            const e1 = opponent?.board.get(context.x, 0);
            if (e1 && e1.type == CardType.CREATURE && e1.power !== null && e1.power <= 3000) {
              e1.destroy();
            }
            const e2 = opponent?.board.get(context.x, 1);
            if (e2 && e2.type == CardType.CREATURE && e2.power !== null && e2.power <= 3000) {
              e2.destroy();
            }
          },
        } as CreatureCard;
      case 31: // Fire Sphere
        return {
          type: CardType.SPELL,
          id: id,
          cost: 1,
          color: CardColor.RED,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null && (creature.power ?? 0) <= 2000) {
              creature?.destroy();
            }
          },
        } as SpellCard;
      case 32: // Iceblade Leonin
        return {
          type: CardType.CREATURE,
          color: CardColor.BLUE,
          id: id,
          cost: 5,
          attack: 5000,
          attributes: [Attribute.LEONIN, Attribute.WARRIOR],
        } as CreatureCard;
      case 33: // Raging Goblin
        return {
          type: CardType.CREATURE,
          color: CardColor.RED,
          id: id,
          cost: 1,
          attack: 1000,
          attributes: [Attribute.GOBLIN, Attribute.ROGUE],
        } as CreatureCard;
      case 34: // Flame Blade Rogue
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          attack: 3500,
          color: CardColor.RED,
          attributes: [Attribute.ROGUE],

          onDeploy: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.loseMaxMana();
          },
        } as CreatureCard;
      case 35: // Phoenix
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.RED,
          attack: 6000,
          attributes: [Attribute.PHOENIX, Attribute.FLYING],

          onDeath: (context: CardEffectContext) => {
            context.battlefield.play({
              costDiscount: -1,
              playerId: context.ownerId,
              cardId: 36, // Phoenix egg
              x: context.x,
              y: context.y,
              handIndex: -1,
            } as PlayPermanentAction);
          },
        } as CreatureCard;
      case 36: // Phoenix Egg
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 3,
          color: CardColor.RED,
          maxEnergy: 3,
          attributes: [Attribute.EGG, Attribute.PHOENIX],

          onDeath: (context: CardEffectContext) => {
            if (context.instance && context.instance.energyRemaining == 0) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 35,
                x: context.x,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as MonumentCard;
      case 37: // Chipped Battleaxe
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.RED,
          maxEnergy: 3,
          attributes: [Attribute.EQUIPMENT],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            if (context.y == 0) {
              const card = player?.board.get(context.x, context.y + 1);
              if (card && card.type == CardType.CREATURE) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: context.x,
                    y: context.y + 1,
                    playerId: context.ownerId,
                  }),
                  [CardModifierDatabase.getModifierById(2)],
                );
              }
            }
            return ret;
          },
        } as MonumentCard;
      case 38: // Nox Toad
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          attack: 2000,
          isDeadly: true,
          attributes: [Attribute.FROG, Attribute.NOX],
        } as CreatureCard;
      case 39: // Wild Growth
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.GREEN,

          onPlay: (context: CardEffectContext) => {
            if (context.instance && context.instance !== null) {
              context.instance.addModifier(
                CardModifierDatabase.getModifierById(3),
                context.battlefield,
              );
            }
          },
        } as SpellCard;
      case 40: // Blood Moon
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.BLACK,
          maxEnergy: 4,
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (card !== null && card.color == CardColor.BLACK) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: context.ownerId,
                  }),
                  [CardModifierDatabase.getModifierById(0)],
                );
              }
            });
            return ret;
          },
        } as MonumentCard;
      case 41: // Apollo
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 9,
          color: CardColor.WHITE,
          attack: 12000,
          attributes: [Attribute.MECHA, Attribute.CAT],

          extraDamage: 1,
          onDeploy: (context) => {
            console.log(context.instance);
            context.battlefield.player1.board.onAll((instance) => {
              if (instance?.type == CardType.CREATURE && instance != context.instance) {
                instance?.destroy();
              }
            });
            context.battlefield.player2.board.onAll((instance) => {
              if (instance?.type == CardType.CREATURE && instance != context.instance) {
                instance?.destroy();
              }
            });
          },
          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 42: // Swiffer
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          color: CardColor.BLACK,
          extraDamage: 1,
          attack: 7500,
          attributes: [Attribute.MECHA, Attribute.CAT],

          onDeploy: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            if (player !== null) {
              if (context.y != 0) player.board.get(context.x, 0)?.destroy();
              if (context.y != 1) player.board.get(context.x, 1)?.destroy();
            }
            if (opponent !== null) {
              opponent.board.get(context.x, 0)?.destroy();
              opponent.board.get(context.x, 1)?.destroy();
            }
          },
          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 43: // Red Devil Rogue
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.RED,
          attack: 4500,
          attributes: [Attribute.DEVIL, Attribute.ROGUE],

          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            if (opponent) {
              const e = opponent.board.closestCreature(context.x);
              e?.addModifier(CardModifierDatabase.getModifierById(4), context.battlefield);
            }
          },
        } as CreatureCard;
      case 44: // Starstorm
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          color: CardColor.RED,
          onPlay: (context) => {
            context.battlefield.player1.board.onAll((instance) => {
              if (instance !== null && (instance.power ?? 0) <= 3000) {
                instance?.destroy();
              }
            });
            context.battlefield.player2.board.onAll((instance) => {
              if (instance !== null && (instance.power ?? 0) <= 3000) {
                instance?.destroy();
              }
            });
          },
        } as SpellCard;
      case 45: // Verdant Golem
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.GREEN,
          attack: 5000,
          attributes: [Attribute.GOLEM, Attribute.PLANT],
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            let count = 0;
            player?.board.onAll((card, x, y) => {
              if (
                card !== null &&
                card.color == CardColor.GREEN &&
                !(x == context.x && y == context.y)
              ) {
                count += 1;
              }
              ret.set(
                StaticModifierUtil.generateBoardTargetKey({
                  x: context.x,
                  y: context.y,
                  playerId: context.ownerId,
                }),
                Array.from({ length: count }, () => CardModifierDatabase.getModifierById(0)),
              );
            });
            return ret;
          },
        } as CreatureCard;
      case 46: // Artemis
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          color: CardColor.GREEN,
          attack: 8000,
          extraDamage: 2,
          attributes: [Attribute.MECHA, Attribute.CAT],
          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 47: // Sellwood Spirit Caller
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.GREEN,
          attack: 3000,
          attributes: [Attribute.DRUID, Attribute.SPIRIT],
          onDeploy: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            if (context.x > 0) {
              player?.board
                ?.get(context.x - 1, context.y)
                ?.addModifier(CardModifierDatabase.getModifierById(8), context.battlefield);
            }
            if (context.x < 2) {
              player?.board
                ?.get(context.x + 1, context.y)
                ?.addModifier(CardModifierDatabase.getModifierById(8), context.battlefield);
            }
          },
        } as CreatureCard;
      case 48: // Mittens
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 6,
          color: CardColor.RED,
          attack: 7000,
          extraDamage: 1,
          attributes: [Attribute.MECHA, Attribute.CAT],
          hasBreakthrough: true,
          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 49: // Vanguard Pawn
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          color: CardColor.WHITE,
          attack: 1000,
          attackDisabled: true,
          attributes: [Attribute.KNIGHT, Attribute.CHESS],
          onDeath: (context) => {
            const source = context.source;
            const typedSource = source as CardPermanentInstance;
            if (source && typedSource.type && typedSource.type == CardType.CREATURE) {
              typedSource.addModifier(CardModifierDatabase.getModifierById(1), context.battlefield);
            }
          },
        } as CreatureCard;
      case 50: // Vanguard Bishop
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.WHITE,
          attack: 4000,
          attributes: [Attribute.KNIGHT, Attribute.CHESS],
          getStaticModifiers: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            if (context.x > 0) {
              if (context.y > 0) {
                const e1 = player?.board.get(context.x - 1, context.y - 1);
                if (e1 && e1.type == CardType.CREATURE) {
                  ret.set(
                    StaticModifierUtil.generateBoardTargetKey({
                      x: context.x - 1,
                      y: context.y - 1,
                      playerId: context.ownerId,
                    }),
                    [CardModifierDatabase.getModifierById(0)],
                  );
                }
              }
              if (context.y < 1) {
                const e2 = player?.board.get(context.x - 1, context.y + 1);
                if (e2 && e2.type == CardType.CREATURE) {
                  ret.set(
                    StaticModifierUtil.generateBoardTargetKey({
                      x: context.x - 1,
                      y: context.y + 1,
                      playerId: context.ownerId,
                    }),
                    [CardModifierDatabase.getModifierById(0)],
                  );
                }
              }
            }
            if (context.x < 2) {
              if (context.y > 0) {
                const e1 = player?.board.get(context.x + 1, context.y - 1);
                if (e1 && e1.type == CardType.CREATURE) {
                  ret.set(
                    StaticModifierUtil.generateBoardTargetKey({
                      x: context.x + 1,
                      y: context.y - 1,
                      playerId: context.ownerId,
                    }),
                    [CardModifierDatabase.getModifierById(0)],
                  );
                }
              }
              if (context.y < 1) {
                const e2 = player?.board.get(context.x + 1, context.y + 1);
                if (e2 && e2.type == CardType.CREATURE) {
                  ret.set(
                    StaticModifierUtil.generateBoardTargetKey({
                      x: context.x + 1,
                      y: context.y + 1,
                      playerId: context.ownerId,
                    }),
                    [CardModifierDatabase.getModifierById(0)],
                  );
                }
              }
            }
            return ret;
          },
          onDeploy: (context: CardEffectContext) => {
            if (context.y == 0) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 49, // Pawn
                x: context.x,
                y: context.y + 1,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as CreatureCard;
      case 51: // Vanguard Rook
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          attack: 5000,
          attributes: [Attribute.KNIGHT, Attribute.CHESS],
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (y == context.y && card !== null) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: context.ownerId,
                  }),
                  [CardModifierDatabase.getModifierById(0)],
                );
              }
            });
            const opponent = context.battlefield.getOpponentByPlayer(player);
            opponent?.board.onAll((card, x, y) => {
              if (x == context.x && card !== null) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: opponent.id,
                  }),
                  [CardModifierDatabase.getModifierById(1)],
                );
              }
            });
            return ret;
          },
        } as CreatureCard;
      case 52: // Light Swords
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 3,
          maxEnergy: 2,
          color: CardColor.WHITE,
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (card !== null && x == context.x) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: player.id,
                  }),
                  [CardModifierDatabase.getModifierById(5)],
                );
              }
            });
            opponent?.board.onAll((card, x, y) => {
              if (card !== null && x == context.x) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: opponent.id,
                  }),
                  [CardModifierDatabase.getModifierById(5)],
                );
              }
            });
            return ret;
          },
        } as MonumentCard;
      case 53: // Manaspawn
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          attack: 0,
          attributes: [Attribute.SPIRIT],
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            const count = player?.player?.mana?.maxMana;
            if (count) {
              ret.set(
                StaticModifierUtil.generateBoardTargetKey({
                  x: context.x,
                  y: context.y,
                  playerId: context.ownerId,
                }),
                Array.from({ length: count }, () => CardModifierDatabase.getModifierById(0)),
              );
            }
            return ret;
          },
        } as CreatureCard;
      case 54: // Sunbeam
        return {
          type: CardType.SPELL,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          onPlay: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            player?.board.get(context.x, 0)?.destroy();
            player?.board.get(context.x, 1)?.destroy();
            opponent?.board.get(context.x, 0)?.destroy();
            opponent?.board.get(context.x, 1)?.destroy();
          },
        } as SpellCard;
      case 55: // Cleanse
        return {
          type: CardType.SPELL,
          id: id,
          cost: 1,
          color: CardColor.WHITE,
          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null) {
              creature?.cleanse();
            }
          },
        } as SpellCard;
      case 56: // To Dust
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.WHITE,
          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null && creature.isExhausted) {
              creature?.destroy();
            }
          },
        } as SpellCard;
      case 57: // Vanguard Knight
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.WHITE,
          attack: 4500,
          attributes: [Attribute.ARCHER, Attribute.CHESS],
          isRanged: true,
          isShielded: true,
        } as CreatureCard;
      case 58: // Spring Totem
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.GREEN,
          isRanged: true,
          maxEnergy: 3,
          attributes: [Attribute.TOTEM],
          onAddMana: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.board.onAll(
              (card) =>
                card?.addEotModifier(CardModifierDatabase.getModifierById(0), context.battlefield),
            );
          },
        } as MonumentCard;
      case 59: // Megawhale Vanada
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 6,
          color: CardColor.BLUE,
          attack: 7000,
          attributes: [Attribute.OCEAN, Attribute.BEAST],
          hasBreakthrough: true,
          onDeploy: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            if (opponent) {
              opponent.board.frontRow.forEach((card) => {
                if (card) {
                  card.stun();
                }
              });
              opponent.board.pushCard(0);
              opponent.board.pushCard(1);
              opponent.board.pushCard(2);
            }
          },
        } as CreatureCard;
      case 60: // Thundercat luffy
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          color: CardColor.BLUE,
          attack: 7000,
          extraDamage: 1,
          attributes: [Attribute.MECHA, Attribute.CAT],

          onDeploy: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.board.onAll((card) => {
              if (card && card.type == CardType.CREATURE) card.stun();
            });
          },
          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(9)],
            );
            return ret;
          },
        } as CreatureCard;
      case 61: // Doggo
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.NEUTRAL,
          attack: 3000,
          attributes: [Attribute.DOG],
        } as CreatureCard;
      case 62: // Dogify
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.BLUE,
          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null) {
              targetPlayer?.transform(
                context.x,
                context.y,
                CardDatabase.getCardById(61) as CreatureCard,
              );
            }
          },
        } as SpellCard;
      case 63: // Warp Gate
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.BLUE,
          onPlay: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            if (targetPlayer) {
              const card = targetPlayer.board.get(context.x, context.y);
              if (card) {
                targetPlayer?.returnToHand(context.x, context.y);
              }
            }
          },
        } as SpellCard;
      case 64: // Arcana Fool Lily
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLUE,
          attack: 4500,
          attributes: [Attribute.ARCANA],
          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            opponent?.board.swapColumn(context.x);
          },
        } as CreatureCard;
      case 65: // Disrupting Tide
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.BLUE,
          maxEnergy: 3,
          attributes: [Attribute.OCEAN],
          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (y == 0 && card !== null && !card.attributes.includes(Attribute.OCEAN)) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: player.id,
                  }),
                  [CardModifierDatabase.getModifierById(5)],
                );
              }
            });
            opponent?.board.onAll((card, x, y) => {
              if (y == 0 && card !== null && !card.attributes.includes(Attribute.OCEAN)) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: opponent.id,
                  }),
                  [CardModifierDatabase.getModifierById(5)],
                );
              }
            });
            return ret;
          },
        } as MonumentCard;
      case 66: // Commander Hazen
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 6,
          color: CardColor.BLUE,
          attack: 5000,
          attributes: [Attribute.WARRIOR],
          extraDamage: 1,
          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            if (player) {
              context.battlefield.floodBackRow({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 67, // Navy Recruit
                x: context.x,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as CreatureCard;
      case 67: // Navy Recruit
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.BLUE,
          attack: 1000,
          isRanged: true,
        } as CreatureCard;
      case 68: // Arcana Fortune Teller
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLUE,
          attack: 5000,
          attributes: [Attribute.ARCANA],
          onDeploy: (context): Effect[] => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            if (opponent) {
              const cardY = opponent.board.closestCreatureY(context.x);
              if (cardY !== null) {
                return [new BounceEffect(opponent.id, context.x, cardY)];
              }
            }
            return [];
          },
        } as CreatureCard;
      case 69: // Noelle
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLUE,
          attack: 5500,
          attributes: [Attribute.WARRIOR, Attribute.VALKYRIE],
          onDeploy: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            if (opponent) {
              opponent.board.pushCard(0);
              opponent.board.pushCard(1);
              opponent.board.pushCard(2);
            }
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.board.onAll((card, x, y) => {
              if (card && !(x == context.x && y == context.y)) {
                card.addModifier(CardModifierDatabase.getModifierById(0), context.battlefield);
              }
            });
          },
        } as CreatureCard;
      case 70: // Clyde
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.RED,
          attack: 4500,
          attributes: [Attribute.ROGUE],
        } as CreatureCard;
      case 71: // Doctor Lee
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLACK,
          attack: 4000,
          attributes: [Attribute.HEALER],
        } as CreatureCard;
      case 72: // Mtear
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          attack: 9000,
          attributes: [Attribute.HEALER],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            let count = 0;
            player?.board.onAll((card, x, y) => {
              if (
                card !== null &&
                !(x == context.x && y == context.y) &&
                card.type == CardType.CREATURE
              ) {
                count += 1;
              }
              ret.set(
                StaticModifierUtil.generateBoardTargetKey({
                  x: context.x,
                  y: context.y,
                  playerId: context.ownerId,
                }),
                Array.from({ length: count }, () => CardModifierDatabase.getModifierById(4)),
              );
            });
            return ret;
          },
        } as CreatureCard;
      case 73: // Reptara Scout
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 1,
          color: CardColor.RED,
          attack: 1000,
          attributes: [Attribute.REPTILE, Attribute.WARRIOR],
        } as CreatureCard;
      case 74: // Adam
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLACK,
          attack: 3500,
          isDeadly: true,
          attributes: [Attribute.ROGUE, Attribute.NOX],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentByPlayer(player);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (
                card !== null &&
                card.type == CardType.CREATURE &&
                !(context.x == x && context.y == y)
              ) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: player.id,
                  }),
                  [CardModifierDatabase.getModifierById(1)],
                );
              }
            });
            opponent?.board.onAll((card, x, y) => {
              if (card !== null && card.type == CardType.CREATURE) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: opponent.id,
                  }),
                  [CardModifierDatabase.getModifierById(1)],
                );
              }
            });
            return ret;
          },
        } as CreatureCard;
      case 75: // Thunder Fists
        return {
          type: CardType.SPELL,
          id: id,
          cost: 1,
          color: CardColor.RED,
          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const targetPlayer = context.localTarget
              ? player
              : context.battlefield.getOpponentByPlayer(player);
            const creature = targetPlayer?.board.get(context.x, context.y);
            if (creature && creature !== null) {
              creature.addEotModifier(CardModifierDatabase.getModifierById(3), context.battlefield);
            }
          },
        } as SpellCard;
      case 76: // Zap
        return {
          type: CardType.SPELL,
          id: id,
          cost: 1,
          color: CardColor.BLUE,

          onPlay: (context: CardEffectContext) => {
            if (
              context.instance &&
              context.instance !== null &&
              context.instance.type == CardType.CREATURE
            ) {
              context.instance.stun();
            }
          },
        } as SpellCard;
      case 77: // Grizzly Bear
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.GREEN,
          attack: 2000,
          attributes: [Attribute.BEAST],
        } as CreatureCard;
      case 78: // Meran Skirmisher
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLUE,
          attack: 4000,
          attributes: [Attribute.OCEAN, Attribute.MERAN],
        } as CreatureCard;
      case 79: // Meran Harpooner
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLUE,
          attack: 4500,
          attributes: [Attribute.OCEAN, Attribute.MERAN],
          onDeploy: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            if (opponent) {
              opponent.board.pushCard(context.x);
            }
          },
          isRanged: true,
        } as CreatureCard;
      case 80: // Nox Eating Rat
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLACK,
          attack: 4000,
          attributes: [Attribute.RAT, Attribute.NOX],
          onDeath: (context) => {
            const source = context.source;
            const typedSource = source as CardPermanentInstance;
            if (source && typedSource.type && typedSource.type == CardType.CREATURE) {
              const player = context.battlefield.getPlayerById(context.ownerId);
              const opponent = context.battlefield.getOpponentByPlayer(player);
              opponent?.player.takeDirectDamage(0);
            }
          },
        } as CreatureCard;
      case 81: // Starlight Heal
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          color: CardColor.WHITE,

          onPlay: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.player?.heal(1);
          },
        } as SpellCard;
      case 82: // Determined Engineer
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.NEUTRAL,
          attack: 1000,
          attributes: [Attribute.ENGINEER],
          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.draw();
          },
        } as CreatureCard;
      case 83: // Death Reaper Mazin
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 8,
          color: CardColor.BLACK,
          attack: 8000,
          attributes: [Attribute.REAPER, Attribute.ROGUE],
          onCombat: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            if (opponent) {
              opponent.board.frontRow.forEach((card) => card?.destroy());
            }
          },
        } as CreatureCard;
      case 84: // Lightning boots
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 2,
          color: CardColor.RED,
          maxEnergy: 3,
          attributes: [Attribute.EQUIPMENT],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            if (context.y == 0) {
              const card = player?.board.get(context.x, context.y + 1);
              if (card && card.type == CardType.CREATURE) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: context.x,
                    y: context.y + 1,
                    playerId: context.ownerId,
                  }),
                  [CardModifierDatabase.getModifierById(9)],
                );
              }
            }
            return ret;
          },
        } as MonumentCard;
      case 85: // Angelic Guard
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.WHITE,
          attack: 4000,
          isShielded: true,
          attributes: [Attribute.ANGEL, Attribute.FLYING],
        } as CreatureCard;
      case 86: // Divine General Moriel
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          color: CardColor.WHITE,
          attack: 7500,
          attributes: [Attribute.DIVINE, Attribute.ANGEL, Attribute.FLYING],

          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.board.onAll((card) => card?.shield());
          },
        } as CreatureCard;
      case 87: // Breath of Renewal
        return {
          type: CardType.SPELL,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
        } as SpellCard;
      case 88: // Lost in Mist
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.GREEN,

          onPlay: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.board.onAll((card) => {
              if (card && card.type == CardType.CREATURE) {
                card.exhaust();
              }
            });
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.board.onAll((card) => {
              if (card && card.type == CardType.CREATURE) {
                card.exhaust();
              }
            });
          },
        } as SpellCard;
      case 89: // Goth Girl Susan
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLACK,
          attack: 4000,
          attributes: [Attribute.WARLOCK, Attribute.GOTH],

          getStaticModifiers: (context: CardEffectContext) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (card !== null && x != context.x && y == context.y) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: player.id,
                  }),
                  [CardModifierDatabase.getModifierById(4)],
                );
              }
            });
            opponent?.board.onAll((card, x, y) => {
              if (card !== null && y == context.y) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: opponent.id,
                  }),
                  [CardModifierDatabase.getModifierById(4)],
                );
              }
            });
            return ret;
          },
        } as CreatureCard;
      case 90: // Flame Wreath Toad
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.RED,
          attack: 4000,
          attributes: [Attribute.FROG],

          showPlusPower: false,
          // onCombat: (context) => {
          //   const card = context.instance;
          //   if (card && card.isReadyForAttack) {
          //     context.instance?.addEotModifier(
          //       CardModifierDatabase.getModifierById(8),
          //       context.battlefield,
          //     );
          //   }
          // },
        } as CreatureCard;
      case 91: // Daring Adventurer Gerty
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.NEUTRAL,
          attack: 3000,
          attributes: [Attribute.ADVENTURER],
        } as CreatureCard;
      case 92: // Natural Order
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          color: CardColor.GREEN,

          onPlay: (context) => {
            if (context.instance && context.instance.type == CardType.CREATURE) {
              context.instance.destroy();
              const opponent = context.battlefield.getOpponentById(context.ownerId);
              opponent?.gainMaxMana(context.instance.color);
            }
          },
        } as SpellCard;
      case 93: // Mana Tree Yustafar
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          color: CardColor.GREEN,
          attack: 6000,
          attributes: [Attribute.ELEMENTAL, Attribute.PLANT],
        } as CreatureCard;
      case 94: // Duke Dairy
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          attack: 4000,
          attributes: [Attribute.LACTOMANCER],

          onDeploy: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            player?.drawCard(95);
            player?.drawCard(95);
            player?.drawCard(95);
          },
        } as CreatureCard;
      case 95: // Milk
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.NEUTRAL,

          onPlay: (context) => {
            const card = context.instance;
            if (card) {
              if (card.attributes.includes(Attribute.LACTOMANCER)) {
                card.addModifier(CardModifierDatabase.getModifierById(0), context.battlefield);
                card.shield();
              } else {
                card.stun();
              }
            }
          },
        } as SpellCard;
      case 96: // Thundersword Leonin
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.WHITE,
          attack: 4500,
          attributes: [Attribute.LEONIN, Attribute.WARRIOR],
        } as CreatureCard;
      case 97: // Viper Spear Alaran
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 7,
          attack: 7000,
          color: CardColor.RED,
          hasBreakthrough: true,
          attributes: [Attribute.WARRIOR, Attribute.ROGUE],

          onDeploy: (context: CardEffectContext) => {
            if (context.x > 0) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 98,
                x: context.x - 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
            if (context.x < 2) {
              context.battlefield.play({
                costDiscount: -1,
                playerId: context.ownerId,
                cardId: 98,
                x: context.x + 1,
                y: context.y,
                handIndex: -1,
              } as PlayPermanentAction);
            }
          },
        } as CreatureCard;
      case 98: // Giant Viper
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.RED,
          attack: 1000,
          isDeadly: true,
          attributes: [Attribute.REPTILE],
        } as CreatureCard;
      case 99: // Order Initiate
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.WHITE,
          attack: 1500,
          isShielded: true,
          attributes: [Attribute.CLERIC],
        } as CreatureCard;
      case 100: // Manne
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.GREEN,
          attack: 4500,
          attributes: [Attribute.BLADEMASTER],

          getStaticModifiers: (context: CardEffectContext) => {
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            ret.set(
              StaticModifierUtil.generateBoardTargetKey({
                x: context.x,
                y: context.y,
                playerId: context.ownerId,
              }),
              [CardModifierDatabase.getModifierById(6)],
            );
            return ret;
          },
        } as CreatureCard;
      case 101: // Shield Blessing
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.WHITE,

          onPlay: (context) => {
            const card = context.instance;
            if (card) {
              card.addModifier(CardModifierDatabase.getModifierById(0), context.battlefield);
              card.shield();
            }
          },
        } as SpellCard;
      case 102: // Infernal Nail
        return {
          type: CardType.SPELL,
          id: id,
          cost: 5,
          color: CardColor.RED,

          onPlay: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.player.takeDirectDamage();
          },
        } as SpellCard;
      case 103: // Syphon Mana
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.GREEN,

          onPlay: (context) => {
            const opponent = context.battlefield.getOpponentById(context.ownerId);
            opponent?.loseMaxMana();
          },
        } as SpellCard;
      case 104: // Cemetery Keeper
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          attack: 1500,
          attributes: [Attribute.UNDEAD],
        } as CreatureCard;
      case 105: // Thunder Mage Asta
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.RED,
          attack: 2500,
          attributes: [Attribute.ROGUE, Attribute.WIZARD],
        } as CreatureCard;
      case 106: // Old Man with Stick
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.NEUTRAL,
          attack: 4000,
          attributes: [Attribute.FIGHTER],
        } as CreatureCard;
      case 107: // Graveyard Girl Elara
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLACK,
          attack: 5500,
          attributes: [Attribute.NECROMANCER],
          getStaticModifiers: (context) => {
            const player = context.battlefield.getPlayerById(context.ownerId);
            const ret: CharacterToModifiersMap = new Map<string, CardModifier[]>();
            player?.board.onAll((card, x, y) => {
              if (card !== null && card.attributes.includes(Attribute.UNDEAD)) {
                ret.set(
                  StaticModifierUtil.generateBoardTargetKey({
                    x: x,
                    y: y,
                    playerId: context.ownerId,
                  }),
                  [CardModifierDatabase.getModifierById(2)],
                );
              }
            });
            return ret;
          },
          onDeath: (context): Effect[] => {
            return [new LoseLifeEffect(context.ownerId, 1, context.source)];
          },
        } as CreatureCard;
      case 108: // Spirit Wolf
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.NEUTRAL,
          attack: 2000,
          attributes: [Attribute.SPIRIT, Attribute.BEAST],
        } as CreatureCard;
      case 109: // Spectral Hunters
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.GREEN,
          attack: 3000,
          attributes: [Attribute.HUNTER, Attribute.SHAMAN],
        } as CreatureCard;
      case 110: // Vanishing Shade
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.BLUE,
          attack: 1000,
          attributes: [Attribute.SPIRIT],
        } as CreatureCard;
      case 111: // Jungle Ape
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.RED,
          attack: 2000,
          attributes: [Attribute.APE],
        } as CreatureCard;
      case 112: // Castle Checkpoint
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 0,
          maxEnergy: 3,
          color: CardColor.WHITE,
        } as MonumentCard;
      case 113: // Spellsap Mage
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.GREEN,
          attack: 3000,
          attributes: [Attribute.ELF, Attribute.WIZARD],
        } as CreatureCard;
      case 114: // Golem Guardian
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.WHITE,
          attack: 6000,
          attackDisabled: true,
          attributes: [Attribute.GOLEM],
        } as CreatureCard;
      case 115: // Princess Aurina
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.WHITE,
          attack: 3000,
          hasBreakthrough: true,
          attributes: [Attribute.ROYALTY],
        } as CreatureCard;
      case 116: // Feline Familiar
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          attack: 3000,
          attributes: [Attribute.CAT, Attribute.WITCH],
        } as CreatureCard;
      case 117: // Meran Lord Azacenadar
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 6,
          color: CardColor.BLUE,
          attack: 8000,
          attributes: [Attribute.DIVINE, Attribute.MERAN, Attribute.ROYALTY],
        } as CreatureCard;
      case 118: // Hell Commander Zandorus
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.RED,
          attack: 7000,
          attributes: [Attribute.DEVIL, Attribute.LORD, Attribute.FLYING],
        } as CreatureCard;
      case 119: // Lady Bellamina
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.BLACK,
          attack: 6000,
          attributes: [Attribute.DEMON, Attribute.FLYING],
        } as CreatureCard;
      case 120: // Apex Chimera Gorasax
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.GREEN,
          attack: 7000,
          attributes: [Attribute.BEAST],
        } as CreatureCard;
      case 121: // Vanguard Queen
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 5,
          color: CardColor.WHITE,
          attack: 6500,
          attributes: [Attribute.CHESS, Attribute.ROYALTY],
        } as CreatureCard;
      case 122: // Force Spiral
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.BLUE,
          // onPlay: (context): Effect[] => {
          //   // Relocation effect
          // }
        } as SpellCard;
      case 123: // Wall of Ice
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 0,
          maxEnergy: 3,
          color: CardColor.BLUE,
        } as MonumentCard;
      case 124: // Ice Wall
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          color: CardColor.BLUE,
        } as SpellCard;
      case 125: // Midnight Assassin
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLACK,
          attack: 3500,
          attributes: [Attribute.CAT, Attribute.ROGUE],
        } as CreatureCard;
      case 126: // Commune Spirit Wolves
        return {
          type: CardType.SPELL,
          id: id,
          cost: 3,
          attributes: [Attribute.COMMUNE],
          color: CardColor.GREEN,
        } as SpellCard;
      case 127: // Raging Skeleton
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 3,
          color: CardColor.BLACK,
          attack: 3000,
          hasBreakthrough: true,
          attributes: [Attribute.UNDEAD],
        } as CreatureCard;
      case 128: // Red Light Infusion
        return {
          type: CardType.SPELL,
          id: id,
          cost: 2,
          color: CardColor.BLACK,
        } as SpellCard;
      case 129: // Raise Skeletons
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          color: CardColor.BLACK,
        } as SpellCard;
      case 130: // Glade Spirit Deer
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.GREEN,
          attack: 500,
          attributes: [Attribute.SPIRIT],
        } as CreatureCard;
      case 131: // Summoning Bell
        return {
          type: CardType.MONUMENT,
          id: id,
          cost: 3,
          maxEnergy: 3,
          color: CardColor.GREEN,
        } as MonumentCard;
      case 132: // Commune Shore Wraiths
        return {
          type: CardType.SPELL,
          id: id,
          cost: 4,
          attributes: [Attribute.COMMUNE],
          color: CardColor.BLUE,
        } as SpellCard;
      case 133: // Shore Wraith
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 2,
          color: CardColor.BLUE,
          attack: 2000,
          attributes: [Attribute.SPIRIT],
        } as CreatureCard;
      case 134: // Kit the Shifter
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.GREEN,
          attack: 3500,
          attributes: [Attribute.ELF, Attribute.DRUID],
        } as CreatureCard;
      case 135: // Silver Reign
        return {
          type: CardType.CREATURE,
          id: id,
          cost: 4,
          color: CardColor.BLACK,
          attack: 4500,
          attributes: [Attribute.UNDEAD, Attribute.KNIGHT],
        } as CreatureCard;
    }
    throw `Invalid card ID! ${id}`;
  }
}
