import React from 'react';
import { DebugGameAreaContent } from './DebugGameAreaContent';
import { M } from '../../../uilib/Modifier';

export function DebugGameArea(): JSX.Element {
  return (
    <div style={M.flexColumn.borderBox.fillWidth.css()}>
      <DebugGameAreaContent />
    </div>
  );
}
