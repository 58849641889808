import { CardInstanceData, FullData, Target } from '../data';

export class FullDataUtil {
  static findTarget(playerId: string, data: FullData, target: Target): CardInstanceData | null {
    const playerTarget = target.isLocalTarget ? data.user : data.opponent;
    const board =
      target.y == 0 ? playerTarget.board.back : target.y == 1 ? playerTarget.board.front : null;
    if (board !== null) {
      return board[target.x] ?? null;
    }
    return null;
  }
}
