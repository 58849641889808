export enum Attribute {
  UNDEAD,
  BAT,
  VAMPIRE,
  OCEAN,
  RAT,
  ELF,
  ARCHER,
  NOX,
  BARD,
  BEAST,
  WARRIOR,
  WIZARD,
  GUNSLINGER,
  ROGUE,
  GOBLIN,
  PHOENIX,
  FLYING,
  EGG,
  EQUIPMENT,
  FROG,
  MECHA,
  CAT,
  DEVIL,
  DEMON,
  PLANT,
  GOLEM,
  DRUID,
  SPIRIT,
  CHESS,
  KNIGHT,
  TOTEM,
  BLADEMASTER,
  DOG,
  ARCANA,
  VALKYRIE,
  HEALER,
  REPTILE,
  MERAN,
  ENGINEER,
  REAPER,
  ANGEL,
  DIVINE,
  GOTH,
  WARLOCK,
  ADVENTURER,
  ELEMENTAL,
  LACTOMANCER,
  LEONIN,
  CLERIC,
  SQUIRREL,
  FIGHTER,
  NECROMANCER,
  HUNTER,
  SHAMAN,
  APE,
  ROYALTY,
  WITCH,
  LORD,
  COMMUNE,
}
