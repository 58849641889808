import {
  CardDatabase,
  CardEffectContext,
  CardPermanentInstance,
  CardSpellInstance,
  CardType,
  PermanentCard,
  SpellCard,
} from './card';
import { Combatant } from './Combatant';
import { Deck } from './stack/Deck';
import { Player } from './player';
import {
  MakeManaAction,
  PlayPermanentAction,
  PlayAction,
  PlaySpellAction,
  CardAction,
  CardActionType,
} from './Request';
import { TenantResponseCode } from './TenantResponseCodes';
import { FullData } from './data/FullData';
import { Effect } from './domain';
import { Target } from './data';
import { CharacterToModifiersMap, StaticModifierUtil } from './card/CardModifier';
import { PlayerModifierSet } from './player/PlayerModifier';

export class Game {
  turnCount = 0;
  // TODO rename
  player1: Combatant;
  player2: Combatant;
  private effectPipeline: Effect[] = [];

  constructor(player1Base: Player, player2Base: Player, deck1: number[], deck2: number[]) {
    this.player1 = Combatant.fromBasePlayer(player1Base, new Deck(deck1), this);
    this.player1.drawTillFull();
    this.player2 = Combatant.fromBasePlayer(player2Base, new Deck(deck2), this);
    this.player2.drawTillFull();
  }

  get player1Key(): string {
    return this.player1.player.base.id;
  }

  get player2Key(): string {
    return this.player2.player.base.id;
  }

  resetNewTurn() {
    this.removeEotEffects();
    this.removeExhaustion();
    this.refillMana();
    this.drawCards();
    this.player1.resetNewTurn();
    this.player2.resetNewTurn();
  }

  removeEotEffects() {
    this.onAllPermanents((card) => card?.clearEotModifiers(this));
  }

  removeExhaustion() {
    this.player1.removeExhaustion();
    this.player2.removeExhaustion();
  }

  getPlayerById(playerId: string): Combatant | null {
    if (this.player1.id == playerId) return this.player1;
    else if (this.player2.id == playerId) return this.player2;
    else return null;
  }

  getOpponentById(playerId: string): Combatant | null {
    if (this.player1.id == playerId) return this.player2;
    else if (this.player2.id == playerId) return this.player1;
    else return null;
  }

  getOpponentByPlayer(player: Combatant | null): Combatant | null {
    if (player === this.player1) return this.player2;
    else if (player === this.player2) return this.player1;
    else return null;
  }

  enqueueMakeMana(playerId: string, handIndex: number): number {
    const player = this.getPlayerById(playerId);
    if (!player) return TenantResponseCode.PLAYER_MISSING;
    if (player.hasManuallySkipped || player.queuedAction != null) {
      return TenantResponseCode.ALREADY_QUEUED;
    }

    return player.enqueueMakeMana({
      playerId: playerId,
      handIndex: handIndex,
      type: CardActionType.MAKE_MANA,
    } as MakeManaAction);
  }

  skip(playerId: string): number {
    const player = this.getPlayerById(playerId);
    if (!player) return TenantResponseCode.PLAYER_MISSING;
    if (player.hasManuallySkipped || player.queuedAction != null) {
      return TenantResponseCode.ALREADY_QUEUED;
    }
    player.setHasManuallySkipped();
    return TenantResponseCode.OK;
  }

  enqueuePlay(
    playerId: string,
    handIndex: number,
    x: number,
    y: number,
    localTarget: boolean,
  ): number {
    const player = this.getPlayerById(playerId);
    if (!player) return TenantResponseCode.PLAYER_MISSING;

    const cardId = player.hand.get(handIndex);
    if (cardId !== null) {
      const card = CardDatabase.getCardById(cardId);
      // TODO why are we checking cost here???
      const debugCost =
        card.type == CardType.SPELL
          ? card.cost - (player.player.modifier.allSpellCostReduction ?? 0)
          : card.cost;
      if (player.player.mana.currentMana < debugCost) return TenantResponseCode.MANA_TOO_LOW;
      if (!player.player.mana.manaPool[card.color]) return TenantResponseCode.MANA_NO_COLOR;
      if (card.type != CardType.SPELL) {
        // Permanents
        if (player.board.isEmpty(x, y)) {
          return player.enqueuePermanentRequest({
            costDiscount: 0,
            playerId: playerId,
            cardId: cardId,
            x: x,
            y: y,
            handIndex: handIndex,
            type: CardActionType.PLAY_PERMANENT,
          } as PlayPermanentAction);
        } else {
          return TenantResponseCode.TILE_FULL;
        }
      } else if (card.type == CardType.SPELL) {
        // Spells
        return player.enqueueSpellRequest({
          costDiscount: 0,
          playerId: playerId,
          cardId: cardId,
          x: x,
          y: y,
          localTarget: localTarget,
          handIndex: handIndex,
          type: CardActionType.PLAY_SPELL,
        } as PlaySpellAction);
      }
      return TenantResponseCode.OK;
    } else {
      return TenantResponseCode.HAND_ACCESS;
    }
  }

  resetPassingActions() {
    this.player1.resetPassingAction();
    this.player2.resetPassingAction();
  }

  haveAllPlayersPassed(): boolean {
    return this.player1.hasPassedAction && this.player2.hasPassedAction;
  }

  haveAllPlayersReadiedAnAction(): boolean {
    return (
      (this.player1.queuedAction != null || this.player1.hasManuallySkipped) &&
      (this.player2.queuedAction != null || this.player2.hasManuallySkipped)
    );
  }

  areAnyPlayersWaitingToReadyAnAction(): boolean {
    return this.player1.queuedAction == null || this.player2.queuedAction == null;
  }

  canAnythingAttack(): boolean {
    return this.player1.canAnythingAttack() || this.player2.canAnythingAttack();
  }

  canAnyActionBeTaken(): boolean {
    return this.player1.canActionBeTaken() || this.player2.canActionBeTaken();
  }

  isEitherPlayerDead(): boolean {
    return this.player1.isDead || this.player2.isDead;
  }

  popQueuedActions(): (CardAction | null)[] {
    const ret: (CardAction | null)[] = [];
    ret.push(this.player1.queuedAction);
    ret.push(this.player2.queuedAction);
    this.player1.clearQueuedAction();
    this.player2.clearQueuedAction();
    return ret;
  }

  refillMana() {
    this.player1.refillMana();
    this.player2.refillMana();
  }

  drawCards() {
    if (this.turnCount != 0) {
      // this.player1.drawTillFull();
      // this.player2.drawTillFull();
      if (this.player1.deck.length == 0) {
        this.player1.player.takeDirectDamage();
      }
      if (this.player2.deck.length == 0) {
        this.player2.player.takeDirectDamage();
      }
      this.player1.draw();
      this.player1.draw();
      this.player2.draw();
      this.player2.draw();
    } else {
      this.turnCount += 1;
    }
  }

  IS_DONE() {
    return this.player1.player.life <= 0 || this.player2.player.life <= 0;
  }

  // TODO split token summoning?
  // TODO this may need to be private to enforce queueing
  play(request: PlayAction): number {
    const player = this.getPlayerById(request.playerId);
    // TODO restore
    if (!player) return TenantResponseCode.PLAYER_MISSING;

    const cardBase = CardDatabase.getCardById(request.cardId);
    if (cardBase.type != CardType.SPELL) {
      // Creature or Monument
      const typedRequest = request as PlayPermanentAction;
      const card = new CardPermanentInstance(cardBase as PermanentCard);
      // TODO "spendIfAble"
      if (player.canPlay(card, typedRequest.x, typedRequest.y, request.costDiscount)) {
        // TODO spawn without cost
        player
          .play(card, typedRequest.x, typedRequest.y, request.costDiscount)
          .forEach((effect: Effect) => {
            this.effectPipeline.push(effect);
          });
        if (request.handIndex >= 0) {
          player.hand.removeAt(request.handIndex);
        }
        return TenantResponseCode.OK;
      } else {
        // TODO add other errors
        return 502;
      }
    } else {
      //Spell
      const typedRequest = request as PlaySpellAction;
      const card = new CardSpellInstance(cardBase as SpellCard);
      const target = this.getPermanent(player.id, {
        x: typedRequest.x,
        y: typedRequest.y,
        isLocalTarget: typedRequest.localTarget,
      } as Target);
      console.log('MEOW 1');
      if (player.canPlaySpell(card, typedRequest.x, typedRequest.y, request.costDiscount)) {
        console.log('MEOW 2');
        player
          .playSpell(
            card,
            typedRequest.x,
            typedRequest.y,
            typedRequest.localTarget,
            target,
            request.costDiscount,
          )
          .forEach((effect: Effect) => {
            this.effectPipeline.push(effect);
          });
        if (request.handIndex >= 0) {
          player.hand.removeAt(request.handIndex);
        }
        return TenantResponseCode.OK;
      }
    }
    return 503;
  }

  makeMana(request: MakeManaAction): number {
    const player = this.getPlayerById(request.playerId);
    if (!player) return 501;

    if (request.handIndex >= 0) {
      const card = player.hand.get(request.handIndex);
      if (card !== null) {
        const base = CardDatabase.getCardById(card);
        player.hand.removeAt(request.handIndex);
        player.gainMaxMana(base.color);

        // Add mana effects
        player.board.onAll((card, x, y) => {
          if (card && card.onAddMana) {
            // TODO fix pipeline
            card.onAddMana({
              battlefield: this,
              ownerId: player.id,
              instance: card,
              x: x,
              y: y,
              localTarget: true,
            } as CardEffectContext);
            // this.effectPipeline.push(
            //   new AddManaEffect(player.id, card, undefined, x, y, card.onAddMana),
            // );
          }
        });
        return TenantResponseCode.OK;
      }
    }
    return TenantResponseCode.HAND_ACCESS;
  }

  // Flood
  flood(request: PlayPermanentAction): number {
    const player = this.getPlayerById(request.playerId);
    // TODO restore
    if (!player) return 501;

    const cardBase = CardDatabase.getCardById(request.cardId);
    if (cardBase.type != CardType.SPELL) {
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 2; j++) {
          const card = new CardPermanentInstance(cardBase as PermanentCard);
          if (player.canPlay(card, i, j, request.costDiscount)) {
            player.play(card, i, j, request.costDiscount).forEach((effect: Effect) => {
              this.effectPipeline.push(effect);
            });
          } else {
            // TODO
            // return 502;
          }
        }
      }
    }
    return TenantResponseCode.OK;
  }

  floodRow(y: number, request: PlayPermanentAction): number {
    return y == 0 ? this.floodBackRow(request) : this.floodFrontRow(request);
  }

  floodBackRow(request: PlayPermanentAction): number {
    const player = this.getPlayerById(request.playerId);
    // TODO restore
    if (!player) return 501;

    const cardBase = CardDatabase.getCardById(request.cardId);
    if (cardBase.type != CardType.SPELL) {
      for (let i = 0; i < 3; i++) {
        const card = new CardPermanentInstance(cardBase as PermanentCard);
        if (player.canPlay(card, i, 0, request.costDiscount)) {
          player.play(card, i, 0, request.costDiscount).forEach((effect: Effect) => {
            this.effectPipeline.push(effect);
          });
        } else {
          // TODO
          // return 502;
        }
      }
    }
    return TenantResponseCode.OK;
  }

  floodFrontRow(request: PlayPermanentAction): number {
    const player = this.getPlayerById(request.playerId);
    // TODO restore
    if (!player) return 501;

    const cardBase = CardDatabase.getCardById(request.cardId);
    if (cardBase.type != CardType.SPELL) {
      for (let i = 0; i < 3; i++) {
        const card = new CardPermanentInstance(cardBase as PermanentCard);
        if (player.canPlay(card, i, 1, request.costDiscount)) {
          player.play(card, i, 1, request.costDiscount).forEach((effect: Effect) => {
            this.effectPipeline.push(effect);
          });
        } else {
          // TODO
          // return 502;
        }
      }
    }
    return TenantResponseCode.OK;
  }

  onAllPermanents(
    action: (card: CardPermanentInstance | null, ownerId: string, x: number, y: number) => void,
  ) {
    this.player1.board.onAll((card: CardPermanentInstance | null, x: number, y: number) =>
      action(card, this.player1.id, x, y),
    );
    this.player2.board.onAll((card: CardPermanentInstance | null, x: number, y: number) =>
      action(card, this.player2.id, x, y),
    );
  }

  recalculateBoardState() {
    this.onAllPermanents((card) => card?.clearStaticModifiers(this));
    const staticModifiers = this.getAllStaticModifiers();
    for (const [boardTargetKey, modifiers] of staticModifiers.entries()) {
      const target = StaticModifierUtil.parseBoardTargetKey(boardTargetKey);
      const player = this.getPlayerById(target.playerId);
      if (player) {
        const permanent = player.getPermanent(target.x, target.y);
        if (permanent !== null) {
          modifiers.forEach((modifier) => permanent.addStaticModifier(modifier, this));
        }
      }
    }
    const playerModifiers = this.getAllStaticPlayerModifiers();
    this.player1.setPlayerModifier(playerModifiers[0]);
    this.player2.setPlayerModifier(playerModifiers[1]);
    this.clearAllDeadBodies();
    this.updateShields();
    this.updateCantBe();
  }

  updateCantBe() {
    this.player1.board.onAll((card) => {
      if (card?.isExhausted && !card.canBeExhausted) card.resetExhaustion();
    });
    this.player2.board.onAll((card) => {
      if (card?.isExhausted && !card.canBeExhausted) card.resetExhaustion();
    });
    this.player1.board.onAll((card) => {
      if (card?.isStunned && !card.canBeStunned) card.resetStunned();
    });
    this.player1.board.onAll((card) => {
      if (card?.isStunned && !card.canBeStunned) card.resetStunned();
    });
  }

  updateShields() {
    this.player1.board.onAll((card) => card?.updateShield());
    this.player2.board.onAll((card) => card?.updateShield());
  }

  private getAllStaticModifiers(): CharacterToModifiersMap {
    const modifiers: CharacterToModifiersMap[] = [];
    this.onAllPermanents((card, ownerId, x, y) => {
      if (card !== null && card.getStaticModifiers) {
        const context = {
          battlefield: this,
          ownerId: ownerId,
          instance: card,
          x: x,
          y: y,
          localTarget: true, // TODO? Does this matter?
        } as CardEffectContext;
        modifiers.push(card.getStaticModifiers(context));
      }
    });
    return StaticModifierUtil.combine(modifiers);
  }

  private getAllStaticPlayerModifiers(): PlayerModifierSet[] {
    const ret = [new PlayerModifierSet(), new PlayerModifierSet()];
    this.player1.board.onAll((card, x, y) => {
      if (card !== null && card.getUserStaticModifiers) {
        const context = {
          battlefield: this,
          ownerId: this.player1.id,
          instance: card,
          x: x,
          y: y,
          localTarget: true, // TODO? Does this matter?
        } as CardEffectContext;
        const set = card.getUserStaticModifiers(context);
        ret[0] = ret[0].combine(set);
      }
      if (card !== null && card.getEnemyStaticModifiers) {
        const context = {
          battlefield: this,
          ownerId: this.player1.id,
          instance: card,
          x: x,
          y: y,
          localTarget: true, // TODO? Does this matter?
        } as CardEffectContext;
        const set = card.getEnemyStaticModifiers(context);
        ret[1] = ret[1].combine(set);
      }
    });
    this.player2.board.onAll((card, x, y) => {
      if (card !== null && card.getUserStaticModifiers) {
        const context = {
          battlefield: this,
          ownerId: this.player2.id,
          instance: card,
          x: x,
          y: y,
          localTarget: true, // TODO? Does this matter?
        } as CardEffectContext;
        const set = card.getUserStaticModifiers(context);
        ret[1] = ret[1].combine(set);
      }
      if (card !== null && card.getEnemyStaticModifiers) {
        const context = {
          battlefield: this,
          ownerId: this.player2.id,
          instance: card,
          x: x,
          y: y,
          localTarget: true, // TODO? Does this matter?
        } as CardEffectContext;
        const set = card.getEnemyStaticModifiers(context);
        ret[0] = ret[0].combine(set);
      }
    });
    return ret;
  }

  private clearAllDeadBodies() {
    this.player1.board.markDeadBodiesForRemoval();
    this.player2.board.markDeadBodiesForRemoval();
    [...this.player1.board.purge(), ...this.player2.board.purge()]
      .flatMap((effect: Effect) => {
        return effect.process(this);
      })
      .forEach((effect: Effect) => {
        this.effectPipeline.push(effect);
      });
  }

  processPipelineAdNauseum() {
    while (this.effectPipeline.length > 0) {
      this.processPipeline().forEach((effect: Effect) => this.effectPipeline.push(effect));
    }
  }

  processPipeline(): Effect[] {
    const newEffects: Effect[] = [];
    while (this.effectPipeline.length > 0) {
      this.effectPipeline
        .pop()
        ?.process(this)
        .forEach((effect: Effect) => newEffects.push(effect));
    }
    return newEffects;
  }

  performAttacks() {
    this.player1.board.performAttacks(this.player2.board, this.player2.player);
    this.player2.board.performAttacks(this.player1.board, this.player1.player);
  }

  getPermanent(playerId: string, target: Target): CardPermanentInstance | null {
    const player = this.getPlayerById(playerId);
    if (player !== null) {
      const playerTarget = target.isLocalTarget ? player : this.getOpponentByPlayer(player);
      if (playerTarget !== null) {
        return playerTarget.getPermanent(target.x, target.y);
      }
    }
    return null;
  }

  onCombatEffects() {
    this.onAllPermanents(
      (card: CardPermanentInstance | null, ownerId: string, x: number, y: number) => {
        if (card && card.onCombat) {
          const context = {
            battlefield: this,
            ownerId: ownerId,
            instance: card,
            x: x,
            y: y,
            localTarget: true,
          } as CardEffectContext;
          card.onCombat(context)?.forEach((effect) => this.effectPipeline.push(effect));
        }
      },
    );
    // TODO
    this.recalculateBoardState();
  }

  asData(playerId: string): FullData | null {
    const player = this.getPlayerById(playerId);
    const opponent = this.getOpponentById(playerId);
    if (player != null && opponent != null) {
      return {
        user: player.asData(),
        opponent: opponent.asData(),
      } as FullData;
    } else return null;
  }
}
