import React, { useEffect, useState } from 'react';
import { CardInstanceData, Field1v1Data, GamePhase, Target } from 'tenant-common';
import { PlayTile } from './PlayTile';
import { CountdownWidget } from './CountdownWidget';

interface GameBoardProps {
  playerSide: Field1v1Data;
  opponentSide: Field1v1Data;
  phase: GamePhase;
  onClickTile: (target: Target) => void;
  onLongPressTile: (id: number) => void;
}
export function GameBoard({
  playerSide,
  opponentSide,
  phase,
  onClickTile,
  onLongPressTile,
}: GameBoardProps): JSX.Element {
  const [lastPhase, setLastPhase] = useState<GamePhase>(GamePhase.WAITING_TO_START);
  const [lastPhaseTime, setLastPhaseTime] = useState<number>(Date.now);
  useEffect(() => {
    if (phase != lastPhase) {
      setLastPhase(phase);
      setLastPhaseTime(Date.now);
    }
  }, [phase]);
  const style: React.CSSProperties = {
    background: 'url(images/battlefield.png) no-repeat center center',
    backgroundSize: 'auto 100%', // Fill to the height of the container
    margin: '0px',
    padding: '0px',
  };
  return (
    <div style={style}>
      <CardRow
        isEnemy={true}
        cards={opponentSide.back}
        onClickTile={(x: number) => onClickTile({ x: x, y: 0, isLocalTarget: false })}
        onLongPressTile={onLongPressTile}
      />
      <CardRow
        isEnemy={true}
        cards={opponentSide.front}
        onClickTile={(x: number) => onClickTile({ x: x, y: 1, isLocalTarget: false })}
        onLongPressTile={onLongPressTile}
      />
      <CountdownWidget
        shouldAnimate={lastPhase == GamePhase.AWAITING_ROUND_ACTION}
        key={lastPhaseTime}
      />
      <CardRow
        isEnemy={false}
        cards={playerSide.front}
        onClickTile={(x: number) => onClickTile({ x: x, y: 1, isLocalTarget: true })}
        onLongPressTile={onLongPressTile}
      />
      <CardRow
        isEnemy={false}
        cards={playerSide.back}
        onClickTile={(x: number) => onClickTile({ x: x, y: 0, isLocalTarget: true })}
        onLongPressTile={onLongPressTile}
      />
    </div>
  );
}

interface CardRowProps {
  cards: (CardInstanceData | null)[];
  isEnemy: boolean;
  onClickTile?: (x: number) => void;
  onLongPressTile: (id: number) => void;
}
function CardRow({ cards, isEnemy, onClickTile, onLongPressTile }: CardRowProps): JSX.Element {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px',
    padding: '0px',
  };
  return (
    <div style={style}>
      <PlayTile
        onLongPress={() => {
          if (cards[0] != null) {
            onLongPressTile(cards[0].id);
          }
        }}
        card={cards[0]}
        onClick={() => onClickTile?.(0)}
        isEnemy={isEnemy}
      />
      <PlayTile
        onLongPress={() => {
          if (cards[1] != null) {
            onLongPressTile(cards[1].id);
          }
        }}
        card={cards[1]}
        onClick={() => onClickTile?.(1)}
        isEnemy={isEnemy}
      />
      <PlayTile
        onLongPress={() => {
          if (cards[2] != null) {
            onLongPressTile(cards[2].id);
          }
        }}
        card={cards[2]}
        onClick={() => onClickTile?.(2)}
        isEnemy={isEnemy}
      />
    </div>
  );
}
