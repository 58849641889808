import React from 'react';
import { M } from '../../uilib/Modifier';

interface SemiDonutProps {
  style?: M;
  outerRadius: number;
  ringThickness: number;
  arcLength: number;
  color: string;
  opacity: string;
  rotationOffset?: number;
}

export const SemiDonut: React.FC<SemiDonutProps> = ({
  style,
  outerRadius,
  ringThickness,
  arcLength,
  color,
  opacity,
  rotationOffset = 0,
}) => {
  const offset = 180 + rotationOffset;
  arcLength += 90;
  const adjustedRadius = outerRadius - ringThickness / 2;
  const circumference = 2 * Math.PI * adjustedRadius;
  const arcLengthInPx = (arcLength / 360) * circumference;

  return (
    <svg
      style={style?.css()}
      width={outerRadius * 2}
      height={outerRadius * 2}
      transform={`rotate(${offset})`}
      viewBox={`0 0 ${outerRadius * 2} ${outerRadius * 2}`}
    >
      <circle
        cx={outerRadius}
        cy={outerRadius}
        r={adjustedRadius}
        stroke={color}
        strokeWidth={ringThickness}
        strokeDasharray={`${arcLengthInPx} ${circumference}`}
        strokeDashoffset={circumference / 4}
        fill="none"
        strokeOpacity={opacity}
        transform={`rotate(-90 ${outerRadius} ${outerRadius})`}
      />
    </svg>
  );
};
