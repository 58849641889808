import React, { useState } from 'react';
import { M } from '../../../uilib/Modifier';
import { FullCard } from '../../component/FullCard';
import { Card, CardColor, CardDatabase, CardUiProperties } from 'tenant-common';

export function DebugCardDatabase(): JSX.Element {
  const [colors, setColors] = useState([
    CardColor.BLACK,
    CardColor.BLUE,
    CardColor.GREEN,
    CardColor.NEUTRAL,
    CardColor.RED,
    CardColor.WHITE,
  ]);
  const cardCount = 220;
  const numbers = Array.from({ length: cardCount }, (_, i) => i + 1);
  const [deck, setDeck] = useState<number[]>(Array.from({ length: cardCount }, () => 0));
  const rawCards = numbers
    .map((n) => {
      try {
        return CardDatabase.getCardById(n);
      } catch (e) {
        return undefined;
      }
    })
    .filter((item): item is Card => item !== undefined)
    .sort((a, b) => {
      if (a.color !== b.color) {
        return a.color - b.color;
      } else {
        return a.cost - b.cost;
      }
    });

  const handleIncrement = (id: number) => {
    setDeck((prev) => {
      return prev.map((value, index) => {
        if (index === id) {
          return Math.min(2, value + 1);
        }
        return value;
      });
    });
  };

  const handleDecrement = (id: number) => {
    setDeck((prev) => prev.map((value, index) => (index === id ? Math.max(0, value - 1) : value)));
  };

  const generateString = () => {
    return deck.flatMap((value, index) => Array(value).fill(index)).join(', ');
  };

  const totalEntries = deck.reduce((sum, value) => sum + value, 0);

  return (
    <div style={M.padding('16px').flex.gap('16px').flexWrap.css()}>
      <div>
        {deck
          .flatMap((value, index) => Array(value).fill(index))
          .map((value, idx) => (
            <span key={idx}>{value}, </span>
          ))}
      </div>
      (Total cards: {totalEntries})
      <CopyButton textToCopy={generateString()} />
      <div style={M.fillWidth.flexRow.flexWrap.gap('8px').alignCenter.justifyCenter.css()}>
        <div
          style={M.padding('12px')
            .fontSize('20px')
            .roundCorners('20px')
            .flex.alignCenter.justifyCenter.background('#eeeeee')
            .css()}
          onClick={() =>
            colors.length > 0
              ? setColors([])
              : setColors([
                  CardColor.BLACK,
                  CardColor.BLUE,
                  CardColor.GREEN,
                  CardColor.NEUTRAL,
                  CardColor.RED,
                  CardColor.WHITE,
                ])
          }
        >
          All
        </div>
        <ColorFilter
          color={CardColor.BLACK}
          selected={colors.includes(CardColor.BLACK)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.BLACK)
                ? prevColors.filter((color) => color !== CardColor.BLACK)
                : [...prevColors, CardColor.BLACK],
            )
          }
        />
        <ColorFilter
          color={CardColor.RED}
          selected={colors.includes(CardColor.RED)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.RED)
                ? prevColors.filter((color) => color !== CardColor.RED)
                : [...prevColors, CardColor.RED],
            )
          }
        />
        <ColorFilter
          color={CardColor.GREEN}
          selected={colors.includes(CardColor.GREEN)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.GREEN)
                ? prevColors.filter((color) => color !== CardColor.GREEN)
                : [...prevColors, CardColor.GREEN],
            )
          }
        />
        <ColorFilter
          color={CardColor.BLUE}
          selected={colors.includes(CardColor.BLUE)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.BLUE)
                ? prevColors.filter((color) => color !== CardColor.BLUE)
                : [...prevColors, CardColor.BLUE],
            )
          }
        />
        <ColorFilter
          color={CardColor.WHITE}
          selected={colors.includes(CardColor.WHITE)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.WHITE)
                ? prevColors.filter((color) => color !== CardColor.WHITE)
                : [...prevColors, CardColor.WHITE],
            )
          }
        />
        <ColorFilter
          color={CardColor.NEUTRAL}
          selected={colors.includes(CardColor.NEUTRAL)}
          onClick={() =>
            setColors((prevColors) =>
              prevColors.includes(CardColor.NEUTRAL)
                ? prevColors.filter((color) => color !== CardColor.NEUTRAL)
                : [...prevColors, CardColor.NEUTRAL],
            )
          }
        />
      </div>
      {rawCards.map((foo) => {
        try {
          return colors.includes(foo.color) ? (
            <div style={M.flexColumn.css()}>
              <FullCard key={foo.id} id={foo.id} />
              <div style={M.flex.justifyCenter.alignCenter.paddingTop('2px').css()}>
                <span
                  style={M.paddingHorizontal('8px')
                    .paddingBottom('2px')
                    .paddingTop('1px')
                    .background('blue')
                    .roundCorners('6px')
                    .color('white')
                    .bold.css()}
                  onClick={() => handleIncrement(foo.id)}
                >
                  +
                </span>
                <div style={M.width('4px').css()}></div>
                <span
                  style={M.paddingHorizontal('8px')
                    .paddingBottom('2px')
                    .paddingTop('1px')
                    .background('blue')
                    .roundCorners('6px')
                    .color('white')
                    .bold.css()}
                  onClick={() => handleDecrement(foo.id)}
                >
                  -
                </span>
              </div>
            </div>
          ) : (
            <></>
          );
        } catch (e) {
          return <></>;
        }
      })}
    </div>
  );
}

interface ColorFilterProps {
  selected: boolean;
  color: CardColor;
  onClick: () => void;
}
function ColorFilter({ selected, color, onClick }: ColorFilterProps): JSX.Element {
  return (
    <div
      onClick={onClick}
      style={M.size('50px')
        .borderBox.roundCorners('20px')
        .background(CardUiProperties.getColorForType(color))
        .addIf(selected, (b) => b.border('4px magenta solid'))
        .css()}
    ></div>
  );
}

interface CopyButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert('Text copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return <button onClick={handleCopy}>Copy Text</button>;
};
