import { io, Socket } from 'socket.io-client';
import {
  CardDatabase,
  CardInstanceData,
  CardType,
  Chat,
  FullData,
  FullDataUtil,
  GamePhase,
  MakeManaActionRequest,
  Message,
  Payload,
  PlayCardActionRequest,
  PlayInterface,
  PlayInterfaceState,
  RevealActionsRequest,
  ServerUpdate,
  SkipActionRequest,
  Target,
  TenantMessage,
  TenantPayload,
} from 'tenant-common';

// const socket = io('https://www.forzen.dev');
// const socket = io('http://localhost:3000');

// const socket = io('http://localhost:3000', {
//   reconnectionAttempts: 5,
//   reconnectionDelay: 1000,
// });

// socket.on('connect', () => {
//   console.log('Connected to server');
// });

// socket.on('disconnect', (reason) => {
//   console.log(`Disconnected: ${reason}`);
//   if (reason === 'io server disconnect') {
//     socket.connect(); // Manually reconnect if the server disconnected
//   }
// });

// socket.on('error', (err) => {
//   console.error(`Socket error: ${err}`);
// });

export class NetworkPlayInterface implements PlayInterface {
  private _chatHistory: Chat[] = [];
  private _gameState: FullData | null = null;
  private _playerHasSkipped = false;
  private _localHasPlayerPlayedManaThisTurn = false;
  private _isPendingAddMana = false;
  private _currentGamePhase = GamePhase.WAITING_TO_START;
  private _turnCount = 0;
  private _revealedActions: RevealActionsRequest | null = null;
  private _selectedHandIndex: number | null = null;

  public state: PlayInterfaceState = new PlayInterfaceState(
    [],
    null,
    false,
    false,
    false,
    GamePhase.WAITING_TO_START,
    0,
    null,
    null,
  );

  constructor(
    public readonly userToken: string,
    public readonly socket: Socket,
    public readonly onStateUpdate: (state: PlayInterfaceState) => void,
  ) {
    this.handleChat = this.handleChat.bind(this);
    this.handleFullStatus = this.handleFullStatus.bind(this);
    this.handleMakeMana = this.handleMakeMana.bind(this);
    this.handlePlayCard = this.handlePlayCard.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleStartTurn = this.handleStartTurn.bind(this);
    this.handleAwaitingRoundAction = this.handleAwaitingRoundAction.bind(this);
    this.handleReveaingRoundAction = this.handleReveaingRoundAction.bind(this);
    this.handleCombat = this.handleCombat.bind(this);
    this.handleRevealedActions = this.handleRevealedActions.bind(this);
    this.performSkip = this.performSkip.bind(this);
    this.performAddMana = this.performAddMana.bind(this);
    this.performPlayCard = this.performPlayCard.bind(this);
  }

  private updateStateFlag() {
    this.state = new PlayInterfaceState(
      this._chatHistory,
      this._gameState,
      this._playerHasSkipped,
      this._localHasPlayerPlayedManaThisTurn,
      this._isPendingAddMana,
      this._currentGamePhase,
      this._turnCount,
      this._revealedActions,
      this._selectedHandIndex,
    );
    this.onStateUpdate(this.state);
  }

  handleChat(payload: string): void {
    console.log('[CHAT] MESSAGE RECEIVED: ' + payload);
    const chat = Payload.parseChat(payload);
    if (chat) {
      this._chatHistory.push(chat);
      this.updateStateFlag();
    }
  }

  handleFullStatus(payload: string): void {
    const status = TenantPayload.parseFullData(payload);
    if (status) {
      console.log(status);
      this._gameState = status;
      this.updateStateFlag();
    }
  }

  handleMakeMana(payload: string): void {
    console.log('[MAKE_MANA] MESSAGE RECEIVED: ' + payload);
    const status = TenantPayload.parseMakeMana(payload);
    if (status) {
      this._playerHasSkipped = true;
      this._localHasPlayerPlayedManaThisTurn = true;
      this._isPendingAddMana = true;
      this.updateStateFlag();
    }
  }

  handlePlayCard(payload: string): void {
    console.log('[PLAY_CARD] MESSAGE RECEIVED: ' + payload);
    const status = TenantPayload.parsePlay(payload);
    if (status && this._gameState != null) {
      this._playerHasSkipped = true;
      const updatedUser = {
        ...this._gameState.user,
        hand: [...this._gameState.user.hand],
        player: { ...this._gameState.user.player },
      };
      const cardId = updatedUser.hand[status.handIndex];
      if (cardId) {
        const card = CardDatabase.getCardById(cardId);
        updatedUser.player.currentMana -= card.cost;
        if (card.type != CardType.SPELL) {
          const row = status.target.y == 0 ? updatedUser.board.back : updatedUser.board.front;
          row[status.target.x] = {
            id: cardId,
            isExhausted: false,
            isFrontendPreview: true,
          } as CardInstanceData;
        }
      }
      updatedUser.hand[status.handIndex] = null;

      // Returning a new state object with updated user
      this._gameState = { ...this._gameState, user: updatedUser };
      this.updateStateFlag();
    }
  }

  handleSkip(): void {
    console.log('HANDLE SKIP');
    this._playerHasSkipped = true;
    this.updateStateFlag();
  }

  handleStartTurn(): void {
    console.log('START TURN');
    this._turnCount += 1;
    this._localHasPlayerPlayedManaThisTurn = false;
    this._currentGamePhase = GamePhase.STARTING_TURN;
    this.updateStateFlag();
  }
  handleAwaitingRoundAction(): void {
    console.log('AWAITING ACTION');
    this._currentGamePhase = GamePhase.AWAITING_ROUND_ACTION;
    this._playerHasSkipped = false;
    this.updateStateFlag();
  }

  handleReveaingRoundAction(payload: string): void {
    console.log('REVEALING ACTION');
    this._currentGamePhase = GamePhase.REVEALING_ROUND_ACTION;
    this.updateStateFlag();
  }

  handleCombat(payload: string): void {
    console.log('HANDLE COMBAT');
    this._currentGamePhase = GamePhase.ANIMATING_ATTACKS;
    this.updateStateFlag();
  }

  handleRevealedActions(payload: string): void {
    console.log('HANDLE REVEAL');
    const revealedActions = TenantPayload.parseRevealActionsRequest(payload);
    console.log(revealedActions);
    this._revealedActions = revealedActions;
    this.updateStateFlag();
  }

  clearRevealedActions(): void {
    this._revealedActions = null;
    this.updateStateFlag();
  }

  setSelectedHandIndex(index: number | null): void {
    this._selectedHandIndex = index;
    this.updateStateFlag();
  }

  init(): void {
    console.log('MESSAGES ON');
    this.socket.on(Message.CHAT, this.handleChat);
    this.socket.on(TenantMessage.FULL_DATA, this.handleFullStatus);
    this.socket.on(TenantMessage.MAKE_MANA, this.handleMakeMana);
    this.socket.on(TenantMessage.PLAY, this.handlePlayCard);
    this.socket.on(TenantMessage.SKIP, this.handleSkip);

    this.socket.on(ServerUpdate.START_TURN, this.handleStartTurn);
    this.socket.on(ServerUpdate.AWAITING_ROUND_ACTION, this.handleAwaitingRoundAction);
    this.socket.on(ServerUpdate.REVEALING_ROUND_ACTION, this.handleReveaingRoundAction);
    this.socket.on(ServerUpdate.COMBAT, this.handleCombat);
    this.socket.on(ServerUpdate.PROVIDE_REVEALED_ACTIONS, this.handleRevealedActions);
  }

  deinit(): void {
    console.log('MESSAGES OFF');
    this.socket.off(Message.CHAT, this.handleChat);
    this.socket.off(TenantMessage.FULL_DATA, this.handleFullStatus);
    this.socket.off(TenantMessage.MAKE_MANA, this.handleMakeMana);
    this.socket.off(TenantMessage.PLAY, this.handlePlayCard);
    this.socket.off(TenantMessage.SKIP, this.handleSkip);

    this.socket.off(ServerUpdate.START_TURN, this.handleStartTurn);
    this.socket.off(ServerUpdate.AWAITING_ROUND_ACTION, this.handleAwaitingRoundAction);
    this.socket.off(ServerUpdate.REVEALING_ROUND_ACTION, this.handleReveaingRoundAction);
    this.socket.off(ServerUpdate.COMBAT, this.handleCombat);
    this.socket.off(ServerUpdate.PROVIDE_REVEALED_ACTIONS, this.handleRevealedActions);
  }

  performSkip(): void {
    console.log('SKIP');
    if (this.userToken) {
      this.socket.emit(
        TenantMessage.SKIP,
        JSON.stringify({
          playerId: this.userToken,
        } as SkipActionRequest),
      );
    }
  }

  performAddMana(handIndex: number): void {
    if (handIndex == null) return;
    console.log('MAKE MANA');
    if (this.userToken) {
      this.socket.emit(
        TenantMessage.MAKE_MANA,
        JSON.stringify({
          playerId: this.userToken,
          handIndex: handIndex,
        } as MakeManaActionRequest),
      );
      this.setSelectedHandIndex(null);
      this.updateStateFlag();
    }
  }

  performPlayCard(selectedHandIndex: number, target: Target): void {
    console.log(`PLAY CARD ${target}`);
    if (this.userToken && this._gameState) {
      const targetedPermanent = FullDataUtil.findTarget(this.userToken, this._gameState, target);
      const cardId =
        selectedHandIndex !== null ? this._gameState.user.hand[selectedHandIndex] : null;
      const card = cardId !== null ? CardDatabase.getCardById(cardId) : null;
      if (card !== null) {
        if (
          (targetedPermanent == null &&
            (card.type == CardType.CREATURE || card.type == CardType.MONUMENT)) ||
          card.type == CardType.SPELL
        ) {
          // PLAY CARD
          if (selectedHandIndex !== null) {
            this.socket.emit(
              TenantMessage.PLAY,
              JSON.stringify({
                playerId: this.userToken,
                handIndex: selectedHandIndex,
                target: target,
              } as PlayCardActionRequest),
            );
            this.setSelectedHandIndex(null);
            this.updateStateFlag();
          }
          // TODO targeting data
        }
      }
    }
  }
}
