import { CardDatabase } from '..';
import { Field1v1Data, FullData, Point } from '../data';
import { ManaActionNeo, PlayActionNeo, UserActionNeo } from '../domain';
import { ActionType } from '../network';

export interface PlayerAi {
  onNewTurn(): void;
  getAction(state: FullData): UserActionNeo;
}

export class BasicAi implements PlayerAi {
  private hasPlayedMana: boolean = false;

  constructor(public readonly playerId: string) {}

  onNewTurn(): void {
    this.hasPlayedMana = false;
  }

  getAction(state: FullData): UserActionNeo {
    const player = state.user.player.username == this.playerId ? state.user : state.opponent;

    // TODO Add hasPlayedMana to player state data

    if (!this.hasPlayedMana) {
      this.hasPlayedMana = true;
      // TODO Random index? Check if need mana?
      return new ManaActionNeo(this.playerId, 0);
    } else {
      // Check if we can cast anything anywhere and then do so at a random spot
      const filteredNumbers: number[] = player.hand.filter((num): num is number => num !== null);
      const cards = filteredNumbers.map((num) => {
        return CardDatabase.getCardById(num);
      });
      const castableCards = cards.filter((card) => card.cost <= player.player.currentMana);
      const emptySquares = findEmptySquares(player.board);
      if (castableCards.length > 0 && emptySquares.length > 0) {
        let randomIndex = Math.floor(Math.random() * cards.length);
        const toCast = cards[randomIndex];
        const handIndex = player.hand.indexOf(toCast.id);
        randomIndex = Math.floor(Math.random() * emptySquares.length);
        const whereCast = emptySquares[randomIndex];
        return new PlayActionNeo(this.playerId, handIndex, whereCast.x, whereCast.y);
      } else {
        // Pass if nothing is playable
        return new UserActionNeo(this.playerId, ActionType.PASS);
      }
    }
  }
}

function findEmptySquares(board: Field1v1Data): Point[] {
  const nullIndices: Point[] = [];

  // Check back array (y = 0)
  board.back.forEach((card, index) => {
    if (card === null) {
      nullIndices.push({ x: index, y: 0 });
    }
  });

  // Check front array (y = 1)
  board.front.forEach((card, index) => {
    if (card === null) {
      nullIndices.push({ x: index, y: 1 });
    }
  });

  return nullIndices;
}
