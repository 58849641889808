export class ModifierData {
  constructor(
    // Instance fields
    public readonly power: number | null = null,
    public readonly canAttack: boolean | null = null,
    public readonly canBeExhausted: boolean | null = null,
    public readonly canBeStunned: boolean | null = null,
    public readonly canBeSpellTargeted: boolean | null = null,
    public readonly isDeadly: boolean | null = null,
  ) {}
}

export class PlayerModifierData {
  constructor(
    // Instance fields
    public readonly allSpellCostReduction: number | null = null,
    public readonly canAddMana: boolean | null = null,
  ) {}
}
