// import React from 'react';
// import './index.css';

// function App() {
//   return <div className="App-container">Hello World!</div>;
// }

// export default App;

import React from 'react';
import { TenantGameScreen } from './ui/screen/TenantMainScreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DebugComponent } from './ui/panel/debug/DebugComponents';
import { DebugGameArea } from './ui/panel/debug/DebugGameArea';
import '@fontsource/alfa-slab-one';
import '@fontsource/michroma';
import { DebugGameAreaPhone } from './ui/panel/debug/DebugGameAreaPhone';
import { DebugCardDatabase } from './ui/panel/debug/DebugCardDatabase';
import { DebugRevealComponents } from './ui/panel/debug/DebugRevealComponents';

function App() {
  return (
    <div className="App-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TenantGameScreen />} />
          <Route path="/debug_components" element={<DebugComponent />} />
          <Route path="/debug_gamearea" element={<DebugGameArea />} />
          <Route path="/debug_gamearea_phone" element={<DebugGameAreaPhone />} />
          <Route path="/debug_card_database" element={<DebugCardDatabase />} />
          <Route path="/debug_reveal_components" element={<DebugRevealComponents />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
