import { MathUtil } from '../../util';
import { Game } from '../Game';
import { CardInstanceData } from '../data';
import { Effect } from '../domain';
import { PlayerInstance, PlayerModifierSet } from '../player';
import { Attribute } from './Attribute';
import {
  CardEffectContext,
  CardType,
  CreatureCard,
  MonumentCard,
  PermanentCard,
  SpellCard,
} from './Card';
import { CardColor } from './CardColor';
import { CardModifier, CharacterToModifiersMap, ModifierSet } from './CardModifier';

export class CardSpellInstance {
  constructor(public readonly base: SpellCard) {}
}

// TODO split creature and monument, superclass
export class CardPermanentInstance {
  private _isDead: boolean = false;
  private _usedEnergy: number = 0;
  private _isExhausted: boolean = false;
  private _isStunned: boolean = false;
  private _modifierSet: ModifierSet = new ModifierSet();
  private _base: PermanentCard;
  private _isShielded: boolean;
  private _shieldMarkedToBeRemoved: boolean = false;

  private _destroyer: CardPermanentInstance | CardSpellInstance | null | undefined;

  constructor(base: PermanentCard) {
    this._base = base;
    this._isShielded = this._base.isShielded;
  }

  public get id(): number {
    return this._base.id;
  }

  public get destroyer(): CardPermanentInstance | CardSpellInstance | null | undefined {
    return this._destroyer;
  }

  public get cost(): number {
    // TODO hand instance?
    return this._base.cost;
  }

  public get isShielded(): boolean {
    return this._isShielded;
  }

  public get color(): CardColor {
    return this._base.color;
  }

  public get power(): number | null {
    // TODO hand instance?
    return this._base.type == CardType.CREATURE
      ? (this._base as CreatureCard).attack + (this._modifierSet.power ?? 0)
      : 0;
  }

  public get energyRemaining(): number | null {
    const maxEnergy =
      this._base.type == CardType.MONUMENT ? (this._base as MonumentCard).maxEnergy : null;
    return maxEnergy == null ? null : MathUtil.clamp(maxEnergy - this._usedEnergy, 0);
  }

  public get isReadyForAttack(): boolean {
    return this.canAttack && !this._isExhausted && !this._isStunned;
  }

  public get canAttack(): boolean {
    return (
      this._base.type == CardType.CREATURE &&
      !this.isExhausted &&
      !this._base.attackDisabled &&
      (this._modifierSet.canAttack ?? true)
    );
  }

  public get canBeExhausted(): boolean {
    return this._modifierSet.canBeExhausted ?? true;
  }

  public get canBeStunned(): boolean {
    return this._modifierSet.canBeStunned ?? true;
  }

  public get canBeSpellTargeted(): boolean {
    return this._modifierSet.canBeSpellTargeted ?? true;
  }

  public get isRanged(): boolean {
    return this._base.isRanged;
  }

  public get isDeadly(): boolean {
    return this._base.isDeadly || (this._modifierSet.isDeadly ?? false);
  }

  public get hasBreakthrough(): boolean {
    return this._base.hasBreakthrough;
  }

  public get isExhausted(): boolean {
    return this._isExhausted;
  }

  public get isStunned(): boolean {
    return this._isStunned;
  }

  public get isReadyForTimeEffect(): boolean {
    return (
      this._base.type == CardType.MONUMENT &&
      this._usedEnergy >= ((this._base as MonumentCard).maxEnergy ?? -1)
    );
  }

  public get extraDamage(): number {
    if (this.type == CardType.CREATURE) {
      return (this._base as CreatureCard).extraDamage;
    } else {
      return 0;
    }
  }

  public get isDead(): boolean {
    return (
      this._isDead || (this.type == CardType.CREATURE && this.power !== null && this.power <= 0)
    );
  }

  public get type(): CardType {
    return this._base.type;
  }

  public shield() {
    this._isShielded = true;
  }

  public removeShield() {
    this._shieldMarkedToBeRemoved = true;
  }

  public updateShield() {
    if (this._shieldMarkedToBeRemoved) {
      this._isShielded = false;
      this._shieldMarkedToBeRemoved = false;
    }
  }

  public get onTimeEffectTriggered(): ((context: CardEffectContext) => void) | undefined {
    return (this._base as MonumentCard).onTimeEffectTriggered;
  }

  public get onDeploy(): ((context: CardEffectContext) => Effect[]) | undefined {
    return this._base.onDeploy;
  }

  public get onDestroy(): ((context: CardEffectContext) => Effect[]) | undefined {
    // TODO rename
    return this._base.onDeath;
  }

  public get onCombat(): ((context: CardEffectContext) => Effect[]) | undefined {
    return this._base.onCombat;
  }

  public get onAddMana(): ((context: CardEffectContext) => Effect[]) | undefined {
    return this._base.onAddMana;
  }

  public get getStaticModifiers():
    | ((context: CardEffectContext) => CharacterToModifiersMap)
    | undefined {
    return this._base.getStaticModifiers;
  }

  public get getUserStaticModifiers():
    | ((context: CardEffectContext) => PlayerModifierSet)
    | undefined {
    return this._base.getUserStaticModifiers;
  }

  public get getEnemyStaticModifiers():
    | ((context: CardEffectContext) => PlayerModifierSet)
    | undefined {
    return this._base.getEnemyStaticModifiers;
  }

  public get attributes(): Attribute[] {
    return this._base.attributes ? [...this._base.attributes] : [];
  }

  countdownEffect() {
    if (this._base.type == CardType.MONUMENT) {
      this._usedEnergy += 1;
      const maxEnergy = (this._base as MonumentCard).maxEnergy ?? -1;
      if (maxEnergy >= 0 && this._usedEnergy >= maxEnergy) {
        this.destroy();
      }
    }
  }

  countdownAttackTimer() {
    if (this.canAttack) this._usedEnergy += 1;
  }

  resetTimer() {
    this._usedEnergy = 0;
  }

  exhaust() {
    if (this._modifierSet.canBeExhausted ?? true) {
      this._isExhausted = true;
    }
  }

  stun() {
    if (this._modifierSet.canBeStunned ?? true) {
      this._isStunned = true;
    }
  }

  resetExhaustion() {
    this._isExhausted = false;
    this._isStunned = false;
  }

  resetStunned() {
    this._isStunned = false;
  }

  cleanse() {
    this._modifierSet = new ModifierSet();
    this._isStunned = false;
    this._isShielded = false;
  }

  fight(enemy: CardPermanentInstance, enemyPlayer: PlayerInstance) {
    let destroyedEnemy = false;
    if (this._base.type == CardType.CREATURE) {
      if (enemy._base.type == CardType.CREATURE) {
        const mutual =
          (this.isRanged
            ? enemy.isRanged // Mutual if you are ranged and enemy is also ranged
            : true) && // Otherwise always mutual...
          !enemy.isStunned; // But not mutual if they're stunned

        if (this.isDeadly) {
          enemy.destroy(this);
          destroyedEnemy = true;
        }
        if (enemy.isDeadly && mutual) this.destroy(enemy);
        const thisPower = this.power;
        const thatPower = enemy.power;
        if (thisPower !== null && thatPower !== null) {
          if (thisPower > thatPower) {
            enemy.destroy(this);
            destroyedEnemy = true;
          } else if (thisPower < thatPower) {
            if (mutual) {
              this.destroy(enemy);
            }
          } else {
            if (mutual) {
              this.destroy(enemy);
            }
            enemy.destroy(this);
            destroyedEnemy = true;
          }
        } else if (thatPower == null) {
          enemy.destroy(this);
          destroyedEnemy = true;
        }
        if (mutual) {
          this.removeShield();
        }
      } else {
        enemy.destroy(this);
        destroyedEnemy = true;
      }
    }
    enemy.removeShield();

    if (destroyedEnemy) {
      if (this.hasBreakthrough) {
        enemyPlayer.takeDirectDamage();
      }
    }
  }

  destroy(source?: CardPermanentInstance | CardSpellInstance | null | undefined) {
    if (!source || !this._isShielded) {
      this._isDead = true;
      this._destroyer = source;
    } else {
      this.removeShield();
    }
  }

  addModifier(modifier: CardModifier, battlefield: Game) {
    this._modifierSet.addModifier(modifier, battlefield);
  }

  addStaticModifier(modifier: CardModifier, battlefield: Game) {
    this._modifierSet.addStaticModifier(modifier, battlefield);
  }

  addEotModifier(modifier: CardModifier, battlefield: Game) {
    this._modifierSet.addEotModifier(modifier, battlefield);
  }

  clearStaticModifiers(battlefield: Game) {
    this._modifierSet.clearStaticModifiers(battlefield);
  }

  clearEotModifiers(battlefield: Game) {
    this._modifierSet.clearEotModifiers(battlefield);
  }

  asData(): CardInstanceData {
    return {
      id: this._base.id,
      modifier: this._modifierSet.asData(),
      isExhausted: this._isExhausted,
      isStunned: this._isStunned,
      isShielded: this._isShielded,
      usedEnergy: this._usedEnergy,
    };
  }
}
