import React from 'react';
import { GameBoard } from '../../component/GameBoard';
import { FullData } from 'tenant-common/dist/game/data/FullData';
import { HandWidget } from '../../component/HandWidget';
import { ManaWidget } from '../../component/ManaWidget';
import { M } from '../../../uilib/Modifier';
import {
  CardColor,
  CardUiProperties,
  GamePhase,
  PlayerInstance,
  RevealActionsRequest,
  Target,
} from 'tenant-common';
import { SkipWidget } from '../../component/SkipWidget';
import { FullCard } from '../../component/FullCard';
import { RevealTile } from '../../component/RevealComponents';
import { DonutWheel } from '../../component/DonutWheel';
import { SemiDonut } from '../../component/DonutWheel2';
import { HeartWidget } from '../../component/HeartWidget';

interface GameAreaProps {
  state: FullData;
  gamePhase: GamePhase;
  selectedHandIndex: number | null;
  hasSkipped: boolean;
  hasPlayedMana: boolean;
  cardPreviewId: number | null;
  revealActionsRequest: RevealActionsRequest | null;
  onClickTile: (target: Target) => void;
  onChangeHandSelection: (index: number) => void;
  onClickAddMana: (handIndex: number | null) => void;
  onSkip: () => void;
  onLongPressCard: (id: number) => void;
  onDismissCardPreview: () => void;
}

function transformCounts(counts: number[]): number[] {
  const result: number[] = [];

  counts.forEach((count, index) => {
    for (let i = 0; i < count; i++) {
      result.push(index);
    }
  });

  return result;
}

export function GameBoardPanel({
  state,
  gamePhase,
  selectedHandIndex,
  hasSkipped,
  hasPlayedMana,
  cardPreviewId,
  revealActionsRequest,
  onClickTile,
  onChangeHandSelection,
  onClickAddMana,
  onSkip,
  onLongPressCard,
  onDismissCardPreview,
}: GameAreaProps): JSX.Element {
  // const manaPool = transformCounts(state.user.player.manaPool ?? []);
  const manaPool = state.user.player.manaPool;
  const manaPool2 = state.opponent.player.manaPool;
  console.log('Mana pool');
  console.log(state.user.player.manaPool);
  console.log('Transformed');
  console.log(manaPool);
  const colorSegments = Array.from({ length: state.user.player.currentMana }, () => ({
    color: CardUiProperties.MANA,
    value: 100 / 12,
  }));
  const numberOfUnfilledSegments = state.user.player.maxMana - colorSegments.length;
  const unfilledSegments = Array.from({ length: numberOfUnfilledSegments }, () => ({
    color: '#376178',
    value: 100 / 12,
  }));
  const numberOfGreySegments = 12 - (colorSegments.length + unfilledSegments.length);
  const greySegments = Array.from({ length: numberOfGreySegments }, () => ({
    color: '#111111',
    value: 100 / 12,
  }));
  const segments = colorSegments.concat(unfilledSegments).concat(greySegments);

  const colorSegments2 = Array.from({ length: state.opponent.player.currentMana }, () => ({
    color: CardUiProperties.MANA,
    value: 100 / 12,
  }));
  const numberOfUnfilledSegments2 = state.opponent.player.maxMana - colorSegments2.length;
  const unfilledSegments2 = Array.from({ length: numberOfUnfilledSegments2 }, () => ({
    color: '#376178',
    value: 100 / 12,
  }));
  const numberOfGreySegments2 = 12 - (colorSegments2.length + unfilledSegments2.length);
  const greySegments2 = Array.from({ length: numberOfGreySegments2 }, () => ({
    color: '#111111',
    value: 100 / 12,
  }));
  const segments2 = colorSegments2.concat(unfilledSegments2).concat(greySegments2);

  const handStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '12px',
  };
  const playerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
  };

  const userReveal = revealActionsRequest
    ? state.user.player.username == revealActionsRequest.player1Action.playerId
      ? revealActionsRequest.player1Action
      : revealActionsRequest.player2Action
    : null;
  const opponentReveal = revealActionsRequest
    ? state.opponent.player.username == revealActionsRequest.player1Action.playerId
      ? revealActionsRequest.player1Action
      : revealActionsRequest.player2Action
    : null;

  return (
    <div style={M.relativePosition.flex.justifyCenter.fillSize.hideOverflow.css()}>
      <div style={M.flexColumn.fillWidth.disableSelection.background('#222222').css()}>
        <div style={M.flex.justifyCenter.alignCenter.padding('8px').textStroke('0').css()}>
          <span style={M.color('white').textStroke('0').css()}>
            {state.opponent.player.username}&nbsp;
          </span>
          {Array.from({ length: PlayerInstance.startingLife }, (_, index) => (
            <HeartWidget key={index} enabled={index < state.opponent.player.life} />
          ))}
          <ManaWidget
            mana={state.opponent.player.currentMana}
            maxMana={state.opponent.player.maxMana}
            enabled={false}
            segments={segments2}
            manaPool={manaPool2 ?? []}
            onClick={() => console.log('')}
          />
          <span style={M.color('white').css()}>D{state.opponent.deckSize}</span>
          <div style={M.size('8px').css()}></div>
          <span style={M.color('white').css()}>H{state.opponent.handSize}</span>
        </div>
        <GameBoard
          playerSide={state.user.board}
          opponentSide={state.opponent.board}
          phase={gamePhase}
          onClickTile={onClickTile}
          onLongPressTile={onLongPressCard}
        />
        <div style={handStyle}>
          <HandWidget
            hand={state.user.hand}
            onClick={onChangeHandSelection}
            onLongPress={onLongPressCard}
            selectedHandIndex={selectedHandIndex}
            playerModifier={state.user.player.modifier}
          />
        </div>
        <div style={M.flex.justifyCenter.alignCenter.padding('8px').css()}>
          <span style={M.color('white').css()}>{state.user.player.username}&nbsp;</span>
          {Array.from({ length: PlayerInstance.startingLife }, (_, index) => (
            <HeartWidget key={index} enabled={index < state.user.player.life} />
          ))}
          <ManaWidget
            mana={state.user.player.currentMana}
            maxMana={state.user.player.maxMana}
            enabled={!hasPlayedMana}
            segments={segments}
            manaPool={manaPool ?? []}
            onClick={() => onClickAddMana(selectedHandIndex)}
          />
          <span style={M.color('white').css()}>D{state.user.deckSize}</span>
          <div style={M.size('8px').css()}></div>
          <SkipWidget onClick={onSkip} hasSkipped={hasSkipped} />
        </div>
      </div>
      {getMessageForPhase(gamePhase) != null && (
        <div
          key={gamePhase}
          style={M.absolutePosition.flex.justifyCenter.alignCenter.noPointerEvents
            .background('red')
            .top('50%')
            .left('50%')
            .transform('translate(-50%, -50%)')
            .width('300px')
            .height('200px')
            .fontSize('32px')
            .circleCorners.animation('fadeInAnimationUp2 1s forwards')
            .css()}
        >
          {getMessageForPhase(gamePhase)}
        </div>
      )}
      {/* Reveal banners */}
      {revealActionsRequest !== null && userReveal && opponentReveal && (
        <div style={M.absolutePosition.fillSize.flexColumn.zIndex('5').noPointerEvents.css()}>
          <div
            style={M.absolutePosition.fillSize
              .background('rgba(0,0,0,0.33)')
              .zIndex('-1')
              .animation('revealFadeInOut 1.75s forwards')
              .css()}
          ></div>
          <div style={M.height('50px').css()}></div>
          <RevealTile
            type={opponentReveal.type}
            cardId={opponentReveal.cardId}
            isLeft={false}
            shouldAnimate={true}
          />
          <div style={M.flexWeight('1').css()}></div>
          <RevealTile
            type={userReveal.type}
            cardId={userReveal.cardId}
            isLeft={true}
            shouldAnimate={true}
          />
          <div style={M.height('50px').css()}></div>
        </div>
      )}

      {/* Card Preview */}
      {cardPreviewId !== null && (
        <div
          style={M.absolutePosition.flex.alignCenter.justifyCenter.fillSize
            .background('rgba(255,255,255,0.5)')
            .textStroke('0')
            .zIndex('20')
            .transform('scale(1.6)')
            .hideOverflow.css()}
          onClick={onDismissCardPreview}
        >
          <FullCard id={cardPreviewId} />
        </div>
      )}
    </div>
  );
}

function getMessageForPhase(gamePhase: GamePhase): string | null {
  switch (gamePhase) {
    case GamePhase.STARTING_TURN:
      return 'Starting Turn';
    // case GamePhase.AWAITING_ROUND_ACTION:
    //   return 'Take Action!';
    case GamePhase.REVEALING_ROUND_ACTION:
      return null;
    case GamePhase.ANIMATING_ATTACKS:
      return 'Combat!';
    case GamePhase.GAME_OVER:
      return 'Game Over!';
  }
  return null;
}
