export enum GamePhase {
  WAITING_TO_START,
  STARTING_TURN,
  START_OF_TURN_EFFECTS,
  AWAITING_ROUND_ACTION,
  REVEALING_ROUND_ACTION,
  COMBAT,
  ANIMATING_ATTACKS,
  GAME_OVER,
}
