import { ActionType } from '../network';

export class UserActionNeo {
  constructor(
    public readonly userId: string,
    public readonly type: ActionType,
  ) {}
}

export class PlayActionNeo extends UserActionNeo {
  constructor(
    userId: string,
    public readonly handIndex: number,
    // TODO make Target
    public readonly x: number,
    public readonly y: number,
  ) {
    super(userId, ActionType.PLAY_ACTION);
  }
}

export class ManaActionNeo extends UserActionNeo {
  constructor(
    userId: string,
    public readonly handIndex: number,
  ) {
    super(userId, ActionType.MANA_ACTION);
  }
}
