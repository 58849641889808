// type CssProperties = {
//   [key: string]: string;
// };

export class M {
  private styles: React.CSSProperties = {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static width(value: string) {
    const instance = new M();
    return instance.width(value);
  }

  static background(value: string) {
    const instance = new M();
    return instance.background(value);
  }

  static height(value: string) {
    const instance = new M();
    return instance.height(value);
  }

  static size(value: string) {
    const instance = new M();
    return instance.size(value);
  }

  static color(value: string) {
    const instance = new M();
    return instance.color(value);
  }

  static margin(value: string) {
    const instance = new M();
    return instance.margin(value);
  }

  static marginLeft(value: string) {
    const instance = new M();
    return instance.marginLeft(value);
  }

  static marginRight(value: string) {
    const instance = new M();
    return instance.marginRight(value);
  }

  static marginTop(value: string) {
    const instance = new M();
    return instance.marginTop(value);
  }

  static marginBottom(value: string) {
    const instance = new M();
    return instance.marginBottom(value);
  }

  static marginHorizontal(value: string) {
    const instance = new M();
    return instance.marginHorizontal(value);
  }

  static marginVertical(value: string) {
    const instance = new M();
    return instance.marginVertical(value);
  }

  static padding(value: string) {
    const instance = new M();
    return instance.padding(value);
  }

  static paddingTop(value: string) {
    const instance = new M();
    return instance.paddingTop(value);
  }

  static paddingRight(value: string) {
    const instance = new M();
    return instance.paddingRight(value);
  }

  static paddingBottom(value: string) {
    const instance = new M();
    return instance.paddingBottom(value);
  }

  static paddingLeft(value: string) {
    const instance = new M();
    return instance.paddingLeft(value);
  }

  static paddingHorizontal(value: string) {
    const instance = new M();
    return instance.paddingHorizontal(value);
  }

  static opacity(value: string) {
    const instance = new M();
    return instance.opacity(value);
  }

  static animation(value: string) {
    const instance = new M();
    return instance.animation(value);
  }

  static fontFamily(value: string) {
    const instance = new M();
    return instance.fontFamily(value);
  }

  static top(value: string) {
    const instance = new M();
    return instance.top(value);
  }

  static left(value: string) {
    const instance = new M();
    return instance.left(value);
  }

  static right(value: string) {
    const instance = new M();
    return instance.right(value);
  }

  static bottom(value: string) {
    const instance = new M();
    return instance.bottom(value);
  }

  static fontSize(value: string) {
    const instance = new M();
    return instance.fontSize(value);
  }

  static textStroke(value: string) {
    const instance = new M();
    return instance.textStroke(value);
  }

  static border(value: string) {
    const instance = new M();
    return instance.border(value);
  }

  static borderLeft(value: string) {
    const instance = new M();
    return instance.borderLeft(value);
  }

  static borderRight(value: string) {
    const instance = new M();
    return instance.borderRight(value);
  }

  static borderTop(value: string) {
    const instance = new M();
    return instance.borderTop(value);
  }

  static borderBottom(value: string) {
    const instance = new M();
    return instance.borderBottom(value);
  }

  static roundCorners(value: string) {
    const instance = new M();
    return instance.roundCorners(value);
  }

  static roundTopLeft(value: string) {
    const instance = new M();
    return instance.roundTopLeft(value);
  }

  static roundTopRight(value: string) {
    const instance = new M();
    return instance.roundTopRight(value);
  }

  static roundBottomLeft(value: string) {
    const instance = new M();
    return instance.roundBottomLeft(value);
  }

  static roundBottomRight(value: string) {
    const instance = new M();
    return instance.roundBottomRight(value);
  }

  static roundTop(value: string) {
    const instance = new M();
    return instance.roundTop(value);
  }

  static roundBottom(value: string) {
    const instance = new M();
    return instance.roundBottom(value);
  }

  static roundLeft(value: string) {
    const instance = new M();
    return instance.roundLeft(value);
  }

  static roundRight(value: string) {
    const instance = new M();
    return instance.roundRight(value);
  }

  static transition(value: string) {
    const instance = new M();
    return instance.transition(value);
  }

  static transform(value: string) {
    const instance = new M();
    return instance.transform(value);
  }

  static alignSelf(value: string) {
    const instance = new M();
    return instance.alignSelf(value);
  }

  static backgroundImage(value: string) {
    const instance = new M();
    return instance.backgroundImage(value);
  }

  static flexWeight(value: string) {
    const instance = new M();
    return instance.flexWeight(value);
  }

  static gap(value: string) {
    const instance = new M();
    return instance.gap(value);
  }

  static boxShadow(value: string) {
    const instance = new M();
    return instance.boxShadow(value);
  }

  static maskImage(value: string) {
    const instance = new M();
    return instance.maskImage(value);
  }

  static objectPosition(value: string) {
    const instance = new M();
    return instance.objectPosition(value);
  }

  static zIndex(value: string) {
    const instance = new M();
    return instance.zIndex(value);
  }

  static backgroundRepeat(value: string) {
    const instance = new M();
    return instance.backgroundRepeat(value);
  }

  static backgroundSize(value: string) {
    const instance = new M();
    return instance.backgroundSize(value);
  }

  static minWidth(value: string) {
    const instance = new M();
    return instance.minWidth(value);
  }

  static maxWidth(value: string) {
    const instance = new M();
    return instance.maxWidth(value);
  }

  static minHeight(value: string) {
    const instance = new M();
    return instance.minHeight(value);
  }

  static maxHeight(value: string) {
    const instance = new M();
    return instance.maxHeight(value);
  }

  static lineHeight(value: string) {
    const instance = new M();
    return instance.lineHeight(value);
  }

  static justifyContent(value: string) {
    const instance = new M();
    return instance.justifyContent(value);
  }

  static filter(value: string) {
    const instance = new M();
    return instance.filter(value);
  }

  static get bold() {
    const instance = new M();
    return instance.bold;
  }

  static get flex() {
    const instance = new M();
    return instance.flex;
  }

  static get justifyStart() {
    const instance = new M();
    return instance.justifyStart;
  }

  static get alignStart() {
    const instance = new M();
    return instance.alignStart;
  }

  static get justifyCenter() {
    const instance = new M();
    return instance.justifyCenter;
  }

  static get alignCenter() {
    const instance = new M();
    return instance.alignCenter;
  }

  static get cicleCorners() {
    const instance = new M();
    return instance.circleCorners;
  }

  static get pointerCursor() {
    const instance = new M();
    return instance.pointerCursor;
  }

  static get disableSelection() {
    const instance = new M();
    return instance.disableSelection;
  }

  static get flexColumn() {
    const instance = new M();
    return instance.flexColumn;
  }

  static get flexRow() {
    const instance = new M();
    return instance.flexRow;
  }

  static get relativePosition() {
    const instance = new M();
    return instance.relativePosition;
  }

  static get borderBox() {
    const instance = new M();
    return instance.borderBox;
  }

  static get block() {
    const instance = new M();
    return instance.block;
  }

  static get inlineBlock() {
    const instance = new M();
    return instance.inlineBlock;
  }

  static get absolutePosition() {
    const instance = new M();
    return instance.absolutePosition;
  }

  static get visible() {
    const instance = new M();
    return instance.visible;
  }

  static get hidden() {
    const instance = new M();
    return instance.hidden;
  }

  static get displayNone() {
    const instance = new M();
    return instance.displayNone;
  }

  static get noPointerEvents() {
    const instance = new M();
    return instance.noPointerEvents;
  }

  static get hideOverflow() {
    const instance = new M();
    return instance.hideOverflow;
  }

  static get noWrap() {
    const instance = new M();
    return instance.noWrap;
  }

  static get flexWrap() {
    const instance = new M();
    return instance.flexWrap;
  }

  static get fillWidth() {
    const instance = new M();
    return instance.fillWidth;
  }

  static get fillHeight() {
    const instance = new M();
    return instance.fillHeight;
  }

  static get fillSize() {
    const instance = new M();
    return instance.fillSize;
  }

  static get textCenter() {
    const instance = new M();
    return instance.textCenter;
  }

  static get justifyEnd() {
    const instance = new M();
    return instance.justifyEnd;
  }

  static get alignEnd() {
    const instance = new M();
    return instance.alignEnd;
  }

  static get alignFlexStart() {
    const instance = new M();
    return instance.alignFlexStart;
  }

  static get alignFlexEnd() {
    const instance = new M();
    return instance.alignFlexEnd;
  }

  static get fitCover() {
    const instance = new M();
    return instance.fitCover;
  }

  static get flexRowReverse() {
    const instance = new M();
    return instance.flexRowReverse;
  }

  static get flexColumnReverse() {
    const instance = new M();
    return instance.flexColumnReverse;
  }

  static get flipX() {
    const instance = new M();
    return instance.flipX;
  }

  width(value: string) {
    this.styles.width = value;
    return this;
  }

  background(value: string) {
    this.styles.background = value;
    return this;
  }

  height(value: string) {
    this.styles.height = value;
    return this;
  }

  size(value: string) {
    this.styles.width = value;
    this.styles.height = value;
    return this;
  }

  color(value: string) {
    this.styles.color = value;
    return this;
  }

  margin(value: string) {
    this.styles.margin = value;
    return this;
  }

  marginLeft(value: string): this {
    this.styles.marginLeft = value;
    return this;
  }

  marginRight(value: string): this {
    this.styles.marginRight = value;
    return this;
  }

  marginTop(value: string): this {
    this.styles.marginTop = value;
    return this;
  }

  marginBottom(value: string): this {
    this.styles.marginBottom = value;
    return this;
  }

  marginHorizontal(value: string): this {
    this.styles.marginLeft = value;
    this.styles.marginRight = value;
    return this;
  }

  marginVertical(value: string): this {
    this.styles.marginTop = value;
    this.styles.marginBottom = value;
    return this;
  }

  padding(value: string) {
    this.styles.padding = value;
    return this;
  }

  paddingTop(value: string) {
    this.styles.paddingTop = value;
    return this;
  }

  paddingRight(value: string) {
    this.styles.paddingRight = value;
    return this;
  }

  paddingBottom(value: string) {
    this.styles.paddingBottom = value;
    return this;
  }

  paddingLeft(value: string) {
    this.styles.paddingLeft = value;
    return this;
  }

  paddingHorizontal(value: string) {
    this.styles.paddingLeft = value;
    this.styles.paddingRight = value;
    return this;
  }

  opacity(value: string) {
    this.styles.opacity = value;
    return this;
  }

  animation(value: string) {
    this.styles.animation = value;
    return this;
  }

  fontFamily(value: string) {
    this.styles.fontFamily = value;
    return this;
  }

  top(value: string) {
    this.styles.top = value;
    return this;
  }

  left(value: string) {
    this.styles.left = value;
    return this;
  }

  right(value: string) {
    this.styles.right = value;
    return this;
  }

  bottom(value: string) {
    this.styles.bottom = value;
    return this;
  }

  fontSize(value: string) {
    this.styles.fontSize = value;
    return this;
  }

  textStroke(value: string) {
    this.styles.WebkitTextStroke = value;
    return this;
  }

  border(value: string) {
    this.styles.border = value;
    return this;
  }

  borderLeft(value: string) {
    this.styles.borderLeft = value;
    return this;
  }

  borderRight(value: string) {
    this.styles.borderRight = value;
    return this;
  }

  borderTop(value: string) {
    this.styles.borderTop = value;
    return this;
  }

  borderBottom(value: string) {
    this.styles.borderBottom = value;
    return this;
  }

  roundCorners(value: string) {
    this.styles.borderRadius = value;
    return this;
  }

  roundTopLeft(value: string) {
    this.styles.borderTopLeftRadius = value;
    return this;
  }

  roundTopRight(value: string): this {
    this.styles.borderTopRightRadius = value;
    return this;
  }

  roundBottomLeft(value: string): this {
    this.styles.borderBottomLeftRadius = value;
    return this;
  }

  roundBottomRight(value: string): this {
    this.styles.borderBottomRightRadius = value;
    return this;
  }

  roundTop(value: string): this {
    this.styles.borderTopLeftRadius = value;
    this.styles.borderTopRightRadius = value;
    return this;
  }

  roundBottom(value: string): this {
    this.styles.borderBottomLeftRadius = value;
    this.styles.borderBottomRightRadius = value;
    return this;
  }

  roundLeft(value: string): this {
    this.styles.borderTopLeftRadius = value;
    this.styles.borderBottomLeftRadius = value;
    return this;
  }

  roundRight(value: string): this {
    this.styles.borderTopRightRadius = value;
    this.styles.borderBottomRightRadius = value;
    return this;
  }

  transition(value: string): this {
    this.styles.transition = value;
    return this;
  }

  transform(value: string): this {
    this.styles.transform = value;
    return this;
  }

  alignSelf(value: string): this {
    this.styles.alignSelf = value;
    return this;
  }

  backgroundImage(value: string): this {
    this.styles.backgroundImage = value;
    return this;
  }

  flexWeight(value: string): this {
    this.styles.flex = value;
    return this;
  }

  gap(value: string): this {
    this.styles.gap = value;
    return this;
  }

  boxShadow(value: string): this {
    this.styles.boxShadow = value;
    return this;
  }

  maskImage(value: string): this {
    this.styles.WebkitMaskImage = value;
    this.styles.maskImage = value;
    return this;
  }

  objectPosition(value: string): this {
    this.styles.objectPosition = value;
    return this;
  }

  zIndex(value: string): this {
    this.styles.zIndex = value;
    return this;
  }

  backgroundRepeat(value: string): this {
    this.styles.backgroundRepeat = value;
    return this;
  }

  backgroundSize(value: string): this {
    this.styles.backgroundSize = value;
    return this;
  }

  minWidth(value: string): this {
    this.styles.minWidth = value;
    return this;
  }

  maxWidth(value: string): this {
    this.styles.maxWidth = value;
    return this;
  }

  minHeight(value: string): this {
    this.styles.minHeight = value;
    return this;
  }

  maxHeight(value: string): this {
    this.styles.maxHeight = value;
    return this;
  }

  lineHeight(value: string): this {
    this.styles.lineHeight = value;
    return this;
  }

  justifyContent(value: string): this {
    this.styles.justifyContent = value;
    return this;
  }

  filter(value: string): this {
    this.styles.filter = value;
    return this;
  }

  get bold() {
    this.styles.fontWeight = 'bold';
    return this;
  }

  get flex() {
    this.styles.display = 'flex';
    return this;
  }

  get justifyStart() {
    this.styles.justifyContent = 'start';
    return this;
  }

  get alignStart() {
    this.styles.alignItems = 'start';
    return this;
  }

  get justifyCenter() {
    this.styles.justifyContent = 'center';
    return this;
  }

  get alignCenter() {
    this.styles.alignItems = 'center';
    return this;
  }

  get circleCorners() {
    this.styles.borderRadius = '50%';
    return this;
  }

  get pointerCursor() {
    this.styles.cursor = 'pointer';
    return this;
  }

  get disableSelection() {
    this.styles.userSelect = 'none';
    this.styles.WebkitUserSelect = 'none';
    this.styles.MozUserSelect = 'none';
    this.styles.msUserSelect = 'none';
    return this;
  }

  get fillSize() {
    this.styles.width = '100%';
    this.styles.height = '100%';
    return this;
  }

  get fillWidth() {
    this.styles.width = '100%';
    return this;
  }

  get fillHeight() {
    this.styles.height = '100%';
    return this;
  }

  get flexColumn() {
    this.styles.display = 'flex';
    this.styles.flexDirection = 'column';
    return this;
  }

  get flexRow() {
    this.styles.display = 'flex';
    this.styles.flexDirection = 'row';
    return this;
  }

  get relativePosition() {
    this.styles.position = 'relative';
    return this;
  }

  get borderBox() {
    this.styles.boxSizing = 'border-box';
    return this;
  }

  get block() {
    this.styles.display = 'block';
    return this;
  }

  get inlineBlock() {
    this.styles.display = 'inline-block';
    return this;
  }

  get absolutePosition() {
    this.styles.position = 'absolute';
    return this;
  }

  get visible() {
    this.styles.visibility = 'visible';
    return this;
  }

  get hidden() {
    this.styles.visibility = 'hidden';
    return this;
  }

  get displayNone() {
    this.styles.display = 'none';
    return this;
  }

  get noPointerEvents() {
    this.styles.pointerEvents = 'none';
    return this;
  }

  get hideOverflow() {
    this.styles.overflow = 'hidden';
    return this;
  }

  get noWrap() {
    this.styles.whiteSpace = 'nowrap';
    return this;
  }

  get flexWrap() {
    this.styles.flexWrap = 'wrap';
    return this;
  }

  get textCenter() {
    this.styles.textAlign = 'center';
    return this;
  }

  get justifyEnd() {
    this.styles.justifyContent = 'end';
    return this;
  }

  get alignEnd() {
    this.styles.alignItems = 'end';
    return this;
  }

  get alignFlexStart() {
    this.styles.alignItems = 'flex-start';
    return this;
  }

  get alignFlexEnd() {
    this.styles.alignItems = 'flex-end';
    return this;
  }

  get fitCover(): this {
    this.styles.objectFit = 'cover';
    return this;
  }

  get flexRowReverse(): this {
    this.styles.display = 'flex';
    this.styles.flexDirection = 'row-reverse';
    return this;
  }

  get flexColumnReverse(): this {
    this.styles.display = 'flex';
    this.styles.flexDirection = 'column-reverse';
    return this;
  }

  get flipX(): this {
    this.styles.transform = 'scaleX(-1)';
    return this;
  }

  css() {
    return this.styles;
  }

  addIf(condition: boolean, callback: (builder: this) => void): this {
    if (condition) {
      callback(this);
    }
    return this;
  }
}
