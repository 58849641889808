import React from 'react';
import {
  CardDatabase,
  CardType,
  CardUiProperties,
  CreatureCard,
  PlayerModifierData,
} from 'tenant-common';
import { M } from '../../uilib/Modifier';
import { useLongPress } from '../../uilib/Hooks';

interface GameHandProps {
  hand: (number | null)[];
  selectedHandIndex: number | null;
  playerModifier?: PlayerModifierData;
  onClick: (index: number) => void;
  onLongPress: (index: number) => void;
}
export function HandWidget({
  hand,
  selectedHandIndex,
  playerModifier,
  onClick,
  onLongPress,
}: GameHandProps): JSX.Element {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  };

  return (
    <div style={containerStyle}>
      {hand.map((id: number | null, index: number) => {
        return (
          <HandCard
            cardId={id}
            onClick={() => onClick(index)}
            onLongPress={onLongPress}
            key={index}
            isSelected={index == selectedHandIndex}
            playerModifier={playerModifier}
          />
        );
      })}
    </div>
  );
}

interface CardProps {
  cardId: number | null;
  isSelected: boolean;
  playerModifier?: PlayerModifierData;
  onClick?: () => void;
  onLongPress?: (id: number) => void;
}
export function HandCard({
  cardId,
  isSelected,
  playerModifier,
  onClick,
  onLongPress,
}: CardProps): JSX.Element {
  if (cardId == null) return <span></span>;
  const cardData = CardDatabase.getCardById(cardId);
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: '55px',
    height: '70px',
    marginRight: '4px',
    borderRadius: '8px',
    transition: 'top 0.1s ease-in-out',
    top: isSelected ? '-8px' : '0px',
    fontFamily: 'Alfa Slab One',
  };

  const imgStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    background: 'white',
    objectFit: 'cover',
    transform: 'scale(1.3)',
    transformOrigin: 'center',
  };

  const overlayStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    width: '20px',
    height: '20px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '0.25rem',
    aspectRatio: '1/1',
    backgroundColor: CardUiProperties.getColorForType(cardData.color), // Semi-transparent background for better readability
    border: '1px solid black',
    borderRadius: '50%',
    color: 'white', // You can customize the text color
    pointerEvents: 'none', // Ensure the overlay does not block the onClick event
  };

  const creatureBase = cardData?.type == CardType.CREATURE ? (cardData as CreatureCard) : null;
  const creaturePower =
    cardData?.type == CardType.CREATURE ? (cardData as CreatureCard).attack : null;

  const longPressEvent = useLongPress(() => onLongPress?.(cardId));

  const baseCost = cardData?.cost ?? 0;
  let cost = cardData?.cost ?? 0;
  if (cardData.type == CardType.SPELL) cost -= playerModifier?.allSpellCostReduction ?? 0;
  if (cost < 0) cost = 0;

  return (
    <div {...longPressEvent} style={containerStyle} onClick={onClick}>
      {cardData ? (
        <>
          <div
            className="pulsing-element2"
            style={M.absolutePosition.fillSize
              .backgroundImage(
                'radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 70%, rgba(0, 0, 0, 0) 100%)',
              )
              .top('-4px')
              .left('-4px')
              .height('78px')
              .width('63px')
              .roundCorners('10px')
              .addIf(!(isSelected ?? true), (b) => b.hidden)
              .css()}
          ></div>
          <div
            style={M.fillHeight.fillWidth.hideOverflow
              .border(`3px solid ${CardUiProperties.getColorForType(cardData.color)}`)
              .borderBox.roundCorners('8px')
              .css()}
          >
            <img
              style={imgStyle}
              src={`/images/cardart/${cardData.id}.png`}
              alt={cardData.id.toString()}
            />
          </div>
          <div
            style={M.relativePosition.flexRow
              .bottom('14px')
              .left('-2px')
              .bold.color('white')
              .fontSize('12px')
              .textStroke('0.5px black')
              .css()}
          >
            <span>{`${creaturePower !== null ? creaturePower.toString()?.padStart(4, '0') : ''}${
              creatureBase && creatureBase.showPlusPower ? '+' : ''
            }`}</span>
          </div>
          <div style={overlayStyle}>
            <span
              style={M.absolutePosition
                .top('-1px')
                .borderBox.size('20px')
                .color(
                  cost < baseCost
                    ? CardUiProperties.BUFF
                    : cost > baseCost
                    ? CardUiProperties.DEBUFF
                    : 'white',
                )
                .textCenter.textStroke('0.75px black')
                .css()}
            >
              {cost}
            </span>
          </div>
        </>
      ) : (
        <span></span>
      )}
    </div>
  );
}
