import React, { useState } from 'react';
import { GameBoardPanel } from '../board/GameBoardPanel';
import { CardInstanceData, CombatantData, Field1v1Data, FullData, GamePhase } from 'tenant-common';
import { PlayerModifierData } from 'tenant-common/dist/game/data/ModifierData';

export function DebugGameAreaContent(): JSX.Element {
  const [selectedHandIndex, setSelectedHandIndex] = useState<number | null>(null);
  const [cardPreviewId, setCardPreviewId] = useState<number | null>(null);
  // const revealActionsRequest = {
  //   player1Action: {
  //     playerId: 'NIC',
  //     type: ActionType.MANA_ACTION,
  //     cardId: 3,
  //   },
  //   player2Action: {
  //     playerId: 'JAM',
  //     type: ActionType.PLAY_ACTION,
  //     cardId: 1,
  //   },
  // };
  const revealActionsRequest = null;

  const user: CombatantData = {
    player: {
      username: 'NIC',
      life: 20,
      currentMana: 4,
      maxMana: 7,
      modifier: { allSpellCostReduction: 1 } as PlayerModifierData,
      manaPool: [true, true, true, false, false, false],
    },
    board: new Field1v1Data([null, null, null], [new CardInstanceData(1), null, null]),
    hand: [4, 29, null, 53, 44],
    deckSize: 14,
    handSize: 4,
    hasPlayedManaThisTurn: true,
  };

  const opponent: CombatantData = {
    player: {
      username: 'JAM',
      life: 18,
      currentMana: 3,
      maxMana: 6,
      manaPool: [true, true, false, false, false, false],
    },
    board: new Field1v1Data([null, null, null], [new CardInstanceData(1), null, null]),
    hand: [1, 29, 1, 2, 3],
    deckSize: 12,
    handSize: 2,
    hasPlayedManaThisTurn: true,
  };

  return (
    <GameBoardPanel
      revealActionsRequest={revealActionsRequest}
      gamePhase={GamePhase.STARTING_TURN}
      onClickTile={() => console.log('clicked')}
      onSkip={() => console.log('clicked')}
      onLongPressCard={(id) => setCardPreviewId(id)}
      cardPreviewId={cardPreviewId}
      onDismissCardPreview={() => setCardPreviewId(null)}
      hasSkipped={false}
      hasPlayedMana={false}
      onClickAddMana={() => console.log('clicked')}
      selectedHandIndex={selectedHandIndex}
      onChangeHandSelection={(index) =>
        setSelectedHandIndex((prev) => (prev == index ? null : index))
      }
      state={
        {
          turn: 3,
          user: user,
          opponent: opponent,
        } as FullData
      }
    />
  );
}
