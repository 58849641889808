import React from 'react';
import { DebugGameAreaContent } from './DebugGameAreaContent';
import { M } from '../../../uilib/Modifier';

export function DebugGameAreaPhone(): JSX.Element {
  return (
    <div style={M.background('#333333').fillSize.flex.justifyCenter.paddingTop('20px').css()}>
      <div
        style={M.flexColumn.borderBox
          .width('360px')
          .height('640px')
          .border('1px magenta solid')
          .bold.textStroke('0.5px black')
          .css()}
      >
        <DebugGameAreaContent />
      </div>
    </div>
  );
}
