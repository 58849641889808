import React from 'react';
import { M } from '../../uilib/Modifier';

interface DeckTileProps {
  coverImageId: number;
  name: string;
  color: string;
  selected: boolean;
  onClick: () => void;
}
export function DeckTile({
  coverImageId,
  name,
  color,
  selected,
  onClick,
}: DeckTileProps): JSX.Element {
  const style: React.CSSProperties = {
    borderRadius: '24px',
    background: color,
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: selected ? '10px #bf52ff solid' : '10px white solid',
  };

  const imageStyle: React.CSSProperties = {
    height: '100px',
  };
  return (
    <div style={style} onClick={onClick}>
      <img
        style={imageStyle}
        src={`/images/cardart/${coverImageId}.png`}
        alt="Tile Image"
        className="tile-image"
      ></img>
      <p style={M.color('white').textCenter.textStroke('0').css()}>
        <b>{name}</b>
      </p>
    </div>
  );
}
