import { Auth } from './Auth';
import { Chat } from './Chat';
import { JoinRoom } from './JoinRoom';

export class Payload {
  static parseAuth(payload: string): Auth | null {
    try {
      const auth: Auth = JSON.parse(payload) as Auth;
      return auth;
    } catch (error) {
      console.error('AUTH parsing error: ', error);
    }
    return null;
  }

  static parseChat(payload: string): Chat | null {
    try {
      const chat: Chat = JSON.parse(payload) as Chat;
      return chat;
    } catch (error) {
      console.error('CHAT parsing error: ', error);
    }
    return null;
  }

  static parseJoinRoom(payload: string): JoinRoom | null {
    try {
      const joinRoom: JoinRoom = JSON.parse(payload) as JoinRoom;
      return joinRoom;
    } catch (error) {
      console.error('JOIN_ROOM parsing error: ', error);
    }
    return null;
  }
}
