import { Game } from './Game';
import { CardColor, CardPermanentInstance, CardSpellInstance, PermanentCard } from './card';
import { Deck } from './stack/Deck';
import { Hand } from './stack/Hand';
import { PlaySide } from './PlaySide';
import { Player, PlayerInstance } from './player';
import { CardAction, MakeManaAction, PlayPermanentAction, PlaySpellAction } from './Request';
import { CombatantData } from './data';
import { TenantResponseCode } from './TenantResponseCodes';
import { PlayerModifierSet } from './player/PlayerModifier';
import { Effect } from './domain';
import { RulesUtil } from './util';

export class Combatant {
  private _queuedAction: CardAction | null = null;
  private _passingAction = true;
  private _hasManuallySkipped = false;
  private _hasPlayedManaThisTurn = false;

  constructor(
    public readonly player: PlayerInstance,
    public readonly board: PlaySide,
    public readonly hand: Hand,
    public readonly deck: Deck,
  ) {}

  get id(): string {
    return this.player.base.id;
  }

  get isDead(): boolean {
    return this.player.life <= 0;
  }

  get queuedAction(): CardAction | null {
    return this._queuedAction ? { ...this._queuedAction } : null;
  }

  get hasPassedAction(): boolean {
    return this._passingAction;
  }

  get hasManuallySkipped(): boolean {
    return this._hasManuallySkipped;
  }

  canAnythingAttack(): boolean {
    return this.board.canAnythingAttack();
  }

  canActionBeTaken(): boolean {
    // TODO check for mana if hand size is greater than 0
    return !this._hasPlayedManaThisTurn || this.hand.canPlayAnything(this.player.mana);
  }

  setHasMadeAction() {
    this._passingAction = false;
  }

  setHasManuallySkipped() {
    this._hasManuallySkipped = true;
  }

  removeExhaustion() {
    this.board.onAll((card) => {
      if (card !== null) {
        card.resetExhaustion();
        card.resetStunned();
      }
    });
  }

  enqueuePermanentRequest(request: PlayPermanentAction): number {
    if (this._queuedAction != null) return TenantResponseCode.ALREADY_QUEUED;
    this.setHasMadeAction();
    this._queuedAction = request;
    return TenantResponseCode.OK;
  }

  enqueueSpellRequest(request: PlaySpellAction): number {
    if (this._queuedAction != null) return TenantResponseCode.ALREADY_QUEUED;
    this.setHasMadeAction();
    this._queuedAction = request;
    return TenantResponseCode.OK;
  }

  enqueueMakeMana(request: MakeManaAction): number {
    if (
      this._queuedAction != null ||
      this._hasPlayedManaThisTurn ||
      !(this.player.modifier.canAddMana ?? true)
    ) {
      return TenantResponseCode.CANT_PLAY_MANA;
    }
    this.setHasMadeAction();
    this._hasPlayedManaThisTurn = true;
    this._queuedAction = request;
    return TenantResponseCode.OK;
  }

  resetPassingAction() {
    this._passingAction = true;
    this._hasManuallySkipped = false;
  }

  resetNewTurn() {
    this._hasPlayedManaThisTurn = false;
  }

  clearQueuedAction() {
    this._queuedAction = null;
    this._hasManuallySkipped = false;
  }

  gainTempMana(amount: number) {
    this.player.mana.gainTemporaryMana(amount);
  }

  gainMaxMana(color: CardColor) {
    this.player.mana.gainMaxMana(color);
  }

  loseMaxMana() {
    this.player.mana.loseMaxMana();
  }

  refillMana() {
    this.player.mana.refill();
  }

  spendMana(amount: number = 1) {
    this.player.mana.spendMana(amount);
  }

  canPlay(card: CardPermanentInstance, x: number, y: number, discount: number): boolean {
    return RulesUtil.canAffordCard(card.id, this.player.mana, discount) && this.board.isEmpty(x, y);
  }

  canPlaySpell(card: CardSpellInstance, x: number, y: number, discount: number): boolean {
    const realDiscount = discount + (this.player.modifier.allSpellCostReduction ?? 0);
    const cost = realDiscount < 0 ? 0 : card.base.cost - realDiscount;
    return this.player.mana.currentMana >= cost;
  }

  play(card: CardPermanentInstance, x: number, y: number, discount: number): Effect[] {
    const cost = discount < 0 ? 0 : card.cost - discount;
    this.spendMana(cost);
    return this.board.play(card, x, y);
  }

  playSpell(
    card: CardSpellInstance,
    x: number,
    y: number,
    localTarget: boolean,
    target: CardPermanentInstance | null,
    discount: number,
  ): Effect[] {
    const realDiscount = discount + (this.player.modifier.allSpellCostReduction ?? 0);
    const cost = realDiscount < 0 ? 0 : card.base.cost - realDiscount;
    this.spendMana(cost);
    return this.board.playSpell(card, x, y, localTarget, target);
  }

  draw() {
    const c = this.deck.draw();
    if (c !== null) {
      this.hand.draw(c);
    }
  }

  drawCard(id: number) {
    this.hand.draw(id);
  }

  drawTillFull() {
    do {
      const c = this.deck.draw();
      if (c !== null) {
        this.hand.draw(c);
      } else break;
    } while (this.hand.hasRoomTillStartingCount);
  }

  returnToHand(x: number, y: number) {
    const c = this.board.get(x, y);
    this.board.set(x, y, null);
    if (c !== null) {
      this.hand.draw(c.id);
    }
  }

  setPlayerModifier(modifierSet: PlayerModifierSet) {
    this.player.setModifier(modifierSet);
  }

  transform(x: number, y: number, newCard: PermanentCard) {
    const card = this.board.get(x, y);
    if (card) {
      this.board.set(x, y, new CardPermanentInstance(newCard));
    }
  }

  getPermanent(x: number, y: number): CardPermanentInstance | null {
    return this.board.get(x, y);
  }

  asData(): CombatantData {
    return {
      player: this.player.asData(),
      board: this.board.asData(),
      hand: this.hand.asData(),
      deckSize: this.deck.length,
      handSize: this.hand.length,
      hasPlayedManaThisTurn: this._hasPlayedManaThisTurn,
    };
  }

  static fromBasePlayer(player: Player, deck: Deck, battlefield: Game): Combatant {
    return new Combatant(
      new PlayerInstance(player),
      new PlaySide(player.id, battlefield),
      new Hand(),
      deck,
    );
  }
}
