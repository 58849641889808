import React, { useEffect, useState } from 'react';
import {
  AttackPattern,
  CardDatabase,
  CardInstanceData,
  CardType,
  CardUiProperties,
  CreatureCard,
  MathUtil,
  MonumentCard,
} from 'tenant-common';
import { M } from '../../uilib/Modifier';
import { useLongPress } from '../../uilib/Hooks';

interface PlayTileProps {
  card: CardInstanceData | null;
  isEnemy: boolean;
  attackTarget?: AttackPattern | null;
  onClick?: () => void;
  onLongPress?: () => void;
}
export function PlayTile({
  card,
  isEnemy,
  attackTarget,
  onClick,
  onLongPress,
}: PlayTileProps): JSX.Element {
  return card == null ? (
    <PlayTile_Empty onClick={onClick} />
  ) : (
    <PlayTile_Card
      card={card}
      isEnemy={isEnemy}
      attackTarget={attackTarget ?? null}
      onClick={onClick}
      onLongPress={onLongPress}
    />
  );
}

interface PlayTileEmptyProps {
  onClick?: () => void;
}
function PlayTile_Empty({ onClick }: PlayTileEmptyProps): JSX.Element {
  return (
    <div
      className="supertilenull"
      style={{ background: 'white', opacity: '0.6' }}
      onClick={onClick}
    ></div>
  );
}

interface PlayTileCardProps {
  card: CardInstanceData;
  isEnemy: boolean;
  attackTarget: AttackPattern | null;
  onClick?: () => void;
  onLongPress?: () => void;
}
function PlayTile_Card({
  card,
  isEnemy,
  attackTarget,
  onClick,
  onLongPress,
}: PlayTileCardProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const base = CardDatabase.getCardById(card.id);
  const typedBase = base as CreatureCard;
  // TODO split monument
  const monumentTypedBase = base as MonumentCard;
  const isShielded = card.isShielded;
  const usedEnergy = card.usedEnergy ?? 0;
  const energyArray: number[] =
    monumentTypedBase.maxEnergy && monumentTypedBase.maxEnergy >= 0
      ? Array(monumentTypedBase.maxEnergy - usedEnergy)
          .fill(1)
          .concat(Array(usedEnergy).fill(-1))
      : [];

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const attackDisabled = typedBase.attackDisabled || !(card.modifier?.canAttack ?? true);
  const willAttack =
    base.type != CardType.MONUMENT &&
    !card.isFrontendPreview &&
    !card.isExhausted &&
    !card.isStunned &&
    !attackDisabled;
  const powerModifier = card.modifier?.power ?? 0;
  const power = MathUtil.clamp(typedBase.attack + powerModifier, 0)
    .toString()
    .padStart(4, '0');
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const longPressEvent = useLongPress(onLongPress ? onLongPress : () => {});
  const attackAnimation =
    attackTarget == null
      ? null
      : (isEnemy ? 'ENEMY' : 'ALLY') +
        '_ATTACK_VECTOR_' +
        attackTarget.attackerPosition +
        '_' +
        (attackTarget.targetPosition == null
          ? 'DIRECT'
          : '_to_' +
            attackTarget.targetPosition +
            '_' +
            (attackTarget.opposed ? '' : 'UN') +
            'OPPOSED');
  return (
    <div style={M.relativePosition.css()}>
      <div
        className="supertilenull"
        style={M.absolutePosition.top('0px').left('0px').background('white').opacity('0.6').css()}
        onClick={onClick}
      ></div>
      <div
        style={M.fontFamily('Alfa Slab One')
          .relativePosition.height('75px')
          .top('0px')
          .margin('4px')
          .borderBox.addIf(isVisible && !card.isFrontendPreview, (b) => {
            return b
              .opacity('0')
              .animation(`fadeInAnimation${isEnemy ? 'Down' : 'Up'} 0.5s forwards`);
          })
          .addIf(isVisible && attackAnimation != null, (b) => {
            return b.animation(`${attackAnimation} 1s`);
          })
          .addIf(card.isFrontendPreview ?? false, (b) => b.opacity('0.25'))
          .transition('top 0.5s ease')
          .css()}
        onClick={onClick}
        {...longPressEvent}
      >
        <div className="tile">
          <div style={M.relativePosition.inlineBlock.css()}>
            <img
              src={`/images/cardart/${card?.id ?? 0}.png`}
              alt="Tile Image"
              style={M.fillWidth //.fillHeight //.fitCover
                // .objectPosition('center -25%;')
                .transform('translateY(-9%)')
                .css()}
            ></img>
            <div
              style={M.absolutePosition.fillSize
                .background('black')
                .top('0px')
                .left('0px')
                .opacity('0.35')
                .addIf(!card.isExhausted && !card.isStunned, (b) => b.hidden)
                .css()}
            ></div>
            <div
              style={M.absolutePosition.fillSize.flex.justifyCenter
                .background('#6ba6ff')
                .top('0px')
                .left('0px')
                .opacity('0.55')
                .addIf(!card.isStunned, (b) => b.hidden)
                .css()}
            >
              <img
                src="/images/spiral.png"
                style={M.relativePosition
                  .top('10px')
                  .opacity('0.3')
                  .filter('invert(100%)')
                  .animation('rotate 3s linear infinite')
                  .size('50px')
                  .css()}
              />
            </div>
          </div>
        </div>
        {isShielded && (
          <div
            className="pulsing-element"
            style={M.absolutePosition.fillSize
              .animation('lightPulse 3s infinite')
              .backgroundImage(
                `radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, ${CardUiProperties.SHIELD} 80%, rgba(0,0,0,0) 100%)`,
              )
              .top('0px')
              .left('0px')
              .fillHeight.width('100px')
              .roundTop('10px')
              .css()}
          ></div>
        )}
        {!isEnemy ? (
          <div
            className="pulsing-element"
            style={M.absolutePosition.fillSize
              .backgroundImage('linear-gradient(to bottom, white, rgba(0, 0, 0, 0))')
              .top('0px')
              .left('0px')
              .height('28px')
              .width('100px')
              .roundTop('10px')
              .addIf(!(willAttack ?? true), (b) => b.hidden)
              .css()}
          ></div>
        ) : (
          <div
            className="pulsing-element"
            style={M.absolutePosition.fillSize
              .backgroundImage('linear-gradient(to top, white, rgba(0, 0, 0, 0))')
              .bottom('0px')
              .left('0px')
              .height('28px')
              .width('100px')
              .roundBottom('10px')
              .addIf(!(willAttack ?? true), (b) => b.hidden)
              .css()}
          ></div>
        )}
        {base.type == CardType.CREATURE && (
          <div
            style={M.relativePosition.flexRow
              .bottom('20px')
              .left('-2px')
              .bold.color(
                powerModifier < 0
                  ? CardUiProperties.DEBUFF
                  : powerModifier > 0
                  ? CardUiProperties.BUFF
                  : 'white',
              )
              .opacity(card.isStunned ? '0.4' : '1')
              .fontSize('20px')
              .textStroke('0.75px black')
              .css()}
          >
            <span>{`${power}${typedBase.showPlusPower ? '+' : ''}`}</span>
            <div style={M.flexWeight('1').css()}></div>
            <AttributeBar
              attackDisabled={attackDisabled}
              isRanged={typedBase.isRanged}
              isDeadly={typedBase.isDeadly || (card.modifier?.isDeadly ?? false)}
              disableStun={card.modifier?.canBeStunned == false}
              disableExhaust={card.modifier?.canBeExhausted == false}
              disableSpellTarget={card.modifier?.canBeSpellTargeted == false}
            />
          </div>
        )}
        {base.type == CardType.MONUMENT &&
          monumentTypedBase.maxEnergy &&
          monumentTypedBase.maxEnergy >= 0 && (
            <>
              <div
                style={M.absolutePosition.fillSize
                  .backgroundImage('linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))')
                  .bottom('0px')
                  .left('0px')
                  .height('20px')
                  .width('100px')
                  .roundBottom('10px')
                  .css()}
              ></div>
              <div
                style={M.relativePosition.flexRow
                  .bottom('14px')
                  .left('15px')
                  .width('70px')
                  .hideOverflow.borderBox.height('16px')
                  .background('black')
                  .roundCorners('8px')
                  .border('2px #111 solid')
                  .css()}
              >
                {energyArray.map((n, index) =>
                  n == 1 ? (
                    <div
                      key={index}
                      style={M.flexWeight('1')
                        // .background('#f6ff00')
                        .backgroundImage('linear-gradient(to bottom, #f6ff00 75%, #9ba100 75%)')
                        .roundCorners('3px')
                        .addIf(index > 0, (b) => b.marginLeft('2px'))
                        .css()}
                    ></div>
                  ) : (
                    <div
                      key={index}
                      style={M.flexWeight('1')
                        .background('#333')
                        .roundCorners('3px')
                        .marginLeft('2px')
                        .css()}
                    ></div>
                  ),
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
}

interface AttributeBarProps {
  attackDisabled: boolean;
  isRanged: boolean;
  isDeadly: boolean;
  disableStun: boolean;
  disableExhaust: boolean;
  disableSpellTarget: boolean;
}
function AttributeBar({
  attackDisabled,
  isRanged,
  isDeadly,
  disableStun,
  disableExhaust,
  disableSpellTarget,
}: AttributeBarProps): JSX.Element {
  if (
    !attackDisabled &&
    !isRanged &&
    !isDeadly &&
    !disableStun &&
    !disableExhaust &&
    !disableSpellTarget
  )
    return <></>;
  return (
    <div
      style={M.flexColumn.alignFlexStart.absolutePosition
        .justifyContent('flex-end')
        .bottom('12px')
        .right('-6px')
        .background('rgba(0, 0, 0, 0.25)')
        .roundCorners('12px')
        .borderBox.paddingLeft('1px')
        .paddingRight('1px')
        .paddingTop('2px')
        .paddingBottom('2px')
        .css()}
    >
      <div style={M.flexColumn.alignFlexStart.css()}>
        {disableExhaust && <img style={M.size('18px').css()} src="/images/noexhausticon.png"></img>}
        {disableStun && <img style={M.size('18px').css()} src="/images/nostunicon.png"></img>}
        {disableSpellTarget && (
          <img style={M.size('18px').css()} src="/images/nospellicon.png"></img>
        )}
        {attackDisabled && <img style={M.size('18px').css()} src="/images/noattackicon.png"></img>}
        {isRanged && <img style={M.size('18px').css()} src="/images/rangedicon.png"></img>}
        {isDeadly && <img style={M.size('18px').css()} src="/images/deadlyicon.png"></img>}
      </div>
    </div>
  );
}
