import React from 'react';
import {
  Attribute,
  CardDatabase,
  CardType,
  CardUiProperties,
  CopyDatabase,
  CreatureCard,
  MonumentCard,
} from 'tenant-common';
import { M } from '../../uilib/Modifier';
import { useFitText } from '../../uilib/Hooks';

interface FullCardProps {
  id: number;
}
export function FullCard({ id }: FullCardProps): JSX.Element {
  const { ref, fontSize } = useFitText(14); // Adjust max font size as needed

  const base = CardDatabase.getCardById(id);
  const creatureBase = base as CreatureCard;
  const monumentTypedBase = base as MonumentCard;
  const usedEnergy = 0;
  const energyArray: number[] =
    monumentTypedBase.maxEnergy && monumentTypedBase.maxEnergy >= 0
      ? Array(monumentTypedBase.maxEnergy - usedEnergy)
          .fill(1)
          .concat(Array(usedEnergy).fill(-1))
      : [];

  let attributes = CardType[base.type].toString().toUpperCase();
  base.attributes?.forEach((attr) => (attributes += ' • ' + Attribute[attr].toString()));

  return (
    <div
      style={M.relativePosition
        .width('200px')
        .height('280px')
        .roundCorners('10px')
        .zIndex('10')
        .background('black')
        .borderBox.border('8px black solid')
        .css()}
    >
      {/* Card Art */}
      <img
        src={`/images/cardart/${id ?? 0}.png`}
        alt="Tile Image"
        style={M.absolutePosition.width('100%').roundTop('6px').css()}
      ></img>

      {/* Bottom Bar */}
      <div
        style={M.absolutePosition.borderBox
          .width('100%')
          .height('28px')
          .bottom('0px')
          // .boxShadow('0 0 10px rgba(0, 0, 0, 0.8)')
          .borderBox.borderTop('2.5px solid rgba(255,255,255,0.3)')
          .roundBottom('6px')
          .hideOverflow.background(CardUiProperties.getColorForType(base.color))
          .css()}
      >
        <div
          style={M.fillSize
            .opacity('0.03')
            .backgroundRepeat('repeat')
            .backgroundImage("url('/images/checker.png')")
            .backgroundSize('150px')
            .css()}
        ></div>
      </div>
      {creatureBase !== undefined && (
        <div
          style={M.absolutePosition
            .fontSize('24px')
            .fontFamily('Alfa Slab One')
            .bottom('-4px')
            .left('4px')
            .color('white')
            .bold.textStroke('0.05rem black')
            .css()}
        >
          {creatureBase.attack?.toString()?.padStart(4, '0')}
          {creatureBase.showPlusPower ? '+' : ''}
        </div>
      )}

      {/* Name Bar */}
      <div
        style={M.absolutePosition.borderBox
          .width('100%')
          .height('32px')
          // .background('red')
          .top('0px')
          .css()}
      >
        <div
          style={M.width('100%')
            .paddingLeft('46px')
            .fontFamily('Michroma')
            .color('white')
            .noWrap.borderBox.height('100%')
            .background('rgba(0,0,0,0.5)')
            .flex.justifyCenter.alignCenter.css()}
        >
          <div
            ref={ref}
            style={M.width('132px')
              .flex.alignCenter.textCenter.height('18px')
              .fontSize(`${fontSize}px`)
              .lineHeight(`${fontSize}px`)
              .paddingBottom('1px')
              .textStroke('0')
              // .fontSize('22px')
              .css()}
          >
            <span>{CopyDatabase.getNameById(id)}</span>
          </div>
        </div>
      </div>

      {/* Cost Bubble */}
      <div
        style={M.absolutePosition.circleCorners
          .size('38px')
          .background(CardUiProperties.getColorForType(base.color))
          .top('-2px')
          .left('0px')
          .borderBox.border('1.5px black solid')
          .css()}
      ></div>
      <div
        style={M.absolutePosition.flex.alignCenter.justifyCenter
          .size('38px')
          .fontSize('28px')
          .fontFamily('Alfa Slab One')
          .top('-1px')
          .left('0px')
          .color('white')
          .textCenter.bold.textStroke('0.05rem black')
          .css()}
      >
        <span>{base.cost}</span>
      </div>

      {/* Card Text */}
      {CopyDatabase.getTextById(id).trim() != '' && (
        <div
          style={M.absolutePosition.borderBox
            .width('100%')
            .paddingLeft('12px')
            .paddingRight('12px')
            .bottom('42px')
            .css()}
        >
          <div
            style={M.width('100%')
              .fontSize('10.5px')
              .fontFamily('sans-serif')
              .color('white')
              .padding('8px')
              .roundCorners('8px')
              .borderBox.height('100%')
              .minHeight('48px')
              .textStroke('0')
              .background('rgba(0, 0, 0, 0.7)')
              .flex.justifyCenter.alignCenter.css()}
          >
            <span dangerouslySetInnerHTML={{ __html: CopyDatabase.getTextById(id) }} />
          </div>
        </div>
      )}

      {/* Attribute Bar */}
      {true && (
        <div style={M.absolutePosition.borderBox.width('100%').bottom('28px').css()}>
          <div
            style={M.width('100%')
              .bold.fontSize('7px')
              .fontFamily('sans-serif')
              .color('white')
              .padding('2px')
              .borderBox.height('100%')
              .height('10px')
              .textStroke('0')
              .background('rgba(0, 0, 0, 0.85)')
              .flex.justifyCenter.alignCenter.css()}
          >
            <span>{attributes}</span>
          </div>
        </div>
      )}

      {/* Monument Bar */}
      {base.type == CardType.MONUMENT &&
        monumentTypedBase.maxEnergy &&
        monumentTypedBase.maxEnergy >= 0 && (
          <>
            <div
              style={M.absolutePosition.flexRow
                .bottom('2px')
                .left('4px')
                .width('90px')
                .hideOverflow.borderBox.height('21px')
                .background('black')
                .roundCorners('8px')
                .border('3px #111 solid')
                .css()}
            >
              {energyArray.map((n, index) =>
                n == 1 ? (
                  <div
                    key={index}
                    style={M.flexWeight('1')
                      // .background('#f6ff00')
                      .backgroundImage('linear-gradient(to bottom, #f6ff00 65%, #9ba100 65%)')
                      .roundCorners('3px')
                      .addIf(index > 0, (b) => b.marginLeft('2px'))
                      .css()}
                  ></div>
                ) : (
                  <div
                    key={index}
                    style={M.flexWeight('1')
                      .background('#333')
                      .roundCorners('3px')
                      .marginLeft('2px')
                      .css()}
                  ></div>
                ),
              )}
            </div>
          </>
        )}
    </div>
  );
}
