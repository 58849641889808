import { TLog } from '../../util';
import { Game } from '../Game';
import { Player } from '../player';
import {
  CardAction,
  CardActionType,
  MakeManaAction,
  PlayPermanentAction,
  PlaySpellAction,
} from '../Request';
import { TenantResponseCode } from '../TenantResponseCodes';
import { CardEffectContext, CardPermanentInstance } from '../card';
import { FullData } from '../data/FullData';
import { ActionType, ActionUtils, RevealedAction } from '../network';
import { GamePhase } from './GamePhase';
import { ManaActionNeo, PlayActionNeo, UserActionNeo } from './UserActionNeo';

export interface GameDriver {
  get currentPhase(): GamePhase;

  advance(): GamePhase | null;

  takeAction(action: UserActionNeo): number;

  enqueuePlay(
    playerId: string,
    handIndex: number,
    x: number,
    y: number,
    localTarget: boolean,
  ): number;

  enqueueMakeMana(playerId: string, handIndex: number): number;

  skip(playerId: string): number;

  disqualify(username: string): void;

  beforeChangePhase(newPhase: GamePhase): void;
  changePhase(newPhase: GamePhase): void;

  asData(playerId: string): FullData | null;

  get isDone(): boolean;
}

export abstract class BaseGameDriver implements GameDriver {
  protected phase: GamePhase = GamePhase.WAITING_TO_START;
  game: Game;

  constructor(player1Username: string, player2Username: string, deck1: number[], deck2: number[]) {
    this.game = new Game(new Player(player1Username), new Player(player2Username), deck1, deck2);
  }

  get currentPhase(): GamePhase {
    return this.phase;
  }

  get isDone(): boolean {
    return this.phase == GamePhase.GAME_OVER;
  }

  abstract beforeChangePhase(newPhase: GamePhase): void;
  abstract advance(): GamePhase | null;

  private startOfTurnActions() {
    TLog.v(`GameDriver::startOfTurnActions`);
    this.game.onAllPermanents((card: CardPermanentInstance | null) => {
      card?.countdownEffect();
    });
    this.game.onAllPermanents(
      (card: CardPermanentInstance | null, ownerId: string, x: number, y: number) => {
        if (card && card.isReadyForTimeEffect) {
          const context = {
            battlefield: this.game,
            instance: card,
            ownerId: ownerId,
            x: x,
            y: y,
          } as CardEffectContext;
          card.onTimeEffectTriggered?.(context);
        }
      },
    );
  }

  private startOfCombatActions() {
    TLog.v(`GameDriver::startOfCombatActions`);
    this.game.onCombatEffects();
  }

  resolveQueuedActions(): (RevealedAction | null)[] {
    TLog.v(`GameDriver::resolveQueuedActions`);
    const actions = this.game.popQueuedActions();
    const resolvedActions: (RevealedAction | null)[] = actions.map((action) => {
      return action
        ? ({
            playerId: action.playerId,
            cardId: this.game.getPlayerById(action.playerId)?.hand.get(action.handIndex),
            type: ActionUtils.cardActionToAction(action.type),
          } as RevealedAction)
        : null;
    });
    // TODO check the response codes, send back animation objects to tell the client what to do, or just send the actions?
    // TODO Maybe just test with MakeMana for now
    actions
      .filter((item): item is CardAction => item !== null)
      .forEach((queuedAction) => {
        switch (queuedAction.type) {
          case CardActionType.PLAY_PERMANENT:
            this.game.play(queuedAction as PlayPermanentAction);
            break;
          case CardActionType.PLAY_SPELL:
            this.game.play(queuedAction as PlaySpellAction);
            break;
          case CardActionType.MAKE_MANA:
            this.game.makeMana(queuedAction as MakeManaAction);
            break;
        }
      });
    return resolvedActions;
  }

  processPipeline() {
    TLog.v(`GameDriver::processPipeline`);
    this.game.processPipelineAdNauseum();
  }

  takeAction(action: UserActionNeo): number {
    TLog.v(`GameDriver::takeAction - userId: ${action.userId}, type: ${ActionType[action.type]}`);
    if (action.type == ActionType.MANA_ACTION) {
      const typed = action as ManaActionNeo;
      return this.enqueueMakeMana(action.userId, typed.handIndex);
    } else if (action.type == ActionType.PLAY_ACTION) {
      const typed = action as PlayActionNeo;
      return this.enqueuePlay(typed.userId, typed.handIndex, typed.x, typed.y, true);
    } else if (action.type == ActionType.PASS) {
      return this.skip(action.userId);
    }
    return TenantResponseCode.ERROR;
  }

  enqueuePlay(
    playerId: string,
    handIndex: number,
    x: number,
    y: number,
    localTarget: boolean,
  ): number {
    if (this.phase != GamePhase.AWAITING_ROUND_ACTION) {
      TLog.v(`GameDriver::enqueuePlay - ERROR: phase is not AWAITING_ROUND_ACTION`);
      return TenantResponseCode.WRONG_PHASE;
    }
    TLog.v(
      `GameDriver::enqueuePlay - playerId: ${playerId}, handIndex: ${handIndex}, x: ${x}, y: ${y}, localTarget: ${localTarget}`,
    );
    return this.game.enqueuePlay(playerId, handIndex, x, y, localTarget);
  }

  enqueueMakeMana(playerId: string, handIndex: number): number {
    if (this.phase != GamePhase.AWAITING_ROUND_ACTION) {
      TLog.v(`GameDriver::enqueueMakeMana - ERROR: phase is not AWAITING_ROUND_ACTION`);
      return TenantResponseCode.WRONG_PHASE;
    }
    TLog.v(`GameDriver::enqueueMakeMana - playerId: ${playerId}, handIndex: ${handIndex}`);
    return this.game.enqueueMakeMana(playerId, handIndex);
  }

  skip(playerId: string): number {
    if (this.phase != GamePhase.AWAITING_ROUND_ACTION) {
      TLog.v(`GameDriver::skip - ERROR: phase is not AWAITING_ROUND_ACTION`);
      return TenantResponseCode.WRONG_PHASE;
    }
    TLog.v(`GameDriver::skip - playerId: ${playerId}`);
    return this.game.skip(playerId);
  }

  disqualify(username: string) {
    console.log(`${username} was disqualified!`);
    this.phase = GamePhase.GAME_OVER;
  }

  changePhase(newPhase: GamePhase) {
    this.phase = newPhase;

    // Start of phase effects
    switch (newPhase) {
      case GamePhase.STARTING_TURN:
        this.game.resetNewTurn();
        this.game.recalculateBoardState();
        break;
      case GamePhase.START_OF_TURN_EFFECTS:
        this.startOfTurnActions();
        this.game.recalculateBoardState();
        break;
      case GamePhase.AWAITING_ROUND_ACTION:
        this.game.recalculateBoardState();
        this.game.resetPassingActions();
        break;
      case GamePhase.COMBAT:
        // TODO this is overzealous?
        this.game.recalculateBoardState();
        this.startOfCombatActions();
        this.game.recalculateBoardState();
        break;
      case GamePhase.ANIMATING_ATTACKS:
        this.game.performAttacks();
        this.game.recalculateBoardState();
        break;
    }
  }

  asData(playerId: string): FullData | null {
    const data = this.game.asData(playerId);
    if (data !== null) {
      return { user: data.user, opponent: data.opponent };
    } else return null;
  }
}
