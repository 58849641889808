import { Game } from '../Game';
import { BoardTarget } from '../data';
import { ModifierData } from '../data/ModifierData';
import { CardEffectContext } from './Card';

export interface CardModifier {
  id: number;
  power?: ((battlefield: Game) => number | null) | undefined;
  canAttack?: ((battlefield: Game) => boolean | null) | undefined;
  canBeExhausted?: ((battlefield: Game) => boolean | null) | undefined;
  canBeStunned?: ((battlefield: Game) => boolean | null) | undefined;
  canBeSpellTargeted?: ((battlefield: Game) => boolean | null) | undefined;
  isDeadly?: ((battlefield: Game) => boolean | null) | undefined;
}

export class ModifierSet {
  private _modifiers: CardModifier[] = [];
  private _staticModifiers: CardModifier[] = [];
  private _eotModifiers: CardModifier[] = [];
  private _modifiedPower: number | null = null;
  private _modifiedCanAttack: boolean | null = null;
  private _modifiedCanBeExhausted: boolean | null = null;
  private _modifiedCanBeStunned: boolean | null = null;
  private _modifiedCanBeSpellTargeted: boolean | null = null;
  private _modifiedIsDeadly: boolean | null = null;

  get power(): number | null {
    return this._modifiedPower;
  }

  get canAttack(): boolean | null {
    return this._modifiedCanAttack;
  }

  get canBeExhausted(): boolean | null {
    return this._modifiedCanBeExhausted;
  }

  get canBeStunned(): boolean | null {
    return this._modifiedCanBeStunned;
  }

  get canBeSpellTargeted(): boolean | null {
    return this._modifiedCanBeSpellTargeted;
  }

  get isDeadly(): boolean | null {
    return this._modifiedIsDeadly;
  }

  addModifier(modifier: CardModifier, battlefield: Game) {
    this._modifiers.push(modifier);
    this.calculate(battlefield);
  }

  addStaticModifier(modifier: CardModifier, battlefield: Game) {
    this._staticModifiers.push(modifier);
    this.calculate(battlefield);
  }

  addEotModifier(modifier: CardModifier, battlefield: Game) {
    this._eotModifiers.push(modifier);
    this.calculate(battlefield);
  }

  clearStaticModifiers(battlefield: Game) {
    this._staticModifiers = [];
    this.calculate(battlefield);
  }

  clearEotModifiers(battlefield: Game) {
    this._eotModifiers = [];
    this.calculate(battlefield);
  }

  private calculate(battlefield: Game) {
    this._modifiedPower = this.calculatePower(battlefield);
    this._modifiedCanAttack = this.calculateCanAttack(battlefield);
    this._modifiedCanBeExhausted = this.calculateCanBeExhausted(battlefield);
    this._modifiedCanBeStunned = this.calculateCanBeStunned(battlefield);
    this._modifiedCanBeSpellTargeted = this.calculateCanBeSpellTargeted(battlefield);
    this._modifiedIsDeadly = this.calculateIsDeadly(battlefield);
  }

  private calculatePower(battlefield: Game): number | null {
    let hasNonNullPower = false;

    const totalPower = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum, item) => {
        const power = item.power ? item.power(battlefield) : null;
        if (power !== null) {
          hasNonNullPower = true;
          return sum + power;
        }
        return sum;
      }, 0);

    return hasNonNullPower ? totalPower : null;
  }

  private calculateCanAttack(battlefield: Game): boolean | null {
    let hasNonNullCanAttack = false;

    const totalCanAttack = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAttack = item.canAttack ? item.canAttack(battlefield) : null;
        if (canAttack !== null) {
          hasNonNullCanAttack = true;
          return sum && canAttack;
        }
        return sum;
      }, true);

    return hasNonNullCanAttack ? totalCanAttack : null;
  }

  private calculateCanBeExhausted(battlefield: Game): boolean | null {
    let hasNonNullCanBeExhausted = false;

    const totalCanBeExhausted = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAttack = item.canBeExhausted ? item.canBeExhausted(battlefield) : null;
        if (canAttack !== null) {
          hasNonNullCanBeExhausted = true;
          return sum && canAttack;
        }
        return sum;
      }, true);

    return hasNonNullCanBeExhausted ? totalCanBeExhausted : null;
  }

  private calculateCanBeStunned(battlefield: Game): boolean | null {
    let hasNonNullCanBeStunned = false;

    const totalCanBeStunned = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAttack = item.canBeStunned ? item.canBeStunned(battlefield) : null;
        if (canAttack !== null) {
          hasNonNullCanBeStunned = true;
          return sum && canAttack;
        }
        return sum;
      }, true);

    return hasNonNullCanBeStunned ? totalCanBeStunned : null;
  }

  private calculateCanBeSpellTargeted(battlefield: Game): boolean | null {
    let hasNonNullCanBeStunned = false;

    const totalCanBeStunned = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAttack = item.canBeSpellTargeted ? item.canBeSpellTargeted(battlefield) : null;
        if (canAttack !== null) {
          hasNonNullCanBeStunned = true;
          return sum && canAttack;
        }
        return sum;
      }, true);

    return hasNonNullCanBeStunned ? totalCanBeStunned : null;
  }

  private calculateIsDeadly(battlefield: Game): boolean | null {
    let hasNonNullCanBeStunned = false;

    const totalCanBeStunned = this._modifiers
      .concat(this._staticModifiers)
      .concat(this._eotModifiers)
      .reduce((sum: boolean, item) => {
        const canAttack = item.isDeadly ? item.isDeadly(battlefield) : null;
        if (canAttack !== null) {
          hasNonNullCanBeStunned = true;
          return sum && canAttack;
        }
        return sum;
      }, true);

    return hasNonNullCanBeStunned ? totalCanBeStunned : null;
  }

  getModifierIds(): number[] {
    return this._modifiers.map((modifier) => modifier.id);
  }

  asData(): ModifierData {
    // TODO make things undefinable
    return {
      power: this.power,
      canAttack: this.canAttack,
      canBeExhausted: this.canBeExhausted ?? true,
      canBeStunned: this.canBeStunned ?? true,
      canBeSpellTargeted: this.canBeSpellTargeted ?? true,
      isDeadly: this.isDeadly ?? false,
    };
  }
}

export class CardModifierDatabase {
  static getModifierById(id: number): CardModifier {
    switch (id) {
      case 0: // Small buff
        return {
          id: id,
          power: () => {
            return 1000;
          },
        } as CardModifier;
      case 1: // Small debuff
        return {
          id: id,
          power: () => {
            return -1000;
          },
        } as CardModifier;
      case 2: // Mid buff
        return {
          id: id,
          power: () => {
            return 2000;
          },
        } as CardModifier;
      case 3: // Large buff
        return {
          id: id,
          power: () => {
            return 3000;
          },
        } as CardModifier;
      case 4: // Mid debuff
        return {
          id: id,
          power: () => {
            return -2000;
          },
        } as CardModifier;
      case 5: // Can't attack
        return {
          id: id,
          canAttack: () => {
            return false;
          },
        } as CardModifier;
      case 6: // Can't be stunned
        return {
          id: id,
          canBeStunned: () => {
            return false;
          },
        } as CardModifier;
      case 7: // Can't be exhausted
        return {
          id: id,
          canBeExhausted: () => {
            return false;
          },
        } as CardModifier;
      case 8: // Small buff
        return {
          id: id,
          power: () => {
            return 1500;
          },
        } as CardModifier;
      case 9: // Can't be spell targeted
        return {
          id: id,
          canBeSpellTargeted: () => {
            return false;
          },
        } as CardModifier;
      case 10: // Give deadly
        return {
          id: id,
          isDeadly: () => {
            return true;
          },
        } as CardModifier;
    }
    throw 'Invalid card ID!';
  }
}

export type CharacterToModifiersMap = Map<string, CardModifier[]>;
export interface StaticModifier {
  id: number;
  doesAffect: (context: CardEffectContext) => boolean;
  getModifierFor: (context: CardEffectContext) => CardModifier;
}

export class StaticModifierUtil {
  static combine(maps: CharacterToModifiersMap[]): CharacterToModifiersMap {
    const combinedMap = new Map<string, CardModifier[]>();

    for (const map of maps) {
      if (map) {
        for (const [boardTargetKey, modifiers] of map.entries()) {
          if (combinedMap.has(boardTargetKey)) {
            combinedMap.set(boardTargetKey, combinedMap.get(boardTargetKey)!.concat(modifiers));
          } else {
            combinedMap.set(boardTargetKey, modifiers);
          }
        }
      }
    }

    return combinedMap;
  }

  static generateBoardTargetKey(boardTarget: BoardTarget): string {
    return `${boardTarget.x},${boardTarget.y},${boardTarget.playerId}`;
  }

  static parseBoardTargetKey(key: string): BoardTarget {
    const [x, y, playerId] = key.split(',');
    return {
      x: parseInt(x, 10),
      y: parseInt(y, 10),
      playerId,
    };
  }
}
