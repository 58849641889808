import React, { useState, useEffect } from 'react';
import { ConnectionInfo, PlayInterfaceState } from 'tenant-common';
import { M } from '../../uilib/Modifier';
import { AuthWindow } from './AuthScreen';
import { GameScreen } from './GameScreen';
import { ChatPanel } from '../panel/ChatPanel';
import { useSocket } from '../../uilib/Hooks';
import { NetworkPlayInterface } from '../../domain/NetworkPlayInterface';

export const TenantGameScreen = () => {
  const socket = useSocket(window.location.origin);
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfo | null>(null);
  const [playInterface, setPlayInterface] = useState<NetworkPlayInterface | null>(null);
  const [playInterfaceState, setPlayInterfaceState] = useState<PlayInterfaceState | null>(null);

  useEffect(() => {
    if (connectionInfo) {
      setPlayInterface(
        new NetworkPlayInterface(connectionInfo.userToken, socket, (state) => {
          setPlayInterfaceState(state);
        }),
      );
    }
  }, [connectionInfo]);

  useEffect(() => {
    playInterface?.init();
    return () => {
      playInterface?.deinit();
    };
  }, [playInterface]);

  return (
    <div style={M.background('#333333').fillSize.flex.justifyCenter.paddingTop('20px').css()}>
      <div
        style={M.flexColumn.borderBox
          .width('360px')
          .height('640px')
          .border('1px magenta solid')
          .textStroke('0.5px black')
          .background('white')
          .css()}
      >
        {playInterfaceState?.gameState && playInterface ? (
          <GameScreen playInterface={playInterface} />
        ) : connectionInfo == null ? (
          <AuthWindow socket={socket} onLogin={(connection) => setConnectionInfo(connection)} />
        ) : (
          playInterfaceState && <ChatPanel messages={playInterfaceState.chatHistory} />
        )}
      </div>
    </div>
  );
};
