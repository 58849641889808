import React from 'react';

interface CountdownWidgetProps {
  key: number;
  shouldAnimate: boolean;
}
export const CountdownWidget: React.FC<CountdownWidgetProps> = ({
  key,
  shouldAnimate,
}: CountdownWidgetProps) => {
  return <div key={key} className={`line ${shouldAnimate ? 'animate' : ''}`}></div>;
};
