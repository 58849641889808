import React from 'react';
import { M } from '../../uilib/Modifier';
import { ActionType, CardDatabase, CopyDatabase } from 'tenant-common';
import { HandCard } from './HandWidget';

interface RevealTileProps {
  type: ActionType;
  cardId: number | null;
  isLeft: boolean;
  shouldAnimate: boolean;
}
export function RevealTile({ type, cardId, isLeft, shouldAnimate }: RevealTileProps): JSX.Element {
  switch (type) {
    case ActionType.PLAY_ACTION:
      return <PlayRevealTile cardId={cardId} isLeft={isLeft} shouldAnimate={shouldAnimate} />;
    case ActionType.MANA_ACTION:
      return <ManaRevealTile cardId={cardId} isLeft={isLeft} shouldAnimate={shouldAnimate} />;
    case ActionType.PASS:
      return <PassRevealTile isLeft={isLeft} shouldAnimate={shouldAnimate} />;
  }
}

interface PlayRevealTileProps {
  cardId: number | null;
  isLeft: boolean;
  shouldAnimate: boolean;
}
function PlayRevealTile({ cardId, isLeft, shouldAnimate }: PlayRevealTileProps): JSX.Element {
  const card = cardId ? CardDatabase.getCardById(cardId) : null;
  return (
    <div style={M.hideOverflow.css()}>
      <div
        style={M.flexColumn.fillWidth
          .addIf(!isLeft, (b) => b.alignEnd)
          .addIf(shouldAnimate, (b) =>
            b.animation(`${isLeft ? 'slideLeftRight' : 'slideRightLeft'} 1.75s forwards`),
          )
          .css()}
      >
        <img
          style={M.fitCover
            .height('160px')
            .width('100%')
            .objectPosition('center 40%')
            .maskImage(
              `linear-gradient(to ${isLeft ? 'left' : 'right'}, transparent 0%, black 50%)`,
            )
            .css()}
          src={`/images/cardart/${cardId}.png`}
        />
        <span
          style={M.color('white')
            .fontFamily('Michroma')
            .textStroke('0px')
            .fontSize('20px')
            .marginHorizontal('8px')
            .css()}
        >
          {card ? CopyDatabase.getNameById(card.id) : ''}
        </span>
      </div>
    </div>
  );
}

function ManaRevealTile({ cardId, isLeft, shouldAnimate }: PlayRevealTileProps): JSX.Element {
  return (
    <div style={M.hideOverflow.css()}>
      <div
        style={M.flexColumn.fillWidth
          .addIf(!isLeft, (b) => b.alignEnd)
          .addIf(shouldAnimate, (b) =>
            b.animation(`${isLeft ? 'slideLeftRight' : 'slideRightLeft'} 1.75s forwards`),
          )
          .css()}
      >
        <div
          style={M.fitCover.flex
            .height('160px')
            .width('100%')
            .background(
              `linear-gradient(to ${isLeft ? 'left' : 'right'}, transparent 0%, #060d2e 50%)`,
            )
            .css()}
        >
          {!isLeft && <div style={M.flexWeight('1').css()}></div>}
          <div
            style={M.fillHeight
              .addIf(isLeft, (b) => b.flexRow)
              .addIf(!isLeft, (b) => b.flexRowReverse)
              .alignCenter.css()}
          >
            <img style={M.height('140px').css()} src={`/images/mana.png`} />
            <img
              style={M.height('40px')
                .addIf(!isLeft, (b) => b.flipX)
                .css()}
              src={`/images/manaarrow.png`}
            />
            <div style={M.width('12px').css()}></div>
            <HandCard cardId={cardId} isSelected={false} />
          </div>
          {isLeft && <div style={M.flexWeight('1').css()}></div>}
        </div>
        <span
          style={M.color('white')
            .fontFamily('Michroma')
            .textStroke('0px')
            .fontSize('20px')
            .marginHorizontal('8px')
            .css()}
        >
          Create Mana
        </span>
      </div>
    </div>
  );
}

interface PassRevealTileProps {
  isLeft: boolean;
  shouldAnimate: boolean;
}
function PassRevealTile({ isLeft, shouldAnimate }: PassRevealTileProps): JSX.Element {
  return (
    <div style={M.hideOverflow.css()}>
      <div
        style={M.flexColumn.fillWidth
          .addIf(!isLeft, (b) => b.alignEnd)
          .addIf(shouldAnimate, (b) =>
            b.animation(`${isLeft ? 'slideLeftRight' : 'slideRightLeft'} 1.75s forwards`),
          )
          .css()}
      >
        <div
          style={M.fitCover.flex
            .height('160px')
            .width('100%')
            .background('rgba(6, 13, 46, 0.33)')
            .maskImage(
              `linear-gradient(to ${isLeft ? 'left' : 'right'}, transparent 0%, black 50%)`,
            )
            .css()}
        >
          {!isLeft && <div style={M.flexWeight('1').css()}></div>}
          <div
            style={M.fillHeight
              .addIf(isLeft, (b) => b.flexRow)
              .addIf(!isLeft, (b) => b.flexRowReverse)
              .alignCenter.css()}
          >
            <span
              style={M.color('white')
                .fontFamily('Michroma')
                .textStroke('0px')
                .fontSize('32px')
                .marginHorizontal('16px')
                .css()}
            >
              Pass
            </span>
          </div>
          {isLeft && <div style={M.flexWeight('1').css()}></div>}
        </div>
        {/* <span
          style={M.color('white')
            .fontFamily('Michroma')
            .textStroke('0px')
            .fontSize('20px')
            .marginHorizontal('8px')
            .css()}
        >
          Pass
        </span> */}
      </div>
    </div>
  );
}
