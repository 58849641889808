import React from 'react';
import { PlayTile } from '../../component/PlayTile';
import { CardInstanceData } from 'tenant-common';
import { DeckTile } from '../../component/DeckTile';
import { M } from '../../../uilib/Modifier';
import { ModifierData } from 'tenant-common/dist/game/data/ModifierData';
import { HandCard } from '../../component/HandWidget';
import { FullCard } from '../../component/FullCard';

export function DebugComponent(): JSX.Element {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%', // Ensure it takes full width
  };
  return (
    <div style={style}>
      <div style={M.flexRow.padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>Play tile</p>
          <PlayTile card={new CardInstanceData(1, false)} isEnemy={false} />
        </div>
        <div>
          <p>Exhausted</p>
          <PlayTile card={new CardInstanceData(1, true)} isEnemy={false} />
        </div>
        <div>
          <p>Play Preview</p>
          <PlayTile
            card={new CardInstanceData(1, true, false, false, 0, new ModifierData(null), true)}
            isEnemy={false}
          />
        </div>
        <div>
          <p>No attack</p>
          <PlayTile card={new CardInstanceData(4, false)} isEnemy={false} />
        </div>
        <div>
          <p>Ranged</p>
          <PlayTile card={new CardInstanceData(15, false)} isEnemy={false} />
        </div>
        <div>
          <p>Deadly</p>
          <PlayTile card={new CardInstanceData(38, false)} isEnemy={false} />
        </div>
        <div>
          <p>Buff</p>
          <PlayTile
            card={new CardInstanceData(38, false, false, false, 0, new ModifierData(1000))}
            isEnemy={false}
          />
        </div>
        <div>
          <p>Debuff</p>
          <PlayTile
            card={new CardInstanceData(38, false, false, false, 0, new ModifierData(-4000))}
            isEnemy={false}
          />
        </div>
        <div>
          <p>Multi</p>
          <PlayTile card={new CardInstanceData(29, false)} isEnemy={false} />
        </div>
        <div>
          <p>Null state</p>
          <PlayTile card={null} isEnemy={false} />
        </div>
      </div>
      <div style={M.flexRow.padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>Play tile</p>
          <PlayTile card={new CardInstanceData(43, false)} isEnemy={true} />
        </div>
        <div>
          <p>Exhausted</p>
          <PlayTile card={new CardInstanceData(1, true)} isEnemy={true} />
        </div>
        <div>
          <p>Stunned</p>
          <PlayTile card={new CardInstanceData(1, true, true)} isEnemy={true} />
        </div>
        <div>
          <p>Play Preview</p>
          <PlayTile
            card={new CardInstanceData(1, true, false, false, 0, new ModifierData(null), true)}
            isEnemy={true}
          />
        </div>
        <div>
          <p>No attack</p>
          <PlayTile card={new CardInstanceData(4, false)} isEnemy={true} />
        </div>
        <div>
          <p>Ranged</p>
          <PlayTile card={new CardInstanceData(15, false)} isEnemy={true} />
        </div>
        <div>
          <p>Deadly</p>
          <PlayTile card={new CardInstanceData(38, false)} isEnemy={true} />
        </div>
        <div>
          <p>Null state</p>
          <PlayTile card={null} isEnemy={true} />
        </div>
      </div>
      <div style={M.flexRow.padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>SH</p>
          <PlayTile card={new CardInstanceData(1, false, false, true)} isEnemy={true} />
        </div>
        <div>
          <p>SH EXH</p>
          <PlayTile card={new CardInstanceData(1, true, false, true)} isEnemy={true} />
        </div>
        <div>
          <p>Shielded CA</p>
          <PlayTile card={new CardInstanceData(4, false, false, true)} isEnemy={true} />
        </div>
        <div>
          <p>No Stun</p>
          <PlayTile
            card={
              new CardInstanceData(4, false, false, false, 0, {
                canBeStunned: false,
                canBeExhausted: false,
              } as ModifierData)
            }
            isEnemy={true}
          />
        </div>
        <div>
          <p>No Spells</p>
          <PlayTile
            card={
              new CardInstanceData(1, false, false, false, 0, {
                canBeSpellTargeted: false,
              } as ModifierData)
            }
            isEnemy={true}
          />
        </div>
      </div>
      <div style={M.flexRow.padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>Monument</p>
          <PlayTile card={new CardInstanceData(40)} isEnemy={false} />
        </div>
        <div>
          <p>Play Preview</p>
          <PlayTile
            card={new CardInstanceData(40, false, false, false, 0, new ModifierData(null), true)}
            isEnemy={false}
          />
        </div>
        <div>
          <p>Energy</p>
          <PlayTile card={new CardInstanceData(13, false, false, false, 2)} isEnemy={false} />
        </div>
        <div>
          <p>Play Preview</p>
          <PlayTile
            card={new CardInstanceData(13, false, false, false, 0, new ModifierData(null), true)}
            isEnemy={false}
          />
        </div>
      </div>
      <div style={M.flexRow.padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>US Hand</p>
          <HandCard cardId={1} isSelected={false} onClick={() => console.log('clicked')} />
        </div>
        <div>
          <p>S Hand</p>
          <HandCard cardId={1} isSelected={true} onClick={() => console.log('clicked')} />
        </div>
      </div>
      <div style={M.flexRow.gap('8px').padding('24px').alignStart.background('#eeeeee').css()}>
        <div>
          <p>No text</p>
          <FullCard id={1} />
        </div>
        <div>
          <p>Keyword</p>
          <FullCard id={3} />
        </div>
        <div>
          <p>Monument with Text</p>
          <FullCard id={40} />
        </div>
      </div>
      <br></br>
      <p>Deck Tiles</p>
      <DeckTile
        coverImageId={2}
        name="Black Deck Test"
        color="orange"
        selected={false}
        onClick={() => console.log('click')}
      />
      <DeckTile
        coverImageId={2}
        name="Black Deck Test"
        color="orange"
        selected={true}
        onClick={() => console.log('click')}
      />
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}
