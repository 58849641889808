import { TLog } from '../../util';
import { BaseGameDriver, GameDriver } from './GameDriver';
import { GamePhase } from './GamePhase';

export class InstantGameDriver extends BaseGameDriver implements GameDriver {
  winnerId: string | null = null;

  beforeChangePhase(newPhase: GamePhase): void {
    TLog.v(`InstantGameDriver::beforeChangePhase - newPhase: ${GamePhase[newPhase]}`);
    this.changePhase(newPhase);
  }

  advance(): GamePhase | null {
    TLog.v(`InstantGameDriver::advance - Current phase: ${GamePhase[this.phase]}`);
    if (this.phase == GamePhase.GAME_OVER) {
      TLog.v('InstantGameDriver::advance - Returning null because state is GAME_OVER');
      return null;
    }
    if (this.game.isEitherPlayerDead()) {
      if (this.game.player1.isDead && this.game.player2.isDead) {
        TLog.v(`InstantGameDriver::advance - The game ends in a draw!`);
        this.winnerId = null;
      } else if (this.game.player1.isDead) {
        this.winnerId = this.game.player2Key;
        TLog.v(`InstantGameDriver::advance - ${this.game.player1.player.base.id} is defeated`);
      } else if (this.game.player2.isDead) {
        this.winnerId = this.game.player1Key;
        TLog.v(`InstantGameDriver::advance - ${this.game.player2.player.base.id} is defeated`);
      }
      this.beforeChangePhase(GamePhase.GAME_OVER);
    }
    const oldPhase = this.phase;

    switch (this.phase) {
      case GamePhase.WAITING_TO_START:
        this.beforeChangePhase(GamePhase.STARTING_TURN);
      // When starting the game we want to fallthrough
      // eslint-disable-next-line no-fallthrough
      case GamePhase.STARTING_TURN:
        this.beforeChangePhase(GamePhase.START_OF_TURN_EFFECTS);
        break;
      case GamePhase.START_OF_TURN_EFFECTS:
        this.beforeChangePhase(GamePhase.AWAITING_ROUND_ACTION);
        break;
      case GamePhase.AWAITING_ROUND_ACTION:
        if (this.game.haveAllPlayersReadiedAnAction()) {
          this.beforeChangePhase(GamePhase.REVEALING_ROUND_ACTION);
        }
        break;
      case GamePhase.REVEALING_ROUND_ACTION:
        if (this.game.haveAllPlayersPassed()) {
          this.beforeChangePhase(GamePhase.COMBAT);
        } else {
          this.beforeChangePhase(GamePhase.AWAITING_ROUND_ACTION);
        }
        break;
      case GamePhase.COMBAT:
        if (!this.game.canAnythingAttack()) {
          this.beforeChangePhase(GamePhase.STARTING_TURN);
        } else {
          this.beforeChangePhase(GamePhase.ANIMATING_ATTACKS);
        }
        break;
      case GamePhase.ANIMATING_ATTACKS:
        this.beforeChangePhase(GamePhase.STARTING_TURN);
        break;
    }

    return oldPhase == this.phase ? null : this.phase;
  }
}
