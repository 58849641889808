import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

export function useLongPress(callback: () => void, ms = 500) {
  const [startLongPress, setStartLongPress] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (startLongPress) {
      timerRef.current = setTimeout(callback, ms);
    } else {
      clearTimeout(timerRef.current);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [startLongPress, ms, callback]);

  const start = () => setStartLongPress(true);
  const stop = () => setStartLongPress(false);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}

export const useFitText = (maxFontSize = 100, minFontSize = 1) => {
  const ref = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const adjustFontSize = () => {
      const container = ref.current;
      if (container) {
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const text = container.innerText;

        const testSpan = document.createElement('span');
        testSpan.style.visibility = 'hidden';
        testSpan.style.position = 'absolute';
        testSpan.style.whiteSpace = 'nowrap';
        testSpan.style.fontFamily = window.getComputedStyle(container).fontFamily;
        testSpan.style.paddingLeft = window.getComputedStyle(container).paddingLeft;
        testSpan.style.paddingRight = window.getComputedStyle(container).paddingRight;
        testSpan.style.paddingTop = window.getComputedStyle(container).paddingTop;
        testSpan.style.paddingBottom = window.getComputedStyle(container).paddingBottom;
        testSpan.innerText = text;
        document.body.appendChild(testSpan);

        let calculatedFontSize = maxFontSize;

        while (calculatedFontSize >= minFontSize) {
          testSpan.style.fontSize = `${calculatedFontSize}px`;

          if (testSpan.offsetWidth <= containerWidth && testSpan.offsetHeight <= containerHeight) {
            break;
          }

          calculatedFontSize -= 1;
        }

        document.body.removeChild(testSpan);
        setFontSize(calculatedFontSize);
      }
    };

    const handleFontLoad = () => {
      adjustFontSize();
      const resizeObserver = new ResizeObserver(adjustFontSize);
      if (ref.current) {
        resizeObserver.observe(ref.current);
      }
      return () => resizeObserver.disconnect();
    };

    if (document.fonts) {
      document.fonts.ready.then(handleFontLoad);
    } else {
      window.addEventListener('load', handleFontLoad);
    }

    // Initial adjustment in case the font is already loaded
    adjustFontSize();

    return () => {
      if (document.fonts) {
        document.fonts.ready.then(handleFontLoad);
      } else {
        window.removeEventListener('load', handleFontLoad);
      }
    };
  }, [maxFontSize, minFontSize]);

  return { ref, fontSize };
};

export const useSocket = (url: string) => {
  const socketRef = useRef<Socket | null>(null);

  if (!socketRef.current) {
    socketRef.current = io(url);
  }

  const socket = socketRef.current;

  useEffect(() => {
    const handleBeforeUnload = () => {
      socket.disconnect();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return socket;
};
