import { Combatant } from '../Combatant';
import { CardActionType } from '../Request';
import { Target } from '../data/Target';

export interface UserActionRequest {
  requestId?: number;
}

export interface PlayerRequest extends UserActionRequest {
  playerId: string;
}

export interface CardActionRequest extends PlayerRequest {
  handIndex: number;
}

interface TargetedActionRequest {
  target: Target;
}

export interface PlayCardActionRequest extends CardActionRequest, TargetedActionRequest {}

export interface MakeManaActionRequest extends CardActionRequest {}

export interface FullStatusActionRequest extends UserActionRequest {
  player1: Combatant;
  player2: Combatant;
}

export interface SkipActionRequest extends PlayerRequest {}

export interface RevealActionsRequest {
  player1Action: RevealedAction;
  player2Action: RevealedAction;
}

export interface RevealedAction {
  playerId: string;
  cardId: number;
  type: ActionType;
}

export enum ActionType {
  MANA_ACTION,
  PLAY_ACTION,
  PASS,
}

export class ActionUtils {
  static cardActionToAction(type: CardActionType | null | undefined): ActionType {
    if (type == CardActionType.PLAY_PERMANENT || type == CardActionType.PLAY_SPELL)
      return ActionType.PLAY_ACTION;
    if (type == CardActionType.MAKE_MANA) return ActionType.MANA_ACTION;
    return ActionType.PASS;
  }
}
