export interface CardAction {
  type: CardActionType;
  playerId: string;
  handIndex: number;
}

// TODO?
export interface PlayAction extends CardAction {
  costDiscount: number;
  playerId: string;
  cardId: number;
  handIndex: number;
}

export interface PlayPermanentAction extends PlayAction {
  x: number;
  y: number;
}

export interface PlaySpellAction extends PlayAction {
  x: number;
  y: number;
  localTarget: boolean;
}

export interface MakeManaAction extends CardAction {}

export enum CardActionType {
  PLAY_PERMANENT,
  PLAY_SPELL,
  MAKE_MANA,
}
