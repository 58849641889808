import React from 'react';
import { M } from '../../uilib/Modifier';

interface DonutWheelProps {
  style?: M;
  segments: { color: string; value: number }[];
  innerRadius: number;
  outerRadius: number;
  ringThickness: number; // New prop for the black ring thickness
  onClick?: () => void;
}

export const DonutWheel: React.FC<DonutWheelProps> = ({
  style,
  segments,
  innerRadius,
  outerRadius,
  ringThickness,
  onClick,
}) => {
  const totalValue = segments.reduce((acc, segment) => acc + segment.value, 0);
  let currentAngle = 0;

  const getPath = (startAngle: number, endAngle: number) => {
    const start = polarToCartesian(outerRadius + 4, outerRadius + 4, outerRadius, endAngle);
    const end = polarToCartesian(outerRadius + 4, outerRadius + 4, outerRadius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      outerRadius,
      outerRadius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
      'L',
      outerRadius + 4,
      outerRadius + 4,
      'Z',
    ].join(' ');

    return d;
  };

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  return (
    <svg
      style={style?.css()}
      onClick={onClick}
      transform="rotate(180)"
      width={outerRadius * 2 + 8}
      height={outerRadius * 2 + 8}
      viewBox={`0 0 ${outerRadius * 2 + 8} ${outerRadius * 2 + 8}`}
      className="donut-wheel"
    >
      {segments.map((segment, index) => {
        const segmentAngle = (segment.value / totalValue) * 360;
        const startAngle = currentAngle;
        currentAngle += segmentAngle;
        const endAngle = currentAngle;

        return (
          <path
            key={index}
            d={getPath(startAngle, endAngle)}
            fill={segment.color}
            stroke="black"
            strokeWidth="1.5"
          />
        );
      })}
      <circle
        cx={outerRadius + 4}
        cy={outerRadius + 4}
        r={innerRadius}
        fill="#222222"
        stroke="black"
        strokeWidth="1.5"
      />
      {/* Overlay ring */}
      <circle
        cx={outerRadius + 4}
        cy={outerRadius + 4}
        r={outerRadius - ringThickness / 2}
        fill="none"
        stroke="black"
        strokeWidth={ringThickness}
        strokeOpacity={0.5}
      />
      <rect
        x={-outerRadius - 5}
        y={-outerRadius - 5}
        width={outerRadius + 4}
        height={outerRadius + 4}
        fill="#222222"
        transform={`translate(${outerRadius + 4}, ${outerRadius + 4})`}
      />
    </svg>
  );
};
