import { Field1v1Data } from './Field1v1Data';
import { PlayerData } from './PlayerData';

export class CombatantData {
  constructor(
    public readonly player: PlayerData,
    public readonly board: Field1v1Data,
    public readonly hand: (number | null)[],
    public readonly deckSize: number,
    public readonly handSize: number,
    public readonly hasPlayedManaThisTurn: boolean,
  ) {}
}
