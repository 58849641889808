import React from 'react';
import { M } from '../../uilib/Modifier';
import { DonutWheel } from './DonutWheel';
import { SemiDonut } from './DonutWheel2';
import { CardUiProperties } from 'tenant-common';

interface ManaWidgetProps {
  style?: M;
  mana: number;
  maxMana: number;
  enabled: boolean;
  segments: {
    color: string;
    value: number;
  }[];
  manaPool: boolean[];
  onClick: () => void;
}
export function ManaWidget({
  style,
  mana,
  maxMana,
  enabled,
  segments,
  manaPool,
  onClick,
}: ManaWidgetProps): JSX.Element {
  // let offset = 0;
  console.log('RENDER START');
  console.log(manaPool);
  return (
    <div
      style={M.background('#222222').size('68px').flex.justifyCenter.alignCenter.css()}
      onClick={onClick}
    >
      <div style={(style ?? M).relativePosition.flex.alignCenter.justifyCenter.css()}>
        {manaPool.map((value, index) => {
          if (index > 0) {
            // offset += manaPool[index - 1] * 30;
          }
          return (
            <>
              <SemiDonut
                style={M.absolutePosition}
                outerRadius={31.5}
                ringThickness={2.7}
                // arcLength={30 * value}
                arcLength={0}
                color={CardUiProperties.getColorForManaIndex(index)}
                opacity="1"
                // rotationOffset={offset}
                rotationOffset={0}
              />
              <SemiDonut
                style={M.absolutePosition}
                outerRadius={31.5}
                ringThickness={1.2}
                // arcLength={30 * value}
                arcLength={30 * 0}
                color="black"
                opacity="0.3"
                // rotationOffset={offset}
                rotationOffset={0}
              />
            </>
          );
        })}

        <DonutWheel
          style={M.absolutePosition}
          segments={segments}
          innerRadius={18}
          outerRadius={28}
          ringThickness={0}
        />
        <SemiDonut
          style={M.absolutePosition}
          outerRadius={28}
          ringThickness={3.5}
          arcLength={maxMana * 30}
          color="black"
          opacity="0.5"
        />
        <SemiDonut
          style={M.absolutePosition}
          outerRadius={20}
          ringThickness={2}
          arcLength={mana * 30}
          color="white"
          opacity="0.2"
        />

        <span
          style={M.absolutePosition.textCenter
            .color('white')
            .noPointerEvents.fontSize('30px')
            .top('-19px')
            .left('-12px')
            .textCenter.borderBox.textStroke('0px black')
            .css()}
        >
          {mana}
        </span>
        <span
          style={M.absolutePosition.noPointerEvents
            .color('white')
            .fontSize('11px')
            .top('8px')
            .left('10px')
            .width('30px')
            .borderBox.textStroke('0px black')
            .css()}
        >
          / {maxMana}
        </span>
      </div>
    </div>
  );
}
