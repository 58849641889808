import React from 'react';
import { M } from '../../uilib/Modifier';

interface SkipWidgetProps {
  hasSkipped: boolean;
  onClick: () => void;
}
export function SkipWidget({ hasSkipped, onClick }: SkipWidgetProps): JSX.Element {
  const clicked = () => {
    if (!hasSkipped) {
      onClick();
    }
  };
  return (
    <div
      style={M.size('50px')
        .flex.justifyCenter.alignCenter.addIf(hasSkipped, (b) => b.background('darkblue'))
        .addIf(!hasSkipped, (b) => b.background('blue'))
        .color('white')
        .circleCorners.css()}
      onClick={clicked}
    >
      <img
        style={M.size('24px')
          .addIf(!hasSkipped, (b) => b.filter('invert(100%)'))
          .css()}
        src="/images/skip.png"
      />
    </div>
  );
}
