import { CardColor } from '../card';

export class CardUiProperties {
  static DEBUFF = '#f55440';
  static BUFF = '#34eb43';
  static MANA = '#7abaff';
  static SHIELD = '#eaff73';

  static getColorForType(color: CardColor): string {
    switch (color) {
      case CardColor.BLACK:
        return '#7e2ed9';
      case CardColor.GREEN:
        return '#2edb5c';
      case CardColor.BLUE:
        return '#2c9ef5';
      case CardColor.WHITE:
        return '#ffee00';
      case CardColor.RED:
        return '#eb4034';
      case CardColor.NEUTRAL:
        return '#e3e3e3';
    }
    return 'white';
  }
  static getColorForManaIndex(index: number): string {
    return this.getColorForType(index as CardColor);
  }
}
